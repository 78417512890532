const chunk = <T>(inputArray: T[] = [], size: number) => {
  return inputArray.reduce((resultArray: T[][], item: T, index) => {
    const chunkIndex = Math.floor(index/size)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

export default chunk
