import React, { FC, useState } from "react"
import AdminPageCard from "../components/adminPageCard/AdminPageCard.component"
import { Box } from "@material-ui/core"
import TenantsList from "../components/tenants/tenantsList/TenantsList.component"

const TenantsPage: FC = () => {
  const [isOpenAddTenantPopup, setIsOpenAddTenantPopup] = useState<boolean>(false)

  return (
    <AdminPageCard
      title="Podmioty"
      actionButtonText={"Dodaj podmiot"}
      actionButtonProps={{
        onClick: () => setIsOpenAddTenantPopup(true)
      }}
    >
      <Box>
        <TenantsList
          isOpenAddTenantPopup={isOpenAddTenantPopup}
          onCloseAddTenantPopup={() => setIsOpenAddTenantPopup(false)}
        />
      </Box>
    </AdminPageCard>
  )
}

export default TenantsPage
