import React, { FC, ReactNode, useEffect, useState } from "react"
import { Box } from "@material-ui/core"
import { useGameCardStyles } from "./GameCard.styles"
import GameSidebar from "../gameSidebar/GameSidebar.component"
import Timer from "../../common/timer/Timer"
import { GameQueryResponse } from "../../../pages/GamePage"
import EnterGameModalComponent from "../enterGameModal/EnterGameModal.component"
import EndGameModalComponent from "../endGameModal/EndGameModal.component"
import { RoutePath } from "../../../routes/Routes.types"
import { useHistory } from "react-router"
import clsx from "clsx"

interface GameCardProps {
  gameType: GameQueryResponse["game"]["gameType"];
  definition: GameQueryResponse["game"]["definition"];
  startedAt?: Date | null;
  closedAt?: Date | null;
  won: boolean;
  ended: boolean;
  resultDescription: string;
  stats?: Array<{
    label: ReactNode,
    value: ReactNode,
  }>;
  onStart?(): void;
  onEnd?(): void;
  onTimerEnd?(): void;
}

const GameCard: FC<GameCardProps> = (
  {
    gameType,
    startedAt,
    closedAt,
    definition,
    stats = [],
    won,
    ended,
    resultDescription,
    onStart,
    onEnd,
    onTimerEnd,
    children
  }
) => {
  const {push} = useHistory()
  const classes = useGameCardStyles()

  const [enterGamePopupOpen, setEnterGamePopupOpen] = useState<boolean>(false)
  const [endGamePopupOpen, setEndGamePopupOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!startedAt) {
      setEnterGamePopupOpen(true)
    }
  }, [])

  useEffect(() => {
    if (ended) {
      setEndGamePopupOpen(true)
    }
  }, [ended])

  const handleOnStart = () => {
    if (onStart) onStart()
    setEnterGamePopupOpen(false)
  }

  const handleOnCloseEndGameModal = () => {
    setEndGamePopupOpen(false)
    push(RoutePath.HOMEPAGE)
  }

  return (
    <Box className={clsx(classes.root, ended && classes.disabled)}>
      { startedAt && (
        <>
          <Box className={classes.content}>
            <Box flex={1}>
              { children }
            </Box>

            <GameSidebar
              title={definition.gameType.name}
              level={definition.level}
              onStart={onStart}
              onEnd={onEnd}
              stats={[
                {
                  label: "Gra",
                  value: definition.gameType.name
                },
                {
                  label: "Poziom",
                  value: definition.level
                },
                ...stats,
                {
                  label: 'Pozostały czas',
                  value: (
                    <Timer
                      timeLimit={definition.timeLimit || 0}
                      startedAt={startedAt}
                      endedAt={closedAt}
                      onEnd={onTimerEnd}
                    />
                  )
                }
              ]}
            />
          </Box>
        </>
      )}

      <EnterGameModalComponent
        gameType={definition.gameType}
        gameEnum={gameType}
        open={enterGamePopupOpen}
        onStart={handleOnStart}
      />

      <EndGameModalComponent
        open={endGamePopupOpen}
        won={won}
        level={definition.level}
        gameType={definition.gameType}
        resultDescription={resultDescription}
        goToHomepage={handleOnCloseEndGameModal}
        onClose={() => setEndGamePopupOpen(false)}
      />
    </Box>
  )
}

export default GameCard
