import React, {FC} from 'react'
import {Card, CardContent, CardMedia} from "@material-ui/core";
import ScoreInputController from "../scoreInputController/ScoreInputController.component";
import {useQuestionsCardStyles} from "./QuestionsCard.styles";
import {getQuestionCardLabel} from "./QuestionCard.utils";

export enum QuestionsType {
  ATTENTION = 'ATTENTION',
  MEMORY = 'MEMORY',
  FLUENCY = 'FLUENCY',
  LANGUAGE = 'LANGUAGE',
  VISUOSPATIAL_ABILITIES = 'VISUOSPATIAL_ABILITIES'
}

export interface QuestionsCardProps {
  image: string
  questionType: QuestionsType;
  name: string;
  maxPoints: number;
  loading?: boolean;
  defaultValue?: number | string
}

const QuestionsCard: FC<QuestionsCardProps> = (
  {
    image,
    questionType,
    name,
    maxPoints,
    loading,
    defaultValue
  }) => {
  const classes = useQuestionsCardStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        component='img'
        image={image}
        className={classes.image}
      />
      <CardContent className={classes.contentWrapper}>
        <ScoreInputController
          name={name}
          maxPoints={maxPoints}
          disabled={loading}
          category={getQuestionCardLabel(questionType)}
          examValue={defaultValue}
        />
      </CardContent>
    </Card>
  )
}

export default QuestionsCard