import React, { FC, useEffect, useState } from "react"
import { Box, Button, Grid, Typography } from "@material-ui/core"
import { useSettingsStyles } from "./Settings.styles"
import { useMutation, useQuery } from "@apollo/client"
import { GAMES_QUERY } from "./Settings.query"
import { GameData, GameNode } from "./Settings.types"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { UPDATE_GAME_DEFINITION, UPDATE_GAME_LEVEL } from "./Settings.mutation"
import { gameSettingsValidationSchema } from "./Settings.validation"
import GameSelectComponent from "../games/gameSelect/GameSelect.component"
import GameLevelSelect from "../games/gameLevelSelect/GameSelect.component"
import TextFieldController from "../formItems/textFieldController/TextFieldController.component"
import { GAME_TYPE } from "../games/Game.types"
import GlobalSuccessSnackbar from "../formItems/globalSuccessSnackbar/GlobalSuccessSnackbar"
import LoaderBox from "../common/loaderBox/LoaderBox.component"
import { getGameLabel } from "../games/Game.utils"

interface SettingsProps {}
const Settings: FC<SettingsProps> = () => {
  const classes = useSettingsStyles()
  const [updateGameLevel, {loading: updateGameLevelLoading}] = useMutation(UPDATE_GAME_LEVEL)
  const [updateGameDefinition, {loading: updateGameDefinitionLoading}] = useMutation(UPDATE_GAME_DEFINITION)

  const loading = updateGameLevelLoading || updateGameDefinitionLoading

  const [games, setGames] = useState<{[key in GAME_TYPE]: GameData[]}>()
  const [selectedGameLevelData, setSelectedGameLevelData] = useState<null | GameData>(null)
  const [isSuccessRequest, setIsSuccessRequest] = useState<boolean>(false)

  const {data, refetch, loading: queryLoading } = useQuery<{ games: { edges: Array <{node: GameNode}>}}>(GAMES_QUERY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  const form = useForm({
    mode: "all",
    resolver: yupResolver(gameSettingsValidationSchema),
    defaultValues: {
      game: null,
      level: null,
      size: null,
      target: null,
      maxAttempts: null,
      timeLimit: null,
      name: "",
      description: "",
    }
  })

  const gameValue = form.watch("game")
  const levelValue = form.watch("level")

  useEffect(() => {
    if (data) {
      const gamesList = data.games.edges.map(edge => edge.node)

      const result = gamesList.reduce((r, a) => {
        r[a.game] = r[a.game] || []
        r[a.game].push(a)
        return r;
      }, Object.create(null))

      setGames(result)
    }
  }, [data])

  useEffect(() => {
    if (gameValue && games) {
      const selectedGame: GameData[] = games[gameValue!]
      if (selectedGame?.length) {
        form.setValue("name", selectedGame[0].gameType.name)
        form.setValue("description", selectedGame[0].gameType.description)
      }

      if (levelValue) {
        // @ts-ignore
        const selectedGameLevel = games[gameValue].find((item: GameData) => item.level === levelValue)
        setSelectedGameLevelData(selectedGameLevel)

        form.clearErrors()
        form.setValue("size", selectedGameLevel.size)
        form.setValue("target", selectedGameLevel.target)
        form.setValue("maxAttempts", selectedGameLevel.maxAttempts)
        form.setValue("timeLimit", selectedGameLevel.timeLimit)
      }
    }
  }, [gameValue, levelValue, games])

  const handleGameDefinitionUpdateSubmit = form.handleSubmit(async (values) => {
    setIsSuccessRequest(false)

    try {
      await updateGameDefinition({
        variables: {
          input: {
            game: values.game,
            patch: {
              name: values.name,
              description: values.description,
            }
          }
        }
      })

      await refetch()
      setIsSuccessRequest(true)
    } catch (e) {
      console.error(e)
    }
  })

  const handleGameLevelDataUpdateSubmit = form.handleSubmit(async (values) => {
    setIsSuccessRequest(false)

    try {
      await updateGameLevel({
        variables: {
          input: {
            game: values.game,
            level: values.level,
            patch: {
              size: values.size,
              target: values.target,
              maxAttempts: values.maxAttempts,
              timeLimit: values.timeLimit,
            }
          }
        }
      })

      await refetch()
      setIsSuccessRequest(true)
    } catch (e) {
      console.error(e)
    }
  })

  if (queryLoading && !data) {
    return <LoaderBox/>
  }

  return (
    <Box className={classes.root}>
      <FormProvider {...form} >
        <form autoComplete="off" noValidate>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <GameSelectComponent
                name="game"
                label={'Gra'}
                isRequired
                mb
                disabled={loading}
              />
            </Grid>

            <Grid item xs={6}>
              <GameLevelSelect
                name="level"
                label={'Poziom'}
                isRequired
                mb
                disabled={loading}
              />
            </Grid>
          </Grid>

          { gameValue && (
            <Box>
              <Box my={3}>
                <Typography variant={"h4"}>
                  Zmiana danych gry { getGameLabel(gameValue) }
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldController
                    name="name"
                    label={'Nazwa'}
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldController
                    name="description"
                    label={'Opis zasad gry'}
                    isRequired
                    multiline
                    maxCharacters={99999}
                    mb
                    disabled={loading}
                  />
                </Grid>
              </Grid>

              <Box className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  onClick={handleGameDefinitionUpdateSubmit}
                >
                  Zapisz dane gry
                </Button>
              </Box>
            </Box>
          )}

          { (gameValue && levelValue) && (
            <Box>
              <Box my={3}>
                <Typography variant={"h4"}>
                  Zmiana ustawień gry { getGameLabel(gameValue) } - poziom { levelValue }
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextFieldController
                    name="size"
                    label={'Liczebność elementów gry'}
                    type={"number"}
                    isRequired
                    mb
                    disabled={loading || gameValue === GAME_TYPE.DIFFERENCES}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldController
                    name="target"
                    label={'Liczba elemtnów koniecznych do pozytywnego zakończenia gry'}
                    type={"number"}
                    isRequired
                    mb
                    disabled={loading || gameValue === GAME_TYPE.DIFFERENCES}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldController
                    name="timeLimit"
                    label={'Limit czasu'}
                    type={"number"}
                    isRequired
                    mb
                    disabled={loading}
                  />
                </Grid>

                { selectedGameLevelData?.maxAttempts && (
                  <Grid item xs={6}>
                    <TextFieldController
                      name="maxAttempts"
                      label={'Maksymalna liczba ruchów'}
                      type={"number"}
                      mb
                      disabled={loading || !selectedGameLevelData?.maxAttempts}
                    />
                  </Grid>
                )}
              </Grid>

              <Box className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  onClick={handleGameLevelDataUpdateSubmit}
                >
                  Zapisz ustawienia poziomu
                </Button>
              </Box>
            </Box>
          )}
        </form>
      </FormProvider>

      <GlobalSuccessSnackbar
        isSuccess={isSuccessRequest}
        successMessage={"Poprawnie zapisano zmiany."}
      />
    </Box>
  )
}

export default Settings
