import { makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"

const box3dWidth = "70px"
const box3dHeight = "70px"
const half = "35px"

export const useBox3dStyles = makeStyles((theme: Theme) => createStyles({
  scene: {
    width: box3dWidth,
    height: box3dHeight,
    perspective: "1400px",
    margin: theme.spacing(4)
  },
  box: {
    width: box3dWidth,
    height: box3dHeight,
    position: "relative",
    transformStyle: 'preserve-3d',
    transform: `translateZ(20px) rotateX(-30deg) rotateY(-30deg)`,
    transformOrigin: `center center -${half}`,
    ...theme.typography.h4,
  },
  face: {
    position: "absolute",
    border: `2px solid ${theme.palette.common.black}`,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  front: {
  },
  ceil: {
    transform: `rotateX(90deg) translate3d(0, -${half}, ${half})`
  },
  side: {
    transform: `rotateY(90deg) translate3d(${half}, 0, ${half})`
  },
}))
