import React, { FC } from "react"
import { Box } from "@material-ui/core"
import AdminPageCard from "../components/adminPageCard/AdminPageCard.component"
import Reports from "../components/reports/Reports.component"

const ReportsPage: FC = () => {
  return (
    <AdminPageCard title="Raporty">
      <Box>
        <Reports/>
      </Box>
    </AdminPageCard>
  )
}

export default ReportsPage
