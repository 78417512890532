import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useSettingsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: theme.palette.background.paper,
      flexDirection: "column",
      boxShadow: theme.shadows[8],
      padding: theme.spacing(4),
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    fieldName: {
      ...theme.typography.body2,
      fontWeight: "bold",
    }
  }),
)