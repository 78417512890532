import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import { UserDataState } from "./user.types"
import {Reducer} from "redux"

const initialState: UserDataState = null

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    getUserData: () => {
      return
    },
    setUserData: (state, action: PayloadAction<Partial<UserDataState>>) => {
      // @ts-ignore
      return {...state, ...action.payload}
    },
    clearUserData: () => {
      return initialState
    },
  }
})

export const {
  getUserData,
  setUserData,
  clearUserData,
} = userSlice.actions

export default userSlice.reducer as Reducer<typeof initialState>
