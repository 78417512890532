import { alpha, makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"

const GAME_SIDEBAR_WIDTH = 275

export const useGameSidebarStyles = makeStyles((theme: Theme) => createStyles({
  box: {
    width: `${GAME_SIDEBAR_WIDTH}px`,
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[5],
    height: "100%",
    display: "flex",
    justifyContent:"space-between",
    flexDirection: "column",
  },
  stats: {
    margin: 0,
  },
  statsTitle: {
    ...theme.typography.h4,
    textAlign: "center",
  },
  statsValue: {
    fontSize: theme.typography.h5.fontSize,
    marginLeft: 0,
    marginBottom: 50,
    textAlign: "center",
  },
  button: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5),
    pointerEvents: "auto",
  },
  goBackButton: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: 50,
  },
  goBackButtonIcon: {
    ...theme.typography.body1,
    marginRight: theme.spacing(1),
  },
}))
