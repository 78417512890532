import { put, takeEvery, call, select } from "redux-saga/effects";
import client from "../../client"
import { clearUserData, getUserData, setUserData } from "./user.slice"
import { push } from "connected-react-router"
import { selectSessionUserId } from "../session/session.selectors"
import { USER_STORAGE_KEY, UserQuery } from "./user.types"
import { getHomeRouteForRole } from "../../components/user/userRole/UserRole.utils"
import { SagaIterator } from "redux-saga"
import { selectLogInRedirectUrl } from "../settings/settings.selectors"
import { USER_QUERY } from "./user.query"

function* loadUserDataSaga() {
  const userId: ReturnType<typeof selectSessionUserId> = yield select(selectSessionUserId)
  const redirectUrl: ReturnType<typeof selectLogInRedirectUrl> = yield select(selectLogInRedirectUrl)

  if (userId) {
    const {data: { user }}: UserQuery = yield call(client.query, {
      query: USER_QUERY,
      variables: { userId }
    });

    yield call(() => localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user)))
    yield put(setUserData(user))
    if (user?.role) {
      yield put(push(redirectUrl ? decodeURIComponent(redirectUrl) : getHomeRouteForRole(user.role)))
    }
  }
}

function* clearUserDataSaga() {
  yield call(() => localStorage.removeItem(USER_STORAGE_KEY))
  yield put(setUserData(null));
}

export default function* (): SagaIterator {
  const userJSON: string = yield call(() => localStorage.getItem(USER_STORAGE_KEY))
  const user = JSON.parse(userJSON)
  if (user) {
    yield put(setUserData(user))
  }

  yield takeEvery(getUserData, loadUserDataSaga);
  yield takeEvery(clearUserData, clearUserDataSaga);
}