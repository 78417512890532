import {makeStyles} from "@material-ui/core"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useLoginPageStyles = makeStyles((theme: Theme) => createStyles({
  input: {
    marginBottom: theme.spacing(2),
  },
  forgetPasswortButtonWrapper: {
    margin: theme.spacing(-2, 0, 2)
  },
  forgetPasswortButton: {
    marginLeft: "auto",
    display: "block",
  },
  submitButton: {
    width: "100%",
    padding: theme.spacing(1.5, 0)
  }
}))
