import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

interface LayoutStylesProps {
  bcgImageSrc?: string | null;
}

const DRAWER_WIDTH = 240;

const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    adminRoot: {
      display: "flex",
      minHeight: "100vh",
      backgroundColor: theme.palette.background.default,
    },
    testedPersonLayout: {
      backgroundImage: (props: LayoutStylesProps) => props.bcgImageSrc ? `url(${props.bcgImageSrc})` : "",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
    topBar: {
      display: "flex",
      justifyContent: "flex-end"
    },
    topToolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    toolbarHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      ...theme.mixins.toolbar,
      ...theme.typography.button,
      textTransform: "uppercase",
      letterSpacing: "1.4px",
    },
    adminContent: {
      minHeight: '100vh',
      overflow: 'auto',
      display: "flex",
      flexDirection: "column",
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: "auto"
    },
    appBarSpacer: theme.mixins.toolbar,
    contentContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }
    },
    adminContentContainer: {
      flex: 1,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: `${DRAWER_WIDTH}px`,
    },
  })
)

export default useLayoutStyles
