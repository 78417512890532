import { createStyles, makeStyles, Theme} from "@material-ui/core/";

export const useEntryExitFormResultCardStyles = makeStyles(( theme: Theme) => createStyles({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    flexDirection: "column",
    boxShadow: theme.shadows[8],
  },
  header: {
    margin: theme.spacing(5, 0),
    textAlign: "center",
    color: theme.palette.common.black
  },
}))
