import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useContext, useState } from "react";
import { CustomThemeContext } from "../../../context/customThemeContext";
import { ActionType, PaletteEnum } from "../../../context/customTheme.types";
import { usePaletteControllerStyles } from "./paletteController.styles";

const PaletteController = () => {
  const styles = usePaletteControllerStyles();
  const { settings, dispatch } = useContext(CustomThemeContext);
  const [palette, setPalette] = useState<PaletteEnum>(
    settings?.palette ?? PaletteEnum.DEFAULT
  );

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newPalette: PaletteEnum
  ) => {
    if (newPalette) {
      dispatch({
        type: ActionType.SWITCH_PALETTE,
        payload: newPalette,
      });
      setPalette(newPalette);
    }
  };

  return (
    <ToggleButtonGroup
      value={palette}
      exclusive
      onChange={handleAlignment}
      className={styles.root}
    >
      <ToggleButton value={PaletteEnum.DEFAULT} className={styles.label}>
        A
      </ToggleButton>
      <ToggleButton
        value={PaletteEnum.HIGH_CONTRAST}
        className={styles.highContrastButton}
      >
        A
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PaletteController;
