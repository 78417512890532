export const smallTypography = {
  fontFamily: [
    "Arial",
    "sans-serif",
  ].join(),
  h1: {
    fontWeight: 300,
    fontSize: "48px",
    lineHeight: "56px",
    letterSpacing: "0.02em",
  },
  h2: {
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "48px",
    letterSpacing: "0.03em"
  },
  h3: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0.03em"
  },
  h4: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  h5: { //h1-mobile
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  body1: { // renders by default as p tag
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  body2: { // renders by default as p tag
    fontSize: "13px",
    lineHeight: "16px",
    letterSpacing: "0.03em",
  },
  subtitle1: { // renders by default as p tag
    fontSize: "13px",
    lineHeight: "24px",
    letterSpacing: "0.04em",
  },
  subtitle2: { // renders by default as p tag
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "0.05em",
  },
  button: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.46px",
    textTransform: undefined,
  },
}

export const mediumTypography = {
  fontFamily: [
    "Arial",
    "sans-serif",
  ].join(),
  h1: {
    fontWeight: 300,
    fontSize: "50px",
    lineHeight: "56px",
    letterSpacing: "0.02em",
  },
  h2: {
    fontWeight: 700,
    fontSize: "38px",
    lineHeight: "48px",
    letterSpacing: "0.03em"
  },
  h3: {
    fontWeight: 700,
    fontSize: "26px",
    lineHeight: "32px",
    letterSpacing: "0.03em"
  },
  h4: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  h5: { //h1-mobile
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  body1: { // renders by default as p tag
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  body2: { // renders by default as p tag
    fontSize: "15px",
    lineHeight: "16px",
    letterSpacing: "0.03em",
  },
  subtitle1: { // renders by default as p tag
    fontSize: "15px",
    lineHeight: "24px",
    letterSpacing: "0.04em",
  },
  subtitle2: { // renders by default as p tag
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.05em",
  },
  button: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.46px",
    textTransform: undefined,
  },
}

export const bigTypography = {
  fontFamily: [
    "Arial",
    "sans-serif",
  ].join(),
  h1: {
    fontWeight: 300,
    fontSize: "52px",
    lineHeight: "56px",
    letterSpacing: "0.02em",
  },
  h2: {
    fontWeight: 700,
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "0.03em"
  },
  h3: {
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "32px",
    letterSpacing: "0.03em"
  },
  h4: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  h5: { //h1-mobile
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  body1: { // renders by default as p tag
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
  },
  body2: { // renders by default as p tag
    fontSize: "17px",
    lineHeight: "16px",
    letterSpacing: "0.03em",
  },
  subtitle1: { // renders by default as p tag
    fontSize: "17px",
    lineHeight: "24px",
    letterSpacing: "0.04em",
  },
  subtitle2: { // renders by default as p tag
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.05em",
  },
  button: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "0.46px",
    textTransform: undefined,
  },
}