// prevent symetric images
const getImageNumber: { [key: number]: number } = {
  0: 11,
  1: 8,
  2: 2,
  3: 13,
  4: 15,
  5: 10,
  6: 6,
}

export default getImageNumber