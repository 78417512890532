import {GAME_TYPE} from "../Game.types";
import dicesTutorial from './assets/dices_tutorial.gif'
import box3dTutorial from './assets/box3d_tutorial.gif'
import bubbleTutorial from './assets/bubble_tutorial.gif'
import differencesTutorial from './assets/differences_tutorial.gif'
import memoryTutorial from './assets/memory_tutorial.gif'

export const getTutorialGifForGame = (gameType: GAME_TYPE) => {
  switch (gameType){
    case GAME_TYPE.DICES: return dicesTutorial;
    case GAME_TYPE.BOX3D: return box3dTutorial;
    case GAME_TYPE.BUBBLES: return bubbleTutorial
    case GAME_TYPE.DIFFERENCES: return differencesTutorial
    case GAME_TYPE.MEMORY:return memoryTutorial
    default: return
  }
}