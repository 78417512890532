import { GamePage } from "../../pages/GamePage";
import { RoutePath } from "../Routes.types"
import { RouteListItem } from "./RouteList.types"
import HomePage from "../../pages/HomePage"
import { UserRole } from "../../components/user/userRole/UserRole.types"

const testedPersonRoutes: RouteListItem[] = [
  {
    path: RoutePath.HOMEPAGE,
    component: HomePage,
    title: "Strona główna",
    permission: [UserRole.TESTED_PERSON],
  },
  {
    path: RoutePath.GAME,
    component: GamePage,
    title: "Gra",
    permission: [UserRole.TESTED_PERSON],
  }
]

export default testedPersonRoutes
