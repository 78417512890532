import {gql} from "@apollo/client";

export const EXAM_QUERY = gql`
  query GetUserById($userId: UUID!) {
    user: user(userId: $userId) {
      userId
      firstName
      lastName
      examsList(condition: {userId: $userId}) {
        answers
        type
        examinationId
      }
    }
  }
`;