export enum EXAM_TYPE {
  ATTENTION = "attention",
  FLUENCY = "fluency",
  LANGUAGE = "language",
  MEMORY = "memory",
  VISUAL = "visual"
}

export const getExamCategoryLabel = (value: EXAM_TYPE) => {
  switch (value){
    case EXAM_TYPE.ATTENTION: return "Uwaga"
    case EXAM_TYPE.FLUENCY: return "Fluencja"
    case EXAM_TYPE.LANGUAGE: return "Język"
    case EXAM_TYPE.MEMORY: return "Pamięć"
    case EXAM_TYPE.VISUAL: return "Funkcje wzrokowo-poznawcze"
    default: return value
  }
}