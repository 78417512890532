import { makeStyles, createStyles, Theme, alpha } from "@material-ui/core";

export const useUserGameCardStatsStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(1),
  },
  propertyWrapper: {
    display: "flex",
    marginBottom: theme.spacing(1),
    justifyContent: "center",
  },
  propertyTitle: {
    fontWeight: 700,
  },
}))