import { Box } from "@material-ui/core"
import React, { FC, useEffect, useState } from "react"
import background from "../../../assets/images/background/game1.jpg"
import { useBackgroundImage } from "../../../hooks/useBackgroundImage";
import { GameComponentProps } from "../../../pages/GamePage";
import { useGameCardStyles } from "../gameCard/GameCard.styles";
import clsx from "clsx"
import GameCard from "../gameCard/GameCard.component"
import { CardState, CardType, MemoryGameState, MemoryGameType } from "./MemoryGame.types"
import { useMemoryGameStyles } from "./MemoryGame.styles"
import MemoryGameCardComponent from "./memoryGameCard/MemoryGameCard.component"

const MemoryGame: FC<GameComponentProps<MemoryGameState>> = (
  {
    state,
    definition,
    startedAt,
    closedAt,
    attempts,
    gameType,
    onStart,
    onMove,
    onFinish,
  }
) => {
  useBackgroundImage(background);
  const classes = useGameCardStyles()
  const memoryClasses = useMemoryGameStyles()

  const [cards, setCards] = useState<MemoryGameType>(state.map((pairId, idx) => ({
    id: idx,
    pairId: pairId - 1,
    cardState: CardState.HIDING
  })))

  const [timeLimitReached, setTimeLimitReached] = useState<boolean>(false);
  const [attemptsMade, setAttemptsMade] = useState<number>(attempts);

  const foundPairsNumber = cards ? cards.filter(card => card.cardState === CardState.MATCHED).length / 2 : 0
  const pairsRemainsNuber = definition.size - foundPairsNumber
  const ended = timeLimitReached || pairsRemainsNuber === 0 || null != closedAt;
  const won = foundPairsNumber >= definition.target;

  useEffect(() => {
    if (ended) {
      onFinish(foundPairsNumber)
    }
  }, [attempts, won, ended])

  const onCardClick = (selectedCard: CardType) => () => {
    const showedCards = cards.filter(card => card.cardState === CardState.SHOWING)
    if (showedCards.length > 2) return

    setCards(cards.map(card => card.id !== selectedCard.id
      ? card
      : { ...card, cardState: CardState.SHOWING }
    ))

    if (showedCards.length) {
      const attemptsMadeNumber = attemptsMade + 1
      setAttemptsMade(attemptsMadeNumber)
      onMove(attemptsMadeNumber)

      if (showedCards[0].pairId === selectedCard.pairId) { // match
        setCards(cards.map(card => card.pairId === selectedCard.pairId
          ? { ...card, cardState: CardState.MATCHED }
          : card
        ))
      } else { // different cards
        window.setTimeout(() => {
          setCards(cards.map(card => [showedCards[0].id, selectedCard.id].includes(card.id)
            ? { ...card, cardState: CardState.HIDING }
            : card
          ))
        }, 1300)
      }
    }
  }

  const start = !startedAt ? () => onStart() : undefined;
  const finish = (startedAt && !ended) ? () => onFinish(foundPairsNumber) : undefined;

  return (
    <GameCard
      gameType={gameType}
      definition={definition}
      startedAt={startedAt}
      closedAt={closedAt}
      onStart={start}
      onEnd={finish}
      onTimerEnd={() => setTimeLimitReached(true)}
      won={won}
      ended={ended}
      stats={[
        {
          label: 'Liczba par do znalezienia',
          value: `${Math.max(0, definition.target - foundPairsNumber)} z ${definition.target}`
        },
      ]}
      resultDescription={`Zostało dopasowanych ${foundPairsNumber} z ${definition.target} wymaganych par.`}
    >
      <Box className={clsx(classes.game, classes.main)}>
        <Box className={memoryClasses.cardsWrapper}>
          { cards && cards.map((card, idx) => (
            <MemoryGameCardComponent
              level={definition.level}
              key={idx}
              card={card}
              disabled={!startedAt || cards.filter(card => card.cardState === CardState.SHOWING).length >= 2}
              onClick={ended || !startedAt || null == card.pairId ? undefined : onCardClick(card)}
            />
          ))}
        </Box>
      </Box>
    </GameCard>
  )
}

export default MemoryGame
