import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const usePaletteControllerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(2),
    },
    label: {
      color: theme.palette.common.white,
    },
    highContrastButton: {
      backgroundColor: "#FFC000",
    },
  })
);
