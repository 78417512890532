export type DicesTemplateCube = {
  id: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export type DicesTemplate = {
  items: Array<DicesTemplateCube>;
  width: number;
  height: number;
  answer: DicesTemplateAnswer;
  correctAnswer: number[][][];
  border: number;
}

export type DicesTemplateAnswer = Array<Array<number | null>>;

export type DropWrapperType = {
  droppableId: DroppableId
  index: number
}

export enum DroppableId {
  INIT_COL = "initColumn",
  RESULT_COL = "resultColumn",
}

export type DraggableBoardType = Array<Array<DicesTemplateCube | null>>

export enum DragItemType {
  CUBE = "cube"
}

export type DragObject = {
  type: DragItemType,
  id: number,
  sourceDroppableId: DroppableId,
  x: number,
  y: number,
}