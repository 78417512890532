import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLayoutContainerMaxWidth } from "../store/layout/layout.slice";

export const useContainerMaxWidth = (value?: "lg" | "xl") => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLayoutContainerMaxWidth(value));

    return value ? () => {
      dispatch(setLayoutContainerMaxWidth("lg"))
    } : undefined;
  }, [value, dispatch]);
}
