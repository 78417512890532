import React, { FC, useState } from "react"
import { Box } from "@material-ui/core"
import { yupResolver } from "@hookform/resolvers/yup"
import Popup from "../../common/popup/Popup.component"
import { useForm, FormProvider } from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import { ADD_USER } from "./AddUser.mutation"
import { useMutation } from "@apollo/client";
import UserRoleSelect from "../userRole/UserRoleSelect.component"
import generateUuid from "../../../utils/generateUuid"
import TenantsSelect from "../../tenants/tenantsSelect/TenantsSelect.component"
import { UserRole } from "../userRole/UserRole.types"
import { addUserValidationSchema } from "./AddUser.validation"
import { useSelector } from "react-redux"
import { selectCurrentTenantId, selectUserRole } from "../../../store/user/user.selectors"
import GlobalErrorSnackbar from "../../formItems/errors/globalErrorSnackbar/GlobalErrorSnackbar"

interface AddUserPopupProps {
  open: boolean;
  refetch(): void;
  onClose(): void;
}

const AddUserPopup: FC<AddUserPopupProps> = ({open, refetch, onClose}) => {
  const userRole = useSelector(selectUserRole)
  const tenantId = useSelector(selectCurrentTenantId)
  const [addUser, {loading}] = useMutation(ADD_USER)
  const [createUserError, setCreateUserError] = useState<string>("")

  const form = useForm({
    mode: "all",
    resolver: yupResolver(addUserValidationSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      tenantId: tenantId || "",
    }
  })

  const roleValue = form.watch("role")
  const isTenantFieldRequired = roleValue && roleValue !== UserRole.ADMIN

  const handleClose = () => {
    onClose()
    form.reset()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    setCreateUserError("")

    try {
      await addUser({
        variables: {
          input: {
            user: {
              userId: generateUuid(),
              ...values,
              tenantId: values.tenantId || undefined
            }
          }
        }
      })

      await refetch()
      handleClose()
    } catch (e: any) {
      if (e.graphQLErrors && e.graphQLErrors[0]?.message === 'duplicate key value violates unique constraint "users_email_key"') {
        form.setError("email", { message: "Użytkownik o podanym adresie e-mail już istnieje" })
      } else {
        setCreateUserError("Błąd rejestracji użytkownika")
      }
    }
  })

  return (
    <Popup
      open={open}
      onClose={handleClose}
      title="Dodaj użytkownika"
      actionButtonText={"Dodaj"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <TextFieldController
              name="firstName"
              label={'Imię'}
              isRequired
              trimStart
              mb
              disabled={loading}
            />

            <TextFieldController
              name="lastName"
              label={'Nazwisko'}
              isRequired
              trimStart
              mb
              disabled={loading}
            />

            <TextFieldController
              name="email"
              label={'E-mail'}
              isRequired
              mb
              disabled={loading}
            />

            <UserRoleSelect
              name="role"
              label={'Rola'}
              isRequired
              mb
              disabled={loading}
            />

            { (userRole === UserRole.ADMIN && isTenantFieldRequired) &&
              <TenantsSelect
                name="tenantId"
                label={'Podmiot'}
                isRequired={isTenantFieldRequired}
                mb
                disabled={loading || !isTenantFieldRequired}
              />
            }
          </form>
        </FormProvider>

        <GlobalErrorSnackbar
          isError={!!createUserError}
          errorMessage={createUserError}
        />
      </Box>
    </Popup>
  )
}

export default AddUserPopup
