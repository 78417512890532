import React, { FC } from "react"
import { Box } from "@material-ui/core"
import HomepageBackground from "../assets/images/background/homepage.svg";
import { useBackgroundImage } from "../hooks/useBackgroundImage";
import UserCompetencesCard from "../components/user/userCompetences/userCompetencesCard/UserCompetencesCard.component";
import UserGamesList from "../components/games/userGamesList/UserGamesList.component";

const HomePage: FC = () => {
  useBackgroundImage(HomepageBackground);

  return (
    <Box>
      <UserCompetencesCard />
      <UserGamesList/>
    </Box>
  )
}

export default HomePage