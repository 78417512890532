import {useCallback, useState} from "react"
import clientFetch from "../fetch"

const useFileDownload = (
  url: string
): {
    download(filename: string): void,
    downloading: boolean,
    downloadError?: unknown,
  } => {
  const [downloading, setDownloading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>()

  const download = useCallback( async (
    fileName: string,
  ) => {
    setDownloading(true)
    setError(null)

    try {
     await clientFetch(url, { method: 'GET' })
       .then(response => response.blob())
       .then(blob => {
         const anchor = document.createElement("a")
         anchor.setAttribute("href", URL.createObjectURL(blob))
         anchor.setAttribute("download", fileName)
         document.body.append(anchor)
         anchor.click()
         document.body.removeChild(anchor)
       })
    } catch (e: any) {
      console.error(e)
      setError(e)
    }
    setDownloading(false)
  }, [setDownloading, url])

  return {download, downloading, downloadError: error}
}

export default useFileDownload
