export const options = {
  scales: {
    y:{
      max: 100,
      min: 0,
      ticks: {
        callback: function(value: string | number){
          return `${value}%` ;
        }
      }
    }
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context: any){
          return `${context.parsed.y}%`
        }
      }
    }
  }
}