import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useScoreInputControllerStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    alignItems: "center"
  },
  input: {
    width: '70px',
    margin: theme.spacing(0, 1),
    '& .MuiInput-input': {
      ...theme.typography.h5,
    },
  }
}))