import React, { FC, useEffect } from "react"
import {Box} from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { useForm, FormProvider } from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import { UserItemNode } from "../../../store/user/user.types"
import UserRoleSelect from "../userRole/UserRoleSelect.component"
import TenantsSelect from "../../tenants/tenantsSelect/TenantsSelect.component"
import { UserRole } from "../userRole/UserRole.types"

interface PreviewUserPopupProps {
  user: UserItemNode;
  open: boolean;
  onClose(): void;
}

const PreviewUserPopup: FC<PreviewUserPopupProps> = ({user, open, onClose}) => {
  const isTenantFieldRequired = user.role && user.role !== UserRole.ADMIN

  const form = useForm({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      tenantId: user.tenant?.tenantId,
    }
  })

  useEffect(() => {
    if (open) {
      form.setValue("firstName", user.firstName)
      form.setValue("lastName", user.lastName)
    }
  }, [open])

  return (
    <Popup
      open={open}
      onClose={onClose}
      title="Podgląd użytkownika"
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <TextFieldController
              name="firstName"
              label={'Imię'}
              isRequired
              mb
              disabled={true}
            />

            <TextFieldController
              name="lastName"
              label={'Nazwisko'}
              isRequired
              mb
              disabled={true}
            />

            <TextFieldController
              name="email"
              label={'E-mail'}
              isRequired
              mb
              disabled={true}
            />

            <UserRoleSelect
              name="role"
              label={'Rola'}
              isRequired
              mb
              disabled={true}
            />

            { isTenantFieldRequired &&
              <TenantsSelect
                name="tenantId"
                label={'Podmiot'}
                isRequired={isTenantFieldRequired}
                mb
                disabled={true}
              />
            }
          </form>
        </FormProvider>
      </Box>
    </Popup>
  )
}

export default PreviewUserPopup
