import { gql } from "@apollo/client"

export const TENANTS_LIST_QUERY = gql`
    query GetAllTenants {
        connection: tenants {
            edges {
                node {
                    tenantId
                    name
                }
            }
        }
    }
`;