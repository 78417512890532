import React, { FC } from 'react'
import {alpha, Box} from "@material-ui/core";
import { Bar } from 'react-chartjs-2'
import {getExamCategoryLabel, EXAM_TYPE} from "./UserCompetencesChart.utils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import {useUserCompetencesChartStyles} from "./UserCompetencesChart.styles";
import {useSelector} from "react-redux";
import {useQuery} from "@apollo/client";
import {FORM_RESULT_QUERY} from "./UserCompetencesChart.query";
import {selectSessionUserId} from "../../../../store/session/session.selectors";
import LoaderBox from "../../../common/loaderBox/LoaderBox.component";
import {options} from "./UserCompetencesChart.options";
import theme from "../../../../themes/theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface UserCompetencesChartProps {
  userId?: string;
}

const UserCompetencesChart: FC<UserCompetencesChartProps> = ({userId}) => {
  const classes = useUserCompetencesChartStyles()
  const currentUserId = useSelector(selectSessionUserId)
  const {data, loading} = useQuery<{ user:{examResultsList: Array<{variable: EXAM_TYPE, percentage: number}>}}>(FORM_RESULT_QUERY, {
    variables: {
      userId: userId || currentUserId
    }
  })

  const convertToPercentage = (result: number): number => {
    return Number((result * 100).toFixed())
  }

  const chartData = {
    labels: data?.user?.examResultsList.map(({variable}) => getExamCategoryLabel(variable)),
    datasets: [
      {
        label: "Formularz wejścia",
        data: data?.user?.examResultsList.map(({percentage}) => convertToPercentage(percentage)),
        borderColor: theme.palette.primary.light,
        backgroundColor: alpha(theme.palette.primary.light, 0.5)
      }
    ]
  }

  if(loading) return <LoaderBox/>

  return (
    <Box className={classes.root}>
      <Bar
        data={chartData}
        height={100}
        options={options}
      />
    </Box>
  )
}

export default UserCompetencesChart