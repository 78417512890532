import React, {FC} from "react"
import {Box, CircularProgress, CircularProgressProps, useTheme} from "@material-ui/core"
import { useDownloadingButtonIconStyles } from "./DownloadingButtonIcon.styles"

interface DownloadingButtonIconProps {}

const DownloadingButtonIcon: FC<DownloadingButtonIconProps & CircularProgressProps> = (props) => {
  const theme = useTheme()
  const classes = useDownloadingButtonIconStyles()

  const defaultProps = {
    size: theme.spacing(1.5),
    thickness: 7
  }

  return (
    <Box
      mr={1}
      className={classes.root}
    >
      <CircularProgress
        {...defaultProps}
        {...props}
        color="inherit"
      />
    </Box>
  )
}

export default DownloadingButtonIcon
