import React, { FC } from "react"
import { useBox3dGridStyles } from "./Box3dGrid.styles"
import GameItem from "../../gameItem/GameItem"
import { Box3dTemplate } from "../Box3dGame.types"
import getImageNumber from "../Box3dGame.utils"

interface Box3dProps {
  grid: Box3dTemplate['projection']
}

const Box3dGrid: FC<Box3dProps> = ({grid}) => {
  const classes = useBox3dGridStyles()

  return (
    <div>
      { grid.map((row, rowIndex) =>
        <div key={rowIndex}>
          { row.map((item, columnIndex) =>
            <div key={`${rowIndex}-${columnIndex}`} className={classes.box}>
              {
                item
                  ? (
                    <div
                      className={classes.face}
                      style={{ transform: `rotate(${item.rotation}deg)` }}
                    >
                      <GameItem
                        size={125}
                        type={getImageNumber[item.type]}
                        assetsNumber={1}
                        backgroundSize={"cover"}
                      />
                    </div>
                  )
                  : <div className={classes.empty}/>
              }
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Box3dGrid
