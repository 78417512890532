import React, {FC} from 'react'
import {Box, Button} from "@material-ui/core";
import {useHistory} from "react-router";
import {useCreateFormButtonsStyles} from "./CreateFormButton.styles";
import {FORM_URL_TYPE} from "./CreateForm.types";

interface CreateFormButtonsProps {
  userId: string
}

const CreateFormButtons: FC<CreateFormButtonsProps> = ({userId}) => {
  const {push} = useHistory()
  const classes = useCreateFormButtonsStyles()

  return(
    <Box>
      <Button
        size={"small"}
        variant={"outlined"}
        color={"secondary"}
        className={classes.button}
        onClick={() => push(`/form/${FORM_URL_TYPE.ENTRY}/${userId}`)}
      >
        Ankieta Wejście
      </Button>
      <Button
        size={"small"}
        variant={"outlined"}
        color={"secondary"}
        className={classes.button}
        onClick={() => push(`/form/${FORM_URL_TYPE.EXIT}/${userId}`)}
      >
        Ankieta Wyjście
      </Button>
    </Box>
  )
}

export default  CreateFormButtons