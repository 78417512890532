import React, {FC, useEffect, useState} from "react"
import {Snackbar, Typography, SnackbarProps} from "@material-ui/core"
import {Alert, AlertTitle} from "@material-ui/lab"

interface GlobalSuccessSnackbarProps extends SnackbarProps {
  isSuccess: boolean;
  alertTitle?: string;
  successMessage?: string | JSX.Element;
}

const GlobalSuccessSnackbar: FC<GlobalSuccessSnackbarProps> = ({isSuccess, alertTitle, successMessage, ...props}) => {
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState<boolean>(false)
  const handleSuccessSnackbarClose = () => {
    setOpenSuccessSnackbar(false)
  }

  useEffect(() => {
    setOpenSuccessSnackbar(isSuccess)
  }, [isSuccess])

  return (
    <Snackbar
      open={openSuccessSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={6000}
      onClose={handleSuccessSnackbarClose}
      {...props}
    >
      <Alert
        variant="filled"
        severity="success"
      >
        { alertTitle && (
          <AlertTitle>{ alertTitle }</AlertTitle>
        )}

        <Typography className={"break-spaces"}>
          { successMessage }
        </Typography>
      </Alert>
    </Snackbar>
  )
}

export default GlobalSuccessSnackbar