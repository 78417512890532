import { gql } from "@apollo/client"

export const UPDATE_GAME_LEVEL = gql`
  mutation updateGameLevel($input: UpdateGameLevelInput!) {
    updateGameLevel (input: $input) {
      clientMutationId
    }
  }
`;

export const UPDATE_GAME_DEFINITION = gql`
  mutation updateGameDefinition($input: UpdateGameDefinitionInput!) {
    updateGameDefinition (input: $input) {
      clientMutationId
    }
  }
`;