import React, { FC, useState } from "react"
import { Box, Typography } from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { useMutation } from "@apollo/client";
import { ARCHIVE_TENANT } from "./ArchiveTenant.mutation"
import { getMessageErrorTranslation } from "../../formItems/errors/errorMessagesMap"
import GlobalFormErrorMessage from "../../formItems/errors/globalFormErrorMessage/GlobalFormErrorMessage.component"

interface ArchiveTenantPopupProps {
  tenantId: string;
  name: string;
  open: boolean;
  onClose(): void;
  refetch(): void;
}

const ArchiveTenantPopup: FC<ArchiveTenantPopupProps> = ({tenantId, name, open, refetch, onClose}) => {
  const [archiveTenant, {loading}] = useMutation(ARCHIVE_TENANT)
  const [error, setError] = useState<string>()

  const handlePopupClose = () => {
    setError("")
    onClose()
  }

  const handleSubmit = async () => {
    setError("")

    try {
      await archiveTenant({
        variables: {
          input: {
            tenantId,
          }
        }
      })

      await refetch()
      onClose()
    } catch (exception: any) {
      if (exception.graphQLErrors && exception.graphQLErrors.length && exception.graphQLErrors[0].message) {
        setError(getMessageErrorTranslation(exception.graphQLErrors[0].message))
      }
      // processResponseException(form, exception)
    }
  }

  return (
    <Popup
      open={open}
      onClose={handlePopupClose}
      title="Archiwizuj podmiot"
      closeButtonText={"Nie, zamknij"}
      actionButtonText={"Tak, potwierdź"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
    >
      <Box>
        <Typography>
          Czy na pewno chcesz zarchiwizować podmiot <b>{ name }</b>?
        </Typography>
        <Typography>
          <b>Uwaga!</b> Operacja archiwizacji jest nieodwracalna.
        </Typography>
      </Box>

      { error &&
        <Box mt={3}>
          <GlobalFormErrorMessage message={error}/>
        </Box>
      }
    </Popup>
  )
}

export default ArchiveTenantPopup
