import { FC, useEffect, useRef, useState } from "react";
import * as React from "react";

interface TimerProps {
  timeLimit: number;
  startedAt?: Date | null;
  endedAt?: Date | null;
  onEnd?(): void;
}

const Timer: FC<TimerProps> = ({timeLimit, startedAt, endedAt, onEnd}) => {
  const [, setRenderToken] = useState<number>(0);
  const interval = useRef<any>();
  const callback = useRef(onEnd);

  useEffect(() => {
    callback.current = onEnd;
  }, [onEnd]);

  useEffect(() => {
    if (callback.current && startedAt && !secondsLeft(startedAt as Date, endedAt, timeLimit)) {
      callback.current();
    }
  }, [startedAt, timeLimit]);

  useEffect(() => {
    if (!startedAt) return;
    setRenderToken(0);

    interval.current = setInterval(() => {
      setRenderToken((i) => i + 1);

      if (callback.current && startedAt && !secondsLeft(startedAt as Date, endedAt, timeLimit)) {
        callback.current();
        clearInterval(interval.current);
      }
    }, 1000);

    return () => {
      clearInterval(interval.current);
    }
  }, [startedAt, setRenderToken]);

  return <>{startedAt ? secondsLeft(startedAt, endedAt, timeLimit) : timeLimit} sekund</>
};

const secondsLeft = (startDate: Date, endedAt: Date | null | undefined, value: number) => {
  const end = new Date(+startDate+value*1000);
  const now = endedAt || new Date();

  return Math.round(Math.max(0, +end - +now)/1000);
}

export default Timer;
