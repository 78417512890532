import React, { FC } from "react"
import { Box, Card, Typography } from "@material-ui/core"
import { RouteComponentProps, useHistory, useParams } from "react-router"
import { useQuery } from "@apollo/client"
import { USER_QUERY } from "../store/user/user.query"
import { UserDataState } from "../store/user/user.types"
import { RoutePath } from "../routes/Routes.types"
import UserGamesList from "../components/games/userGamesList/UserGamesList.component"
import UserCompetencesChart
  from "../components/user/userCompetences/userCompetencesChart/UserCompetencesChart.component"
import { isIdValidated } from "../utils/validateId"
import LoaderBox from "../components/common/loaderBox/LoaderBox.component"
import {FORM_URL_TYPE} from "../components/entryExitFormResult/createFormButtons/CreateForm.types";

export interface UserGameStatusPageProps {
  userId: string
  formType: FORM_URL_TYPE
}

const UserGameStatusPage: FC<RouteComponentProps<UserGameStatusPageProps>> = () => {
  const {userId} = useParams<UserGameStatusPageProps>()
  const {push} = useHistory()
  const {data, loading} = useQuery<{ user: UserDataState }>(USER_QUERY,
    {
      variables: {userId}
    }
  )

  if (!userId || !isIdValidated(userId) || (data && !data.user)) {
    push(RoutePath.ERROR_404)
  }

  if (loading) {
    return <LoaderBox/>
  }

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h2">
          Status użytkownika 
          { (data && data.user) && (
            <b>{ data.user!.firstName } { data.user!.lastName }</b>
          )}
        </Typography>
      </Box>

      <Box>
        <Box mb={6}>
          <Card>
            <Box p={3}>
              <UserCompetencesChart userId={userId}/>
            </Box>
          </Card>
        </Box>

        <UserGamesList userId={userId} disabled/>
      </Box>
    </Box>
  )
}

export default UserGameStatusPage
