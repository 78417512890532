import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "redux";
import { LayoutState } from "./layout.types"

const initialState: LayoutState = {
  containerMaxWidth: "lg"
};

const userSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setLayoutBackground: (state: LayoutState, {payload}: PayloadAction<string|undefined>) => {
      state.background = payload;
      return state;
    },
    setLayoutContainerMaxWidth: (state: LayoutState, {payload}: PayloadAction<"lg"|"xl"|undefined>) => {
      state.containerMaxWidth = payload;
      return state;
    },
  }
})

export const {
  setLayoutBackground,
  setLayoutContainerMaxWidth,
} = userSlice.actions

export default userSlice.reducer as Reducer<LayoutState>
