import { UserRole } from "../../components/user/userRole/UserRole.types"

export const USER_STORAGE_KEY = "user"

export type UserDataState = UserItemNode | null

export type UserItemNode = {
  userId: string,
  email: string,
  firstName: string,
  lastName: string,
  role: UserRole,
  archived: boolean;
  lastActivity?: string;
  tenant?: {
    tenantId: string;
    name: string;
  }
}

export interface UserQuery {
  data: {
    user: UserDataState
  }
}