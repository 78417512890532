import React, {ChangeEvent, FC, useEffect} from "react";
import {Box, TextField, Typography} from '@material-ui/core'
import {Controller, useFormContext} from 'react-hook-form'
import { useScoreInputControllerStyles } from './ScoreInputController.styles'
import { StandardTextFieldProps } from "@material-ui/core/TextField/TextField"

interface ScoreFormResultProps extends StandardTextFieldProps {
  name: string;
  maxPoints: number;
  category: string;
  examValue?: number | string
}

const ScoreFormResult: FC<ScoreFormResultProps> = ({ name, maxPoints,category ,examValue, ...props}) => {
  const form = useFormContext();
  const classes = useScoreInputControllerStyles();

  useEffect(() => {
    form.setValue(name, examValue)
  },[examValue])

  const handleChange = (onChange: (value: string | number | null) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    onChange(value === '' ? null : value)}

  return (
    <Box>
      <Controller
        name={name}
        control={form.control}
        render={(
          {
            field: {
              onChange,
              value
            },
            fieldState: {
              error,
              invalid
            }
          }) => (
          <>
            <Box className={classes.root}>
              <Typography
                component='p'
                variant='h4'
              >
                {category} [Wynik 0-{maxPoints}]
              </Typography>

              <TextField
                id={name}
                value={value}
                onChange={handleChange(onChange)}
                className={classes.input}
                inputProps={{style: {textAlign: "center"}}}
                variant="outlined"
                error={invalid}
                {...props}
              />

              <Typography component='p' variant='h4'>
                / {maxPoints}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography color={'error'}>
                { error?.message }
              </Typography>
            </Box>
          </>
        )}
      />
    </Box>
  )
}

export default ScoreFormResult;