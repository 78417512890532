import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SettingsState } from "./settings.types"
import {Reducer} from "redux"

const initialState: SettingsState = {
  appName: "Trumpo",
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state: SettingsState, action: PayloadAction<Partial<SettingsState>>) => {
      return { ...state, ...action.payload }
    },
    clearSettings: () => {
      return { ...initialState }
    },
  },
})

export const {
  setSettings,
  clearSettings
} = settingsSlice.actions

export default settingsSlice.reducer as Reducer<typeof initialState>
