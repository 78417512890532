import React, {FC, useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import {useSummaryScoreStyles} from "./SummaryScore.styles";
import {Box, Divider, Typography} from "@material-ui/core"
import {getQuestionCardLabel} from "../questionsCard/QuestionCard.utils";
import {QuestionsType} from "../questionsCard/QuestionsCard.component";
import {questionsData} from "../questionsCard/QuestionsCardData.utils";

const SummaryScore: FC = () => {
  const { watch } = useFormContext();
  const watchAllFields = watch();
  const classes = useSummaryScoreStyles();

  const getMaxCategoryPoints = (typeOfQuestion: QuestionsType) => {
    const totalValue = questionsData.reduce((summary, value) => value.questionType === typeOfQuestion ? summary + value.maxPoints : summary, 0)
    return totalValue
  }

  const getCategoryInputs = (typeOfQuestion: QuestionsType) => {
    const categoryInputs: string[] = []
    questionsData.forEach((item,index) => {
      if(item.questionType === typeOfQuestion){
        categoryInputs.push(`answers[${index}]`)
      }
    })
    return categoryInputs
  }

  const [attentionScore, setAttentionScore] = useState<number>()
  const [memoryScore, setMemoryScore] = useState<number>()
  const [fluencyScore, setFluencyScore] = useState<number>()
  const [visuospatialScore, setVisuospatialScore] = useState<number>()
  const [languageScore, setLanguageScore] = useState<number>()
  const [summaryScore, setSummaryScore] = useState<number>()

  const attentionFieldsArray = watch(getCategoryInputs(QuestionsType.ATTENTION))
  const memoryFieldsArray = watch(getCategoryInputs(QuestionsType.MEMORY))
  const fluencyFieldsArray = watch(getCategoryInputs(QuestionsType.FLUENCY))
  const visuospatialFieldsArray = watch(getCategoryInputs(QuestionsType.VISUOSPATIAL_ABILITIES))
  const languageFieldsArray = watch(getCategoryInputs(QuestionsType.LANGUAGE))
  const allFieldsArray = watch(questionsData.map((item,index) => `answers[${index}]`))

  const sumArray = (arr: string[]): number => {
    const finalArray= arr.reduce((acc, item) => isNaN(Number(item)) ? acc : acc + Number(item),0)
    return finalArray
  }

  useEffect(() => {
    setAttentionScore(sumArray(attentionFieldsArray))
    setMemoryScore(sumArray(memoryFieldsArray))
    setFluencyScore(sumArray(fluencyFieldsArray))
    setVisuospatialScore(sumArray(visuospatialFieldsArray))
    setLanguageScore(sumArray(languageFieldsArray))
    setSummaryScore(sumArray(allFieldsArray))
  },[watchAllFields])

  const categories = [
    {
      category: getQuestionCardLabel(QuestionsType.ATTENTION),
      points: attentionScore,
      maxPoints: getMaxCategoryPoints(QuestionsType.ATTENTION)
    },
    {
      category: getQuestionCardLabel(QuestionsType.MEMORY),
      points: memoryScore,
      maxPoints: getMaxCategoryPoints(QuestionsType.MEMORY)
    },
    {
      category: getQuestionCardLabel(QuestionsType.FLUENCY),
      points: fluencyScore,
      maxPoints: getMaxCategoryPoints(QuestionsType.FLUENCY)
    },
    {
      category: getQuestionCardLabel(QuestionsType.LANGUAGE),
      points: languageScore,
      maxPoints: getMaxCategoryPoints(QuestionsType.LANGUAGE)
    },
    {
      category: getQuestionCardLabel(QuestionsType.VISUOSPATIAL_ABILITIES),
      points: visuospatialScore,
      maxPoints: getMaxCategoryPoints(QuestionsType.VISUOSPATIAL_ABILITIES)
    },
    {
      category: "Wynik ogólny ACE-III",
      points: summaryScore,
      maxPoints: questionsData.reduce((summary, value) => summary + value.maxPoints, 0)
    },
  ]

  return (
    <Box className={classes.root}>
      <Typography
        variant='h3'
        className={classes.title}
        component='p'
      >
        Wyniki
      </Typography>
      <Box className={classes.contentWrapper}>
        {categories.map((category, index) => (
          <Box>
            <Box
              className={classes.row}
              key={category.category}
            >
              <Typography
                className={classes.category}
                component='p'
              >
                {category.category}:
              </Typography>
              <Box className={classes.pointsWrapper}>
                <Typography
                  variant='h5'
                  component='p'
                >
                  {category.points}
                </Typography>
                <Typography
                  variant='h5'
                  component='p'
                >
                  /{category.maxPoints}
                </Typography>
              </Box>
            </Box>
            {index !== categories.length - 1 && <Divider/>}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default SummaryScore;