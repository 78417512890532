export enum RoutePath {
  BASE = "/",
  HOMEPAGE = "/games",
  ADMIN_HOMEPAGE = "/admin",
  USER_GAMES_STATUS = "/user/:userId",
  LOGIN = "/login/:ref?",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password/:token/:newPassword?",
  GAME= "/game/:gameId/:notCompleted?",
  USERS = "/users",
  REPORTS = "/reports",
  TENANTS = "/tenants",
  ERROR_404 = "/404",
  ERROR_403 = "/403",
  SERVER_ERROR = "/server-error/:code",
  FALLBACK = "/*",
  ENTRY_EXIT_FORM_RESULT = "/form/:formType/:userId",
  SETTINGS = "/settings",
}
