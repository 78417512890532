import { alpha, makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"
import { Theme as DefaultTheme } from "@material-ui/core/styles/createTheme";

export const useGameCardStyles = makeStyles<DefaultTheme>((theme: Theme) => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  disabled: {
    "& *": {
      pointerEvents: "none",
    }
  },
  content: {
    display: "flex",
    width: "100%",
    flex: 1,
    gap: 10,
    paddingBottom: theme.spacing(2),
  },
  main: {
    background: alpha(theme.palette.background.paper, 0.5),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[5],
    height: "100%",
    position: "relative",
    transition: "transform .8s",
    overflow: "hidden"
  },
  wrapperPlaceholder: {
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.primary.light,
    }
  },
}))
