import { makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"
import { Theme as DefaultTheme } from "@material-ui/core/styles/createTheme";

interface GameCardStylesProps {
  size: number;
}

export const useMemoryGameCardStyles = makeStyles<DefaultTheme, GameCardStylesProps>((theme: Theme) => createStyles({
  flipperWrap: {
    position: "relative",
    perspective: 1000,
    width: ({size}) => `${size}px`,
    height: ({size}) => `${size}px`,
    border: "none",
    background: "none",
    display: "block",
    padding: 0,
    pointerEvents: "none",
    margin: theme.spacing(0, 1, 1, 0),
  },
  flipperWrapReversed: {
    pointerEvents: "auto",
    cursor: "pointer",
  },
  flipper: {
    transition: ".6s",
    transformStyle: "preserve-3d",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  flipperReversed: {
    transform: "rotateY(180deg)",
  },
  card: {
    backfaceVisibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  cardFront: {
  },
  cardBack: {
    transform: "rotateY(180deg)",
    background: theme.palette.primary.light,
    borderRadius: "50%",
    boxShadow: theme.shadows["5"],
  }
}))
