import React, { FC, Fragment, ReactNode } from "react"
import { Box, Button } from "@material-ui/core"
import { useGameSidebarStyles } from "./GameSidebar.styles"
import { RoutePath } from "../../../routes/Routes.types"
import { useHistory } from "react-router"

interface GameSidebarProps {
  title: string;
  level: number;
  stats?: Array<{
    label: ReactNode,
    value: ReactNode,
  }>;
  onStart?(): void;
  onEnd?(): void;
}

const GameSidebar: FC<GameSidebarProps> = (props) => {
  const classes = useGameSidebarStyles()
  const {push} = useHistory()

  const stats = props.stats?.map((item,key) => (
    <Fragment key={key}>
      <dt className={classes.statsTitle}>{item.label}</dt>
      <dd className={classes.statsValue}>{item.value}</dd>
    </Fragment>
  ));

  return (
    <Box className={classes.box}>
      <div>
        <dl className={classes.stats}>
          {stats}
        </dl>
      </div>

      {props.children}

      <div>
        <Button
          variant={"contained"}
          color={"secondary"}
          fullWidth
          className={classes.button}
          onClick={props.onEnd ? props.onEnd : () => push(RoutePath.HOMEPAGE)}
        >
          { props.onEnd
            ? "Zakończ"
            : "Wróć do listy gier"
          }
        </Button>
      </div>
    </Box>
  )
}

export default GameSidebar
