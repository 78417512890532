import React, {FC, useEffect, useState} from 'react'
import {Box, Typography} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {GAMES_QUERY} from "./UserGamesList.query";
import {useUserGamesListStyles} from "./UserGamesList.styles";
import {GameNode, GameListItemType} from "../Game.types";
import UserGameCard from "./userGameCard/UserGameCard.component";
import { useSelector } from "react-redux"
import { selectSessionUserId } from "../../../store/session/session.selectors"
import { useIsHighContrast } from '../../../hooks/useIsHightContrast';

interface UserGamesListProps {
  userId?: string;
  disabled?: boolean;
}

const UserGamesList: FC<UserGamesListProps> = ({disabled = false, userId}) => {
  const classes = useUserGamesListStyles()
  const currentUserId = useSelector(selectSessionUserId)
  const isHighContrast = useIsHighContrast()

  const [games, setGames] = useState<GameListItemType[]>([])
  const {data} = useQuery<{ games: { edges: Array <{node: GameNode}>}}>(GAMES_QUERY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    variables: {
      id: userId || currentUserId
    }
  })

  useEffect(() => {
    if (data) {
      const gamesList = data.games.edges.map(edge => edge.node)
      const sortedList = gamesList.reduce((gamesArray: GameListItemType[], gameLevelItem) => {
        const gameTypeArr = gamesArray.find(typeArr => typeArr.type === gameLevelItem.game)

        return gameTypeArr
          ? gamesArray.map(typeArr => typeArr.type === gameLevelItem.game
            ? {...typeArr, levels: [...typeArr.levels, gameLevelItem]}
            : typeArr
          )
          : [...gamesArray, {
            type: gameLevelItem.game,
            levels: [gameLevelItem]
          }]
      }, [])
      setGames(sortedList)
    }
  }, [data])

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography
          component='p'
          variant='h3'
          className={isHighContrast ? classes.highContrastTheme : classes.defaultTheme}
        >
          Lista gier
        </Typography>
      </Box>

      <Box>
        { games.map(game => (
          <Box key={game.type} className={classes.gameRow}>
            <Typography
              component='p'
              variant='h4'
              className={classes.gameTitle}
            >
              {game.levels[0].gameDefinitionByGame.name}
            </Typography>

            <Box className={classes.gamesSection}>
              {game.levels.map((level: GameNode) => (
                <UserGameCard
                  key={level.level}
                  disabled={disabled}
                  {...level}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default UserGamesList