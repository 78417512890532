import { gql } from "@apollo/client"

export const USER_QUERY = gql`
    query GetUserById($userId: UUID!) {
        user: user(userId: $userId) {
            userId
            email
            firstName
            lastName
            role
            archived
            tenant {
                name
                tenantId
            }
        }
    }
`;
