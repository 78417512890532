import {makeStyles} from "@material-ui/core"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useForgetPasswordStyles = makeStyles((theme: Theme) => createStyles({
  input: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    width: "100%",
    padding: theme.spacing(1.5, 0)
  }
}))
