import { gql, useMutation, useQuery } from "@apollo/client";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { GAME_TYPE } from "../components/games/Game.types"
import BubblesGame from "../components/games/bubbleGame/BubblesGame.component"
import { parseDate } from "../utils/parseDate";
import MemoryGame from "../components/games/memoryGame/MemoryGame.component"
import DifferencesGame from "../components/games/differencesGame/DifferencesGame.component"
import { RoutePath } from "../routes/Routes.types"
import { Redirect } from "react-router-dom"
import LoaderBox from "../components/common/loaderBox/LoaderBox.component"
import Box3dGame from "../components/games/box3dGame/Box3dGame.component"
import DicesGame from "../components/games/dicesGame/DicesGame.component"
import { isIdValidated } from "../utils/validateId"

const GAME_QUERY = gql`
  query Game($gameId: UUID!) {
    game(gameId: $gameId) {
      nodeId
      gameType
      level
      template
      startedAt
      closedAt
      data
      attempts
      seed
      definition: gameLevelByGameTypeAndLevel {
        size
        level
        maxAttempts
        timeLimit
        target
        gameType: gameDefinitionByGame {
          name
          description
        }
      }
    }
  }
`;

const SAVE_GAME_MUTATION = gql`
  mutation SaveGame($gameId: UUID!, $data: GamePatch!) {
    updateGame(input: {gameId: $gameId, patch: $data}) {
      game {
        nodeId
        startedAt
        closedAt
        result
      }
    }
  }
`;

const START_GAME_MUTATION = gql`
  mutation StartGame($gameId: UUID!) {
    startGame(input: {gameId: $gameId}) {
      game {
        nodeId
        startedAt
      }
    }
  }
`;

const FINISH_GAME_MUTATION = gql`
  mutation FinishGame($gameId: UUID!, $result: Int!) {
    endGame(input: {gameId: $gameId, result: $result}) {
      game {
        nodeId
        closedAt
        result
        data
      }
    }
  }
`;

export interface GameQueryResponse {
  game: {
    gameType: GAME_TYPE;
    template: any;
    startedAt: string;
    closedAt: string;
    result: number;
    data: any;
    attempts: number;
    seed: number;
    definition: {
      level: number;
      size: number;
      maxAttempts?: number;
      timeLimit?: number;
      target: number;
      gameType: {
        name: string;
        description: string;
      }
    }
  }
}

export interface GameComponentProps<T> {
  state: T
  gameType: GAME_TYPE;
  startedAt?: Date | null;
  closedAt?: Date | null;
  attempts: number;
  hacks: boolean;
  definition: GameQueryResponse["game"]["definition"]

  onStart(): void;

  onMove(attempts: number, data?: T): void;

  onFinish(result: number, data?: T): void;
}

export const GamePage: FC = () => {
  const {gameId} = useParams<{ gameId: string }>();
  const {push} = useHistory()
  if (!gameId || !isIdValidated(gameId)) {
    push(RoutePath.ERROR_404)
  }

  const [hacks, setHacks] = useState(false);
  useEffect(() => {
    (window as any)['useTrumpoHacks'] = (password: string) => setHacks(password === 'edgeteq');
  }, [setHacks]);

  const {data, loading, error} = useQuery<GameQueryResponse>(GAME_QUERY, {variables: {gameId}});
  const [executeUpdate] = useMutation(SAVE_GAME_MUTATION);
  const [finishGame] = useMutation(FINISH_GAME_MUTATION);
  const [startGame] = useMutation(START_GAME_MUTATION);

  const onFinish = useCallback((result: number) => {
    finishGame({
      variables: {
        gameId,
        result,
      }
    });
  }, [executeUpdate, gameId]);

  const onStart = useCallback(() => {
    startGame({
      variables: {
        gameId,
      }
    });
  }, [executeUpdate, gameId]);

  const onMove = useCallback((attempts: number, data: any) => {
    executeUpdate({
      variables: {
        gameId,
        data: {
          attempts,
          data,
        }
      }
    });
  }, []);

  useEffect(() => {
    if (data?.game?.seed && !data?.game?.startedAt) {
      // consolelog game uniq identificator (seed) to verify game in bug case
      // eslint-disable-next-line no-console
      console.debug(data?.game?.seed)
    }
  }, [data])

  if (error) throw error;
  if (loading) return <LoaderBox/>
  if (!data?.game) return <Redirect to={RoutePath.ERROR_404}/>

  const props: GameComponentProps<any> = {
    startedAt: parseDate(data!.game.startedAt),
    closedAt: parseDate(data!.game.closedAt),
    state: null == data!.game.data ? data!.game.template : data!.game.data,
    definition: data!.game.definition,
    attempts: data!.game.attempts,
    gameType: data!.game.gameType,
    hacks,
    onStart,
    onMove,
    onFinish,
  }

  switch (data?.game?.gameType as GAME_TYPE | undefined) {
    case GAME_TYPE.MEMORY:
      return <MemoryGame {...props}/>
    case GAME_TYPE.DIFFERENCES:
      return <DifferencesGame {...props}/>
    case GAME_TYPE.DICES:
      return <DicesGame {...props}/>
    case GAME_TYPE.BOX3D:
      return <Box3dGame {...props}/>
    case GAME_TYPE.BUBBLES:
      return <BubblesGame {...props}/>
  }

  return null;
};
