import React, {FC} from "react"
import {Container, Box,} from "@material-ui/core"
import { useSelector } from "react-redux";
import { selectBackgroundImage, selectContainerMaxWidth } from "../store/layout/layout.selectors";
import BaseLayout from "./BaseLayout"
import useLayoutStyles from "./Layout.styles"
import UserMenu from "../components/menu/userMenu/UserMenu.component"
import SessionManager from "../components/common/sessionManager/SessionManager.component"
import PaletteController from "../components/wcag/paletteController/PaletteController.component";
import TypographyController from "../components/wcag/typographyController/TypographyController.component";

const TestedPersonLayout: FC = ({ children }) => {
  const bcgImageSrc = useSelector(selectBackgroundImage);
  const containerMaxWidth = useSelector(selectContainerMaxWidth);
  const classes = useLayoutStyles({bcgImageSrc})

  return (
    <BaseLayout>
      <Box className={classes.testedPersonLayout}>
        <Box className={classes.topBar}>
          <SessionManager/>
          <PaletteController/>
          <TypographyController/>
          <UserMenu/>
        </Box>

        <Box display="flex" flex="1">
          <Container
            maxWidth={containerMaxWidth || "lg"}
            className={classes.contentContainer}
          >
            <Box height={"100%"}>
              { children }
            </Box>
          </Container>
        </Box>
      </Box>
    </BaseLayout>
  )
}

export default TestedPersonLayout
