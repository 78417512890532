import { Box, Typography } from "@material-ui/core"
import React, { FC, useEffect, useState } from "react"
import background from "../../../assets/images/background/game2.svg"
import { useBackgroundImage } from "../../../hooks/useBackgroundImage";
import { GameComponentProps } from "../../../pages/GamePage";
import GameCard from "../gameCard/GameCard.component"
import clsx from "clsx"
import { useGameCardStyles } from "../gameCard/GameCard.styles"
import DifferanceGameImage from "./differenceGameImage/DifferanceGameImage.component"
import { useDifferenceGameStyles } from "./DifferanceGame.styles"
import { useContainerMaxWidth } from "../../../hooks/useContainerMaxWidth"

type GameState = null;

const DifferencesGame: FC<GameComponentProps<GameState>> = (
  {
    gameType,
    definition,
    startedAt,
    closedAt,
    attempts,
    onStart,
    onMove,
    onFinish,
  }
) => {
  useBackgroundImage(background);
  useContainerMaxWidth("xl");

  const [baseImageSrc, setBaseImageSrc] = useState<string>();
  const [timeLimitReached, setTimeLimitReached] = useState<boolean>(false);
  const [foundDifferencesNumber, setFoundDifferencesNumber] = useState<number>(0);
  const [attemptsMade, setAttemptsMade] = useState<number>(attempts);

  const started = startedAt != null;
  const ended = timeLimitReached || null != closedAt;
  const won = foundDifferencesNumber === definition.target;

  const cardGameClasses = useGameCardStyles({won, ended, started})
  const classes = useDifferenceGameStyles()

  const start = !started ? () => onStart() : undefined;
  const finish = (started && !ended) ? () => onFinish(foundDifferencesNumber) : undefined;

  const handlaOnImageClick = (onDiffClick: boolean) => {
    if (onDiffClick) {
      setFoundDifferencesNumber(foundDifferencesNumber + 1)
    }

    setAttemptsMade(attemptsMade + 1)
    onMove(attemptsMade + 1)
  }

  useEffect(() => {
    if (ended || won) {
      onFinish(foundDifferencesNumber)
    }
  }, [attempts, won, ended])

  useEffect(() => {
    if (definition) {
      import(`./assets/level${definition.level}/baseImage.svg`)
        .then((module) => setBaseImageSrc(module.default));
    }
  }, [definition]);

  return (
    <GameCard
      gameType={gameType}
      definition={definition}
      startedAt={startedAt}
      closedAt={closedAt}
      won={won}
      ended={ended}
      onStart={start}
      onEnd={finish}
      onTimerEnd={() => setTimeLimitReached(true)}
      stats={[
        {
          label: 'Liczba różnic do znalezienia',
          value: `${Math.max(0, definition.target - foundDifferencesNumber)} z ${definition.target}`
        }
      ]}
      resultDescription={`Znaleziono ${foundDifferencesNumber} z ${definition.target} wymaganych różnic.`}
    >
      <Box className={clsx(cardGameClasses.game, cardGameClasses.main, classes.wrapper)}>
        <Box className={clsx(classes.imageWrapper, !started && classes.imageWrapperPlaceholder)}>
          <Typography className={classes.boxTitle}>
            Obrazek główny:
          </Typography>

          <img src={baseImageSrc} alt="" className={classes.image}/>
        </Box>

        <Box className={clsx(classes.imageWrapper, !started && classes.imageWrapperPlaceholder)}>
          <Typography className={classes.boxTitle}>
            Wskaż różnice:
          </Typography>

          <DifferanceGameImage
            level={definition.level}
            disabled={ended || !started}
            onImageClick={handlaOnImageClick}
          />
        </Box>
      </Box>
    </GameCard>
  )
}

export default DifferencesGame
