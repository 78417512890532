import React, {FC, useEffect, useState} from "react"
import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks"
import {selectSessionExpirationDate} from "../../../store/session/session.selectors"
import { clearSession, refreshSession } from "../../../store/session/session.slice"
import useInterval from "../../../hooks/useInterval"
import SessionManagerPopup from "./sessionManagerPopup/SessionManagerPopup.component"
import { useHistory } from "react-router"

const SHOW_ALERT_TIME = 60 * 2 // 2min

interface SessionManagerProps {}

const SessionManager: FC<SessionManagerProps> = () => {
  const dispatch = useAppDispatch()
  const {push} = useHistory()

  const expirationDate = useAppSelector(selectSessionExpirationDate)
  const [sessionPopupOpen, setSessionPopupOpen] = useState<boolean>(false)
  const [now, setNow] = useState<Date>(new Date())

  const getNow = () => {
    setNow(new Date())
  }
  const {start, stop} = useInterval(getNow, 1000)
  const secondsLeft = expirationDate ? Math.floor((+expirationDate - +now / 1000)) : null

  if (null == secondsLeft || secondsLeft < 1) {
    dispatch(clearSession())
    push('/login')
  }

  if (secondsLeft! <= SHOW_ALERT_TIME && !sessionPopupOpen) {
    setSessionPopupOpen(true)
  } else if (secondsLeft! > SHOW_ALERT_TIME && sessionPopupOpen) {
    setSessionPopupOpen(false)
  }

  const minutes = Math.floor(secondsLeft! / 60)
  const seconds = secondsLeft! % 60

  useEffect(() => {
    start()
    return (): void => {
      stop()
    }
  }, [start])

  const refresh = () => {
    setSessionPopupOpen(false)
    dispatch(refreshSession())
  }

  const timeLeft = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`

  return (
    <SessionManagerPopup
      open={sessionPopupOpen}
      onClose={() => {setSessionPopupOpen(false)}}
      timeLeft={timeLeft}
      refresh={refresh}
    />
  )
}

export default SessionManager
