import { PaletteOptions } from "@material-ui/core/styles/createPalette"

export const defaultPalette: PaletteOptions = {
  primary: {
    main: "#0F609B",
    light: "#2680C2",
    dark: "#003E6B"
  },
  secondary: {
    main: "#334E68",
    dark: "#102A43",
    light: "#627D98"
  },
  background: {
    default: "#D9E2EC",
    paper: "#F0F4F8",
  },
  error: {
    main: "#BA2525",
    light: "#FFCCCC",
  },
  success: {
    main: "#379683",
    dark: "#80b918"
  },
  action: {
    disabledBackground: "rgba(130, 130, 130, 0.95)",
    disabled: "#D3D3D3",
  }
}

export const highContrastPalette: PaletteOptions = {
  primary: {
    main: "#FFC000",
    light: "#000",
    dark: "#000"
  },
  secondary: {
    main: "#FFC000",
    dark: "#000",
    light: "#000"
  },
  background: {
    default: "#000",
    paper: "#FFC000",
  },
  error: {
    main: "#000",
    light: "#000",
  },
  success: {
    main: "#000",
    dark: "#000"
  },
  action: {
    disabledBackground: "rgba(130, 130, 130, 0.95)",
    disabled: "#D3D3D3",
  }
}
