export type MemoryGameState = Array<number>;

export type MemoryGameType = Array<CardType>;

export type CardType = {
  id: number,
  pairId: number,
  cardState: CardState,
}

export enum CardState {
  HIDING,
  SHOWING,
  MATCHED,
}