import React, { FC, useEffect } from "react"
import {Box, Chip, TableBody, TableHead, Typography} from "@material-ui/core"
import {USERS_LIST_QUERY} from "./UsersList.query"
import useRelayConnection from "../../../hooks/useRelayConnection"
import {UserItemNode} from "../../../store/user/user.types"
import {usePaginationParams} from "../../../hooks/useQueryParams"
import TableWithPagination from "../../common/tableWithPagination/TableWithPagination.component"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import UsersListActions from "../usersListActions/UsersListActions.component"
import {useUsersListStyles} from "./UsersList.styles"
import AddUserPopup from "../addUserPopup/AddUser.component"
import {getUserRoleChipColor, getUserRoleLabel} from "../userRole/UserRole.utils"
import {UserRole} from "../userRole/UserRole.types";
import CreateFormButtons from "../../entryExitFormResult/createFormButtons/CreateFormButtons.component";
import {useSelector} from "react-redux";
import {selectUserRole} from "../../../store/user/user.selectors";
import TableCellWithFilter from "../../common/tableCellWithFilter/TableCellWithFilter.component"
import { StringParam, useQueryParam } from "use-query-params"
import { FilterSelectType } from "../../common/tableCellWithFilter/TableCellWithFilter.types"

interface UsersListProps {
  isOpenAddUserPopup: boolean;
  onCloseAddUserPopup(): void;
}

const UsersList: FC<UsersListProps> = ({isOpenAddUserPopup, onCloseAddUserPopup}) => {
  const classes = useUsersListStyles()
  const currentUserRole = useSelector(selectUserRole)

  const [fullNameFilter, setFullNameFilter] = useQueryParam("fullName", StringParam)
  const [tenantIdFilter, setTenantIdFilter] = useQueryParam("tenantId", StringParam)

  const filters = (fullNameFilter || tenantIdFilter)
    ? {
      fullName: fullNameFilter ? { likeInsensitive: fullNameFilter } : undefined,
      tenantId: tenantIdFilter ? { equalTo: tenantIdFilter } : undefined,
    }
    : undefined

  const {tableProps, refetch, loading} = useRelayConnection<UserItemNode>(USERS_LIST_QUERY, {
    variables: {
      filter: filters,
      orderBy: ['REGISTER_AT_DESC']
    },
    pagination: usePaginationParams()
  })

  useEffect(() => {
    refetch()
  }, [fullNameFilter])

  return (
    <Box>
      <AddUserPopup
        open={isOpenAddUserPopup}
        refetch={refetch}
        onClose={onCloseAddUserPopup}
      />

      <TableWithPagination {...tableProps}>
        <TableHead>
          <TableRow>
            <TableCellWithFilter
              defaultValue={fullNameFilter}
              label="Imię i nazwisko"
              disabled={loading}
              onFilterSubmit={(val) => setFullNameFilter(val)}
            >
              <Typography className={classes.fieldName}>Imię i nazwisko</Typography>
            </TableCellWithFilter>
            <TableCell>
              <Typography className={classes.fieldName}>E-mail</Typography>
            </TableCell>

            <TableCell>
              <Typography className={classes.fieldName}>Rola</Typography>
            </TableCell>

            { currentUserRole === UserRole.LEADER &&
              <TableCell>
                <Typography className={classes.fieldName}>Ostatnia aktywność</Typography>
              </TableCell>
            }

            {currentUserRole === UserRole.ADMIN &&
              <TableCellWithFilter
                defaultValue={tenantIdFilter}
                label="Podmiot"
                disabled={loading}
                select={FilterSelectType.TENANTS}
                onFilterSubmit={(val) => setTenantIdFilter(val)}
              >
                <Typography className={classes.fieldName}>Podmiot</Typography>
              </TableCellWithFilter>
            }
            <TableCell>
              <Typography className={classes.fieldName} align={currentUserRole === UserRole.ADMIN ? "right" : 'left'}>Akcje</Typography>
            </TableCell>
            {currentUserRole === UserRole.LEADER &&
              <TableCell align="right">
                <Typography className={classes.fieldName}>Formularze</Typography>
              </TableCell>
            }
          </TableRow>
        </TableHead>

        <TableBody>
          <>
            { tableProps.dataSource.map((dataItem: UserItemNode) => (
              <TableRow key={dataItem.email}>
                <TableCell>
                  {dataItem.firstName} {dataItem.lastName}
                </TableCell>

                <TableCell>
                  {dataItem.email}
                </TableCell>

                <TableCell>
                  <Chip
                    label={getUserRoleLabel(dataItem.role)}
                    color={getUserRoleChipColor(dataItem.role)}
                  />
                </TableCell>

                { currentUserRole === UserRole.LEADER &&
                  <TableCell>
                    { dataItem.lastActivity
                      ? dataItem.lastActivity.split('T')[0].split('-').reverse().join('.')
                      : (dataItem.role === UserRole.TESTED_PERSON ? '—' : '')
                    }
                  </TableCell>
                }

                {currentUserRole === UserRole.ADMIN &&
                  <TableCell>
                    {dataItem.tenant?.name}
                  </TableCell>
                }

                <TableCell align={currentUserRole === UserRole.ADMIN ? "right" : 'left'}>
                  <UsersListActions
                    user={dataItem}
                    refetch={refetch}
                  />
                </TableCell>

                <TableCell align="right">
                  {dataItem.role === UserRole.TESTED_PERSON
                    && <CreateFormButtons userId={dataItem.userId}/>
                  }
                </TableCell>

              </TableRow>
            ))}
          </>
        </TableBody>
      </TableWithPagination>
    </Box>
  )
}

export default UsersList
