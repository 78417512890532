import {Button, Box} from "@material-ui/core";
import React, {FC} from "react";
import {useHistory} from "react-router";
import {useSubmitFormButtonsStyles} from "./SubmitFormButton.styles";
import {RoutePath} from "../../../routes/Routes.types";
import DownloadingButtonIcon from "../../common/downlodingButtonIcon/DownloadingButtonIcon.component"

interface SubmitFormButtonsProps {
  isEditMode: boolean
  loading?: boolean;
}

const SubmitFormButtons: FC<SubmitFormButtonsProps> = ({isEditMode, loading}) => {
  const classes = useSubmitFormButtonsStyles()
  const {push} = useHistory()

  return (
    <Box className={classes.root}>
      <Button
        variant='outlined'
        color='primary'
        disabled={loading}
        onClick={() => push(RoutePath.USERS)}
      >
        Wróć
      </Button>
      <Button
        variant='contained'
        color='primary'
        type='submit'
        disabled={loading}
        startIcon={loading ? <DownloadingButtonIcon/> : null}
        className={classes.saveButton}
      >
        {isEditMode ? "Edytuj" : "Zapisz"}
      </Button>
    </Box>
  )
}

export default SubmitFormButtons