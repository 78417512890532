import {makeStyles} from "@material-ui/core"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useDragAndDropStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  },
}))
