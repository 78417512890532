import React, { FC } from "react"
import { List, ListItem, ListItemText, ListItemIcon, SvgIcon } from "@material-ui/core"
import { SvgIconComponent } from "@material-ui/icons"
import { useDispatch, useSelector } from "react-redux"
import { selectUserRole } from "../../../store/user/user.selectors"
import { UserRole } from "../../user/userRole/UserRole.types"
import adminRoutes from "../../../routes/routesList/Admin.routes"
import { RouteListItem } from "../../../routes/routesList/RouteList.types"
import { RoutePath } from "../../../routes/Routes.types"
import { useHistory } from "react-router"
import { refreshSession } from "../../../store/session/session.slice"
import { NavLink } from "react-router-dom"

const MenuItems: FC = () => {
  const userRole = useSelector(selectUserRole)
  const {push} = useHistory()
  const dispatch = useDispatch()

  const handleMenuItemClick = (e: React.MouseEvent<HTMLAnchorElement>, path: RoutePath) => {
    e.preventDefault()
    dispatch(refreshSession())
    push(path)
  }

  const navItemContent = (icon: SvgIconComponent | undefined, text: string) => (
    <>
      <ListItemIcon>
        { icon && <SvgIcon component={icon}/> }
      </ListItemIcon>

      <ListItemText
        primary={text}
        primaryTypographyProps={{variant: "button", component: "span"}}
      />
    </>
  )

  return (
    <List component="nav">
      { adminRoutes
        .filter((menuItem: RouteListItem) => menuItem.permission.includes(userRole as UserRole))
        .filter((menuItem: RouteListItem) => !!menuItem.icon)
        .map((menuItem: RouteListItem) => (
          <ListItem
            key={menuItem.title}
            button
            component={NavLink}
            to={menuItem.path}
            exact
            activeClassName="Mui-selected"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleMenuItemClick(e, menuItem.path)}
          >
            { navItemContent(menuItem.icon, menuItem.title) }
          </ListItem>
        ))
      }
    </List>
  )
}

export default MenuItems
