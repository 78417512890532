import * as yup from "yup"
import {
  min1CapitalLetterErrorMessage, min1SmallLetterErrorMessage,
  min8CharsErrorMessage, minOneSpecialCharacterErrorMessage,
  requiredErrorMessage,
  wrongRepeatPassword
} from "../../../components/formItems/errors/yupErrorMessages"

export const resetPasswordValidationSchema = yup.object().shape({
  password: yup.string()
    .required(requiredErrorMessage)
    .min(8, min8CharsErrorMessage)
    .matches(/[A-Z]+/, min1CapitalLetterErrorMessage)
    .matches(/[a-z]+/, min1SmallLetterErrorMessage)
    .matches(/[~!@#$%^&*()\-_=+{}[\]|\\:;'"<,>./?€£¥₹]+/, minOneSpecialCharacterErrorMessage),

  passwordRepeat: yup.string()
    .required(requiredErrorMessage)
    .test("password match", wrongRepeatPassword, (passwordRepeat, testContext) => {
      return passwordRepeat === testContext.parent.password
    }),
})