export enum GAME_TYPE {
  MEMORY = 'MEMORY',
  DIFFERENCES = 'DIFFERENCES',
  DICES = 'DICES',
  BOX3D = 'BOX3D',
  BUBBLES = 'BUBBLES',
}

export type GameNode = {
  game: GAME_TYPE;
  level: number;
  size: number;
  target: number;
  maxAttempts: number | null;
  timeLimit: number;
  games: number,
  lastGame: string,
  unlocked: boolean,
  completed: string | null,
  gameDefinitionByGame: {
    name: string
  }
}

export type GameListItemType = {
  type: GAME_TYPE,
  levels: GameNode[]
}