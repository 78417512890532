import { gql } from "@apollo/client"

export const USERS_LIST_QUERY = gql`
    query GetUsersList(
        $first: Int,
        $offset: Int,
        $filter: UserFilter,
        $orderBy: [UsersOrderBy!]
    ) {
        connection: users(first: $first, offset: $offset, filter: $filter, orderBy: $orderBy) {
            totalCount
            edges {
                node {
                    userId
                    email
                    firstName
                    lastName
                    role
                    archived
                    lastActivity
                    tenant {
                        tenantId
                        name
                    }
                }
            }
        }
    }
`;