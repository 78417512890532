import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SessionState } from "./session.types"
import {Reducer} from "redux"

const initialState: SessionState = {}

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    initSession: (state: SessionState, action: PayloadAction<Partial<SessionState>>) => {
      return { ...state, ...action.payload }
    },
    setSession: (state: SessionState, action: PayloadAction<Partial<SessionState>>) => {
      return { ...state, ...action.payload }
    },
    refreshSession: () => {
      return
    },
    clearSession: () => {
      return { ...initialState }
    },
  },
})

export const {
  initSession,
  setSession,
  clearSession,
  refreshSession,
} = sessionSlice.actions

export default sessionSlice.reducer as Reducer<typeof initialState>
