import {alpha, makeStyles} from "@material-ui/core"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useLoaderBoxStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    zIndex: theme.zIndex.tooltip,
    padding: theme.spacing(8),
  },
  fullPage: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "100vh",
  },
}))
