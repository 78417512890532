import React, {ComponentType, FC} from "react"
import {Redirect, Route, RouteProps} from "react-router-dom"
import {RoutePath} from "./Routes.types"
import { UserRole } from "../components/user/userRole/UserRole.types"
import { useAppSelector } from "../hooks/storeHooks"
import { selectAuthenticated } from "../store/session/session.selectors"
import { selectUserRole } from "../store/user/user.selectors"

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line
  component: ComponentType<any>;
  routePermission: UserRole[];
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  routePermission,
  ...rest
}) => {
  const authenticated = useAppSelector(selectAuthenticated)
  const userRole = useAppSelector(selectUserRole)

  return (
    <Route
      {...rest}
      render={(props) => {
        let redirectLink = "/login"
        let permission = true
        const {location} = rest

        if (!authenticated) {
          // add ref for redirect to ref page after login
          if (location) {
            redirectLink += `/${encodeURIComponent(location.pathname)}`
          }
        } else {
          if (userRole) {
            if (!routePermission.includes(userRole)) {
              permission = false
              redirectLink = RoutePath.ERROR_403
            }
          }
        }

        return (authenticated && permission)
          ? <Component {...props} />
          : <Redirect to={redirectLink}/>
      }}
    />
  )
}

export default PrivateRoute
