export const mixins = {
  toolbar: {
    minHeight: 88,
    "@media(min - width: 0px)and(orientation : landscape )": {
      minHeight: 88
    },
    "@media(min - width: 600 px)": {
      minHeight: 88,
    },
  },
}
