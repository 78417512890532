import React, {FC} from "react"
import BaseLayout from "../layout/BaseLayout"

interface WithBaseLayoutProps {}

const withBaseLayout = <T extends WithBaseLayoutProps>(WrappedComponent: FC<T & WithBaseLayoutProps>): FC<T> => (props: T) => {
  return (
    <BaseLayout>
      <WrappedComponent {...props} />
    </BaseLayout>
  )
}

export default withBaseLayout
