import {gql} from "@apollo/client";

export const GAMES_QUERY = gql`
  query GetGames($id: UUID!) {
    games: gameLevels {
      totalCount
      edges {
        node {
          game
          games(userId: $id)
          level
          unlocked (userId: $id)
          lastGame (userId: $id)
          completed (userId: $id)
          gameDefinitionByGame {
            name
          }
        }
      }
    }
  }
`;
