import { Box } from "@material-ui/core"
import React, { FC } from "react"
import clsx from "clsx"
import { CardState, CardType } from "../MemoryGame.types"
import GameItem from "../../gameItem/GameItem"
import { useMemoryGameCardStyles } from "./MemoryGameCard.styles"

interface MemoryGameCardProps {
  level: number;
  card: CardType;
  disabled: boolean;
  onClick?(): void;
}

const MemoryGameCard: FC<MemoryGameCardProps> = ({level,card, disabled, onClick}) => {
  const CARD_WIDTH = 136 -( 6 * level)
  const classes = useMemoryGameCardStyles({size: CARD_WIDTH})

  const isReversed = card.cardState === CardState.HIDING
  return (
    <button
      className={clsx(
        classes.flipperWrap,
        (isReversed && !disabled) && classes.flipperWrapReversed
      )}
      onClick={onClick}
    >
      <Box className={clsx(classes.flipper, isReversed && classes.flipperReversed)}>

        <Box className={clsx(classes.card, classes.cardFront)}>
          <GameItem
            size={CARD_WIDTH}
            assetsNumber={1}
            type={card.pairId}
          />
        </Box>

        <Box className={clsx(classes.card, classes.cardBack)}/>

      </Box>
    </button>
  )
}

export default MemoryGameCard
