import React, { FC } from "react"
import { Box } from "@material-ui/core"
import AdminPageCard from "../components/adminPageCard/AdminPageCard.component"
import Settings from "../components/settings/Settings.component"

const SettingsPage: FC = () => {
  return (
    <AdminPageCard title="Ustawienia">
      <Box>
        <Settings/>
      </Box>
    </AdminPageCard>
  )
}

export default SettingsPage
