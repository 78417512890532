import {makeStyles, createStyles, Theme} from "@material-ui/core";

export const useSubmitFormButtonsStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    margin: theme.spacing(1, "auto",5,),
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: "810px"
  },
  saveButton: {
    marginLeft: theme.spacing(3)
  }
}))