import React, { FC, useState } from "react"
import { Button } from "@material-ui/core"
import { useTenantsListActionsStyles } from "./TenantsListActions.styles"
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import EditTenantPopup from "../editTenantPopup/EditTenant.component"
import { TenantItemNode } from "../tenantsList/TenantsList.types"
import PreviewTenantPopup from "../previewTenantPopup/PreviewTenant.component"
import ArchiveTenantPopup from "../archiveTenantPopup/ArchiveTenant.component"

interface TenantsListActionsProps {
  tenant: TenantItemNode;
  refetch(): void;
}

const TenantsListActions: FC<TenantsListActionsProps> = ({ tenant, refetch }) => {
  const classes = useTenantsListActionsStyles()

  const [isOpenEditTenantPopup, setIsOpenEditTenantPopup] = useState<boolean>(false)
  const [isOpenPreviewTenantPopup, setIsOpenPreviewTenantPopup] = useState<boolean>(false)
  const [isOpenArchiveTenantPopup, setIsOpenArchiveTenantPopup] = useState<boolean>(false)

  return (
    <>
      <Button
        size={"small"}
        variant={"outlined"}
        color={"secondary"}
        className={classes.button}
        onClick={() => setIsOpenEditTenantPopup(true)}
      >
        <EditIcon className={classes.icon}/>
        Edytuj
      </Button>

      <Button
        size={"small"}
        variant={"outlined"}
        color={"secondary"}
        className={classes.button}
        onClick={() => setIsOpenPreviewTenantPopup(true)}
      >
        <VisibilityIcon className={classes.icon}/>
        Podgląd
      </Button>

      <Button
        size={"small"}
        variant={"outlined"}
        color={"secondary"}
        className={classes.button}
        onClick={() => setIsOpenArchiveTenantPopup(true)}
      >
        { tenant.archived ? <UnarchiveIcon className={classes.icon}/> : <ArchiveIcon className={classes.icon}/> }
        { tenant.archived ? "Aktywuj" : "Archiwizuj" }
      </Button>

      <EditTenantPopup
        tenantId={tenant.tenantId}
        name={tenant.name}
        open={isOpenEditTenantPopup}
        refetch={refetch}
        onClose={() => setIsOpenEditTenantPopup(false)}
      />

      <PreviewTenantPopup
        tenant={tenant}
        open={isOpenPreviewTenantPopup}
        onClose={() => setIsOpenPreviewTenantPopup(false)}
      />

      <ArchiveTenantPopup
        tenantId={tenant.tenantId}
        name={tenant.name}
        open={isOpenArchiveTenantPopup}
        refetch={refetch}
        onClose={() => setIsOpenArchiveTenantPopup(false)}
      />
    </>
  )
}

export default TenantsListActions
