import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import rootSaga from "./rootSaga"
import { routerMiddleware } from "connected-react-router"
import {createBrowserHistory} from "history"
import createRootReducer from "./rootReducer"

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
  }),
  routerMiddleware(history),
  sagaMiddleware,
]

const store = configureStore({
  reducer: createRootReducer(history),
  middleware,
})

sagaMiddleware.run(rootSaga)

export default store
