import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLayoutBackground } from "../store/layout/layout.slice";

export const useBackgroundImage = (src?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLayoutBackground(src));

    return src ? () => {
      dispatch(setLayoutBackground(undefined))
    } : undefined;
  }, [src, dispatch]);
}
