import {makeStyles, createStyles, Theme} from "@material-ui/core";

export const useQuestionsCardStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: theme.spacing(4,'auto'),
    boxShadow: theme.shadows[8],
    maxWidth: "810px"
  },
  contentWrapper: {
    color: theme.palette.common.black,
    display: "flex",
    alignItems: "center"
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  image: {
    maxWidth: "100%"
  },
  textInput: {
    width: '80px',
    margin: theme.spacing(0,2)
  }
}))