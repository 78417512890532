import React, {FC} from "react"
import { Box, Typography, Avatar, Button } from "@material-ui/core"
import {useAuthPageStyles} from "./AuthPageCard.styles"
import LockOutlinedIcon from "@material-ui/icons/Lock"
import { ArrowBack } from "@material-ui/icons"
import { useHistory } from "react-router"
import UELogos from '../../assets/images/ue_logo.jpg'

interface AuthPageCardProps {
  title: string;
  showGoBackButton?: boolean;
}

const AuthPageCard: FC<AuthPageCardProps> = (
  {
    title,
    showGoBackButton,
    children
  }
) => {
  const classes = useAuthPageStyles()
  const {push} = useHistory()

  return (
    <Box className={classes.pageBox}>
      <img src={UELogos} alt="" className={classes.logos}/>
      <Box className={classes.contentWrapper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography variant={"h2"} className={classes.title}>
          { title }
        </Typography>

        <Box>
          { children }
        </Box>

        { showGoBackButton && (
          <Button
            className={classes.goBackButton}
            onClick={() => push("/login")}
          >
            <ArrowBack className={classes.goBackButtonIcon}/>
            <Typography variant={"button"}>
              Wróć na stronę logowania
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default AuthPageCard
