import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useSummaryScoreStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: "810px",
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(4,"auto"),
    padding: theme.spacing(3)
  },
  title: {
    padding: theme.spacing(0,2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  contentWrapper: {
    margin: theme.spacing(0,3),
    maxWidth: "300px",
  },
  row: {
    display: "flex",
    padding: theme.spacing(1,0),
    justifyContent: "space-between"
  },
  category: {
    marginRight: theme.spacing(2)
  },
  pointsWrapper: {
    display: "flex",
    flexDirection: "row"
  }
}))