import { GAME_TYPE } from "./Game.types"

export const getGameLabel = (value: GAME_TYPE): string => {
  switch (value) {
    case GAME_TYPE.MEMORY: return 'Memory';
    case GAME_TYPE.DIFFERENCES: return 'Znajdź różnice';
    case GAME_TYPE.DICES: return 'Kości';
    case GAME_TYPE.BOX3D: return 'Figury przestrzenne';
    case GAME_TYPE.BUBBLES: return 'Kulki';
    default: return value
  }
}