import {gql} from '@apollo/client'

export const FORM_RESULT_QUERY = gql`
  query FormResult ($userId: UUID!){
    user(userId: $userId){
      examResult(type: ENTRY)
      examResultsList {
        percentage
        variable
      }
    }
  }
`