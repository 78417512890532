import { makeStyles, createStyles, Theme, alpha } from "@material-ui/core";

export const useUserGamesListStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    paddingBottom: theme.spacing(.5),
    marginBottom: theme.spacing(5),
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2,)
  },
  defaultTheme: {
    color: theme.palette.common.white,
  },
  highContrastTheme: {
    color: theme.palette.common.black,
  },
  gameRow: {
    margin: theme.spacing(4,2),
    borderRadius: theme.shape.borderRadius,
    paddingBottom: theme.spacing(2, 0)
  },
  gameTitle: {
    padding: theme.spacing(1),
  },
  gamesSection: {
    display: "flex",
    gap: theme.spacing(2),
  }
}))