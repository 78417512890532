import {makeStyles} from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"

export const useDifferenceGameStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    display: "flex",
    gap: theme.spacing(3),
    alignItems: "flex-start",
  },
  imageWrapper: {
    flexBasis: "50%",
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingBottom: theme.spacing(0),
  },
  imageWrapperPlaceholder: {
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.primary.light,
    }
  },
  image: {
    display: "block",
  },
  boxTitle: {
    margin: theme.spacing(2),
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: ".8px",
  }
}))
