import {makeStyles} from "@material-ui/core"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useSessionManagerPopupStyles = makeStyles((theme: Theme) => createStyles({
  timeLeft: {
    display: "inline-block",
    minWidth: theme.spacing(4),
    fontWeight: "bold",
  },
  mb: {
    marginBottom: theme.spacing(1),
  }
}))
