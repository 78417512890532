import { makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"

export const useEndGameStyles = makeStyles((theme: Theme) => createStyles({
  summary: {
  },
  resultTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  resultDescription: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
}))
