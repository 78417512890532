import { RefObject } from "react"

export const scrollToRefElement = (
  elementRef: RefObject<HTMLElement> | null,
  offset = 0,
): void => {
  if (elementRef?.current) {
    const y = elementRef.current.getBoundingClientRect().top + window.pageYOffset + offset
    window.scrollTo({top: y, behavior: "smooth"})
  }
}

export const scrollToElementId = (
  id: string,
  offset = 0,
): void => {
  const element = document.getElementById(id)
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset + offset
    window.scrollTo({top: y, behavior: "smooth"})
  }
}

export const scrollToTop = (): void => {
  window.scrollTo({
    top: -window.pageYOffset,
    behavior: "smooth",
  })
}

export const scrollToBottom = (): void => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: "smooth",
  })
}