import React, { FC } from "react"
import { useCubeItemStyles } from "./CubeItem.styles"
import { useDrag } from "react-dnd"
import clsx from "clsx"
import { DicesTemplateCube, DragItemType, DragObject } from "../../DicesGame.types"
import { DroppableId } from "../../DicesGame.types"
import getColor from "../../DicesGame.utils"

interface CubeItemProps extends DicesTemplateCube {
  droppableId: DroppableId;
  x: number,
  y: number,
}

const CubeItem: FC<CubeItemProps> = (
  {
    id,
    top,
    right,
    bottom,
    left,
    droppableId,
    x,
    y,
  }
) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DragItemType.CUBE,
    item: {
      id,
      type: DragItemType.CUBE,
      sourceDroppableId: droppableId,
      x,
      y,
    } as DragObject,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  }))

  const classes = useCubeItemStyles({
    top: getColor[top],
    right: getColor[right],
    bottom: getColor[bottom],
    left: getColor[left],
  })

  return (
    <div
      ref={drag}
      className={clsx(
        isDragging && classes.dragging
      )}
    >
      <div className={classes.cube} />
    </div>
  )
}

export default CubeItem
