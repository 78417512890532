import { makeStyles } from "@material-ui/core"
import { createStyles } from "@material-ui/core/styles"
import { Theme as DefaultTheme } from "@material-ui/core/styles/createTheme";
import { CUBE_WIDTH } from "../../DicesGame.component"

interface CubeItemStylesProps {
  top: string;
  right: string;
  bottom: string;
  left: string;
}

export const useCubeItemStyles = makeStyles<DefaultTheme, CubeItemStylesProps>(() => createStyles({
  dragging: {
    display: "none"
  },
  cube: {
    width: `${CUBE_WIDTH}px`,
    height: `${CUBE_WIDTH}px`,
    border: `${CUBE_WIDTH}px solid transparent`,
    borderTopColor: props => props.top,
    borderRightColor: props => props.right,
    borderLeftColor: props => props.left,
    borderBottomColor: props => props.bottom,
    cursor: 'grab'
  },
}))
