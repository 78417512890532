import React, {FC} from "react"
import TestedPersonLayout from "../layout/TestedPersonLayout"

interface WithTestedPersonLayoutProps {}

const withTestedPersonLayout = <T extends WithTestedPersonLayoutProps>(
  WrappedComponent: FC<T & WithTestedPersonLayoutProps>,
): FC<T> => (props: T) => {
  return (
    <TestedPersonLayout>
      <WrappedComponent {...props} />
    </TestedPersonLayout>
  )
}

export default withTestedPersonLayout
