import React, { FC, useState } from "react"
import DropWrapper from "./dropWrapper/DropWrapper.component"
import { useDragAndDropStyles } from "./DragAndDrop.styles"
import { DicesTemplate, DraggableBoardType, DroppableId } from "../DicesGame.types"
import chunk from "../../../../utils/chunk"

interface DragAndDropProps {
  template: DicesTemplate
  onChange(resultsBoard: DraggableBoardType): void
}

const DragAndDrop: FC<DragAndDropProps> = (
  {
    template,
    onChange,
  }
) => {
  const classes = useDragAndDropStyles()
  const answerWasSaved = !!(template.answer?.length && template.answer[0].length)

  const [items, setItems] = useState<DraggableBoardType>(answerWasSaved
    ? chunk(template.items, template.height).map(templateCol => templateCol.map(dice => {
      return ([] as Array<number | null>).concat(...template.answer).includes(dice.id) ? null : dice
    }))
    : chunk(template.items, template.height)
  )

  const [resultItems, setResultItems] = useState<DraggableBoardType>(answerWasSaved
    ? template.answer.map((itemCol: (number | null)[]) => itemCol.map((diceId: number | null) => {
      return diceId
        ? template.items.find(templateItem => templateItem.id === diceId) || null
        : null
    }))
    : new Array(template.width).fill(0).map(() => new Array(template.height).fill(null))
  )

  const getList = (id: DroppableId) => id === DroppableId.INIT_COL
    ? items
    : resultItems

  const onDragEnd = (
    cubeId: number,
    sourceId: DroppableId,
    destinationId: DroppableId,
    srcX: number,
    srcY: number,
    destX: number,
    destY: number,
  ) => {
    const sourceClone = Array.from(getList(sourceId))
    const destClone = Array.from(getList(destinationId))

    const movedItem = sourceClone[srcX][srcY]

    const newSourceItemsArray = sourceClone.map((col, rowIdx) => {
      return rowIdx === srcX
        ? col.map((item, colIdx) => colIdx === srcY ? null : item)
        : col
    })

    let newDestItemsArray
    if (sourceId === destinationId) {
      newDestItemsArray = newSourceItemsArray.map((col, rowIdx) => {
        return rowIdx === destX
          ? col.map((item, colIdx) => colIdx === destY ? movedItem : item)
          : col
      })
    } else { // sourceId !== destinationId
      newDestItemsArray = destClone.map((col, rowIdx) => {
        return rowIdx === destX
          ? col.map((item, colIdx) => colIdx === destY ? movedItem : item)
          : col
      })
    }

    sourceId === DroppableId.RESULT_COL ? setResultItems(newSourceItemsArray) : setItems(newSourceItemsArray)
    destinationId === DroppableId.RESULT_COL ? setResultItems(newDestItemsArray) : setItems(newDestItemsArray)
    onChange(destinationId === DroppableId.RESULT_COL
      ? newDestItemsArray
      : sourceId === DroppableId.RESULT_COL ? newSourceItemsArray : resultItems
    )
  }

  return (
    <div className={classes.wrapper}>
      <DropWrapper
        droppableId={DroppableId.RESULT_COL}
        border={template.border}
        items={resultItems}
        updateBoardCell={onDragEnd}
      />

      <DropWrapper
        droppableId={DroppableId.INIT_COL}
        items={items}
        updateBoardCell={onDragEnd}
      />
    </div>
  )
}

export default DragAndDrop
