import * as yup from "yup"
import { UserRole } from "../userRole/UserRole.types"
import { emailErrorMessage, requiredErrorMessage, } from "../../formItems/errors/yupErrorMessages"

export const addUserValidationSchema = yup.object().shape({
  firstName: yup.string().required(requiredErrorMessage),
  lastName: yup.string().required(requiredErrorMessage),
  email: yup.string()
    .required(requiredErrorMessage)
    .email(emailErrorMessage),
  role: yup.mixed()
    .required(requiredErrorMessage)
    .oneOf(Object.values(UserRole), requiredErrorMessage),
  tenantId: yup.string()
    .when("role", {
      is: UserRole.LEADER || UserRole.TESTED_PERSON,
      then: yup.string().required(requiredErrorMessage),
    })
})