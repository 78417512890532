import {UserRole} from "./UserRole.types"
import {RoutePath} from "../../../routes/Routes.types"

const getHomeRouteForRole = (role: UserRole) => {
  switch(role) {
    case UserRole.TESTED_PERSON: return RoutePath.HOMEPAGE;
    case UserRole.LEADER: return RoutePath.USERS;
    case UserRole.ADMIN: return RoutePath.TENANTS
    default: return RoutePath.ADMIN_HOMEPAGE
  }
}

const getUserRoleLabel = (role?: UserRole) => {
  switch(role) {
    case UserRole.ADMIN: return "Administrator";
    case UserRole.LEADER: return "Osoba prowadząca";
    case UserRole.TESTED_PERSON: return "Osoba badana";
    default: return role
  }
}

const getUserRoleChipColor = (role?: UserRole) => {
  switch(role) {
    case UserRole.ADMIN: return "primary";
    case UserRole.LEADER: return "secondary";
    case UserRole.TESTED_PERSON: return "primary";
    default: return "default"
  }
}

export {
  getHomeRouteForRole,
  getUserRoleLabel,
  getUserRoleChipColor,
}