import { QueryParamConfig } from "serialize-query-params/lib/types"
import { NumberParam, useQueryParams, withDefault } from "use-query-params"

export const usePaginationParams = () => useQueryParams({
  page: withDefault(NumberParam, 1),
  rowsPerPage: withDefault(NumberParam, 10),
})

export const withEnum = <T>(
  config: QueryParamConfig<T | undefined>,
  values: T[]
): QueryParamConfig<T | undefined> => ({
  ...config,
  decode: (raw) => {
    const value = config.decode(raw)
    return values.includes(value as T) ? value : undefined
  },
})
