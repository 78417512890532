import {QuestionsType} from "./QuestionsCard.component";

import img1 from '../../../assets/images/questionsCardImages/sekcja1.jpg'
import img2 from '../../../assets/images/questionsCardImages/sekcja2.jpg'
import img3 from '../../../assets/images/questionsCardImages/sekcja3.jpg'
import img4 from '../../../assets/images/questionsCardImages/sekcja4.jpg'
import img5 from '../../../assets/images/questionsCardImages/sekcja5.jpg'
import img6 from '../../../assets/images/questionsCardImages/sekcja6.jpg'
import img7 from '../../../assets/images/questionsCardImages/sekcja7.jpg'
import img8 from '../../../assets/images/questionsCardImages/sekcja8.jpg'
import img9 from '../../../assets/images/questionsCardImages/sekcja9.jpg'
import img10 from '../../../assets/images/questionsCardImages/sekcja10.jpg'
import img11 from '../../../assets/images/questionsCardImages/sekcja11.jpg'
import img12 from '../../../assets/images/questionsCardImages/sekcja12.jpg'
import img13 from '../../../assets/images/questionsCardImages/sekcja13.jpg'
import img14 from '../../../assets/images/questionsCardImages/sekcja14.jpg'
import img15 from '../../../assets/images/questionsCardImages/sekcja15.jpg'
import img16 from '../../../assets/images/questionsCardImages/sekcja16.jpg'
import img17 from '../../../assets/images/questionsCardImages/sekcja17.jpg'
import img18 from '../../../assets/images/questionsCardImages/sekcja18.jpg'
import img19 from '../../../assets/images/questionsCardImages/sekcja19.jpg'
import img20 from '../../../assets/images/questionsCardImages/sekcja20.jpg'
import img21 from '../../../assets/images/questionsCardImages/sekcja21.jpg'
import img22 from '../../../assets/images/questionsCardImages/sekcja22.jpg'
import img23 from '../../../assets/images/questionsCardImages/sekcja23.jpg'
import img24 from '../../../assets/images/questionsCardImages/sekcja24.jpg'

export type QuestionsDataItem = {
  image: string
  questionType: QuestionsType;
  maxPoints: number;
}

export const questionsData: QuestionsDataItem[] = [
  {
    image: img1,
    questionType: QuestionsType.ATTENTION,
    maxPoints: 5
  },
  {
    image: img2,
    questionType: QuestionsType.ATTENTION,
    maxPoints: 5
  },
  {
    image: img3,
    questionType: QuestionsType.ATTENTION,
    maxPoints: 3
  },
  {
    image: img4,
    questionType: QuestionsType.ATTENTION,
    maxPoints: 5
  },
  {
    image: img5,
    questionType: QuestionsType.MEMORY,
    maxPoints: 3
  },
  {
    image: img6,
    questionType: QuestionsType.FLUENCY,
    maxPoints: 7
  },
  {
    image: img7,
    questionType: QuestionsType.FLUENCY,
    maxPoints: 7
  },
  {
    image: img8,
    questionType: QuestionsType.MEMORY,
    maxPoints: 7
  },
  {
    image: img9,
    questionType: QuestionsType.MEMORY,
    maxPoints: 4
  },
  {
    image: img10,
    questionType: QuestionsType.LANGUAGE,
    maxPoints: 3
  },
  {
    image: img11,
    questionType: QuestionsType.LANGUAGE,
    maxPoints: 2
  },
  {
    image: img12,
    questionType: QuestionsType.LANGUAGE,
    maxPoints: 2
  },
  {
    image: img13,
    questionType: QuestionsType.LANGUAGE,
    maxPoints: 1
  },
  {
    image: img14,
    questionType: QuestionsType.LANGUAGE,
    maxPoints: 1
  },
  {
    image: img15,
    questionType: QuestionsType.LANGUAGE,
    maxPoints: 12
  },
  {
    image: img16,
    questionType: QuestionsType.LANGUAGE,
    maxPoints: 4
  },
  {
    image: img17,
    questionType: QuestionsType.LANGUAGE,
    maxPoints: 1
  },
  {
    image: img18,
    questionType: QuestionsType.VISUOSPATIAL_ABILITIES,
    maxPoints: 1
  },
  {
    image: img19,
    questionType: QuestionsType.VISUOSPATIAL_ABILITIES,
    maxPoints: 2
  },
  {
    image: img20,
    questionType: QuestionsType.VISUOSPATIAL_ABILITIES,
    maxPoints: 5
  },
  {
    image: img21,
    questionType: QuestionsType.VISUOSPATIAL_ABILITIES,
    maxPoints: 4
  },
  {
    image: img22,
    questionType: QuestionsType.VISUOSPATIAL_ABILITIES,
    maxPoints: 4
  },
  {
    image: img23,
    questionType: QuestionsType.MEMORY,
    maxPoints: 7
  },
  {
    image: img24,
    questionType: QuestionsType.MEMORY,
    maxPoints: 5
  }]