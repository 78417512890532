export const themeKey = "theme";

export enum ActionType {
  SWITCH_TYPOGRAPHY = "SWITCH_TYPOGRAPHY",
  SWITCH_PALETTE = "SWITCH_PALETTE"
}

export enum PaletteEnum {
  HIGH_CONTRAST = "HIGH_CONTRAST",
  DEFAULT = "DEFAULT",
}

export enum TypographyEnum {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  BIG = "BIG",
}

export type CustomThemeActionTypes = 
  { type: ActionType.SWITCH_TYPOGRAPHY, payload: TypographyEnum }
  | { type: ActionType.SWITCH_PALETTE, payload: PaletteEnum }

export type CustomTheme = {
  palette?: PaletteEnum;
  typography?: TypographyEnum;
};