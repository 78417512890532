import * as React from 'react';
import Routes from './routes/Routes';

const App = () => {
  return (
    <Routes />
  )
};

export default App;
