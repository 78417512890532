import React, { FC } from "react"
import { useEmptyCellStyles } from "./EmptyCell.styles"
import { useDrop } from "react-dnd"
import clsx from "clsx"
import { DragItemType, DragObject, DroppableId } from "../../DicesGame.types"

interface EmptyCellProps {
  updateBoardCell(itemId: number, sourceId: DroppableId, x: number, y: number): void;
}

const EmptyCell: FC<EmptyCellProps> = ({updateBoardCell}) => {
  const classes = useEmptyCellStyles()

  const [{ isOver }, dropRef] = useDrop({
    accept: DragItemType.CUBE,
    drop: (item: DragObject) => {
      updateBoardCell(item.id, item.sourceDroppableId, item.x, item.y)
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  })

  return (
    <div
      ref={dropRef}
      className={clsx(
        classes.emptyBox,
        isOver && classes.isOver,
      )}
    />
  )
}

export default EmptyCell
