import { makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"
import { Theme as DefaultTheme } from "@material-ui/core/styles/createTheme";
import { CUBE_WIDTH } from "../../DicesGame.component"

export const useEmptyCellStyles = makeStyles<DefaultTheme>((theme: Theme) => createStyles({
  emptyBox: {
    border: `1px dashed ${theme.palette.common.black}`,
    width: `${CUBE_WIDTH * 2}px`,
    height: `${CUBE_WIDTH * 2}px`,
  },
  isOver: {
    border: `4px solid ${theme.palette.warning.main}`,
  },
}))
