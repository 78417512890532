import React, {FC} from "react"
import {Box, Button, Typography, Container} from "@material-ui/core"
import {useErrorPageStyles} from "./ErrorPageCard.styles"
import {useHistory} from "react-router"
import { useSelector } from "react-redux"
import { selectAuthenticated } from "../../store/session/session.selectors"
import { getHomeRouteForRole } from "../user/userRole/UserRole.utils"
import { selectUserRole } from "../../store/user/user.selectors"

interface ErrorPageCardProps {
  title: string;
  description: string;
}

const ErrorPageCard: FC<ErrorPageCardProps> = (
  {
    title,
    description,
  }
) => {
  const classes = useErrorPageStyles()
  const {push} = useHistory()

  const authenticated = useSelector(selectAuthenticated)
  const userRole = useSelector(selectUserRole)

  const navigateTo = () => {
    (authenticated && userRole)
      ? push(getHomeRouteForRole(userRole))
      : push("/login")
  }

  return (
    <Container className={classes.root}>
      <Box className={classes.contentWrapper}>
        <Typography
          variant={"h3"}
          component="h2"
          className={classes.title}
        >
          { title }
        </Typography>

        <Typography
          className={classes.description}
          color="textSecondary"
        >
          { description }
        </Typography>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={navigateTo}
        >
          { authenticated
            ? "Wróć na stronę główną"
            : "Przejdź do panelu logowania"
          }
        </Button>
      </Box>
    </Container>
  )
}

export default ErrorPageCard
