import React, { FC } from "react"
import ErrorPageCard from "../../components/errorPageCard/ErrorPageCard.component"
import { RouteComponentProps } from "react-router"
import store from "../../store"
import { push } from "connected-react-router"

type ServerErrorProps = RouteComponentProps<{ code: string }>

const ServerErrorPage: FC<ServerErrorProps> = (props) => {
  const errorCode = Number(props.match.params.code)
  if (!errorCode || typeof errorCode !== "number" || errorCode < 500) {
    store.dispatch(push("/404"))
  }

  return (
    <ErrorPageCard
      title={`${errorCode} - Błąd serwera`}
      description="Wystąpił problem z serwerem aplikacji"
    />
  )
}

export default ServerErrorPage