import React, { FC, useState } from "react"
import {Button, ClickAwayListener, Paper, Popper} from "@material-ui/core"
import { useUsersListActionsStyles } from "./UsersListActions.styles"
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import EditUserPopup from "../editUserPopup/EditUser.component"
import { UserItemNode } from "../../../store/user/user.types"
import PreviewUserPopup from "../previewUserPopup/PreviewUser.component"
import ArchiveUserPopup from "../archiveUserPopup/ArchiveUser.component"
import {ArrowDropDown, ArrowForward} from "@material-ui/icons"
import { useHistory } from "react-router"
import { UserRole } from "../userRole/UserRole.types"

interface UsersListActionsProps {
  user: UserItemNode;
  refetch(): void;
}

const UsersListActions: FC<UsersListActionsProps> = ({ user, refetch }) => {
  const classes = useUsersListActionsStyles()
  const {push} = useHistory()

  const [isOpenEditUserPopup, setIsOpenEditUserPopup] = useState<boolean>(false)
  const [isOpenPreviewUserPopup, setIsOpenPreviewUserPopup] = useState<boolean>(false)
  const [isOpenArchiveUserPopup, setIsOpenArchiveUserPopup] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<null|HTMLElement>(null)
  const open = Boolean(anchorEl)
  const openDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closeDropdown = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        size={"small"}
        variant={"outlined"}
        color={"secondary"}
        onClick={openDropdown}
      >
        Akcje użytkownika
        <ArrowDropDown/>
      </Button>
      <Popper
        anchorEl={anchorEl}
        open={open}
      >
        <ClickAwayListener onClickAway={closeDropdown}>
          <Paper className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              onClick={() => setIsOpenEditUserPopup(true)}
            >
              <EditIcon className={classes.icon}/>
              Edytuj
            </Button>

            <Button
              className={classes.button}
              onClick={() => setIsOpenPreviewUserPopup(true)}
            >
              <VisibilityIcon className={classes.icon}/>
              Podgląd
            </Button>

            <Button
              className={classes.button}
              onClick={() => setIsOpenArchiveUserPopup(true)}
            >
              { user.archived ? <UnarchiveIcon className={classes.icon}/> : <ArchiveIcon className={classes.icon}/> }
              { user.archived ? "Aktywuj" : "Archiwizuj" }
            </Button>

            { user.role === UserRole.TESTED_PERSON && (
              <Button
                className={classes.button}
                onClick={() => push(`/user/${user.userId}`)}
              >
                <ArrowForward className={classes.icon}/>
                Karta gier
              </Button>
            )}

            <EditUserPopup
              user={user}
              open={isOpenEditUserPopup}
              refetch={refetch}
              onClose={() => setIsOpenEditUserPopup(false)}
            />

            <PreviewUserPopup
              user={user}
              open={isOpenPreviewUserPopup}
              onClose={() => setIsOpenPreviewUserPopup(false)}
            />

            <ArchiveUserPopup
              user={user}
              open={isOpenArchiveUserPopup}
              refetch={refetch}
              onClose={() => setIsOpenArchiveUserPopup(false)}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default UsersListActions
