import React, {FC} from "react"
import { Container, Box, AppBar, Drawer, Divider, Toolbar, } from "@material-ui/core"
import useLayoutStyles from "./Layout.styles"
import PageFooter from "../components/pageFooter/PageFooter.component"
import MenuItemsComponent from "../components/menu/mainMenu/MenuItems.component"
import UserMenu from "../components/menu/userMenu/UserMenu.component"
import SessionManager from "../components/common/sessionManager/SessionManager.component"
import TypographyController from "../components/wcag/typographyController/TypographyController.component"
import PaletteController from "../components/wcag/paletteController/PaletteController.component"

interface DefaultLayoutProps {}

const AdminLayout: FC<DefaultLayoutProps> = ({ children }) => {
  const classes = useLayoutStyles({})

  return (
    <div className={classes.adminRoot}>
      <AppBar
        position="absolute"
        className={classes.appBar}
      >
        <Toolbar className={classes.topToolbar}>
          <SessionManager/>

          <PaletteController/>
          <TypographyController/>
          <UserMenu/>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={true}
      >
        <div className={classes.toolbarHeader}>
          Menu
        </div>

        <Divider />

        <Box>
          <MenuItemsComponent/>
        </Box>
      </Drawer>

      <Box component="main" className={classes.adminContent}>
        <div className={classes.appBarSpacer} />

        <Container
          maxWidth="lg"
          className={classes.adminContentContainer}
        >
          <Box mb="auto">
            { children }
          </Box>
        </Container>

        <PageFooter/>
      </Box>
    </div>
  )
}

export default AdminLayout
