import React, { FC } from "react"
import ErrorPageCard from "../../components/errorPageCard/ErrorPageCard.component"

const Error404Page: FC = () => {
  return (
    <ErrorPageCard
      title="404 - Nie ma takiej strony"
      description="Strona o takim adresie URL nie została znaleziona"
    />
  )
}

export default Error404Page