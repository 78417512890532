import confetti from "canvas-confetti";

export const fireConfetti = (timeout: number) => {
  const randomInRange = (min: number, max: number): number => {
    return Math.random() * (max - min) + min;
  }

  setTimeout(() => {
    confetti({
      angle: randomInRange(65, 125),
      spread: randomInRange(50, 80),
      particleCount: randomInRange(70, 120),
      origin: { y: 0.6 },
      zIndex: 1300
    });
  }, timeout)
}