import * as yup from "yup"
import {
  maxNumberErrorMessage,
  numberErrorMessage,
  requiredErrorMessage,
} from "../formItems/errors/yupErrorMessages"
import { GAME_TYPE } from "../games/Game.types"

export const gameSettingsValidationSchema = yup.object().shape({
  game: yup.string()
    .required(requiredErrorMessage),
  level: yup.number()
    .required(requiredErrorMessage),
  name: yup.string()
    .required(requiredErrorMessage),
  description: yup.string()
    .required(requiredErrorMessage),
  size: yup.number()
    .required(requiredErrorMessage)
    .typeError(numberErrorMessage)
    .positive(numberErrorMessage)
    .when("game", {
      is: GAME_TYPE.MEMORY,
      then: yup.number().max(16, maxNumberErrorMessage(16)),
    }),
  target: yup.number()
    .required(requiredErrorMessage)
    .typeError(numberErrorMessage)
    .positive(numberErrorMessage),
  maxAttempts: yup.number()
    .notRequired()
    .typeError(numberErrorMessage)
    .positive(numberErrorMessage)
    .nullable(true),
  timeLimit: yup.number()
    .required(requiredErrorMessage)
    .typeError(numberErrorMessage)
    .positive(numberErrorMessage),
})