export type SessionState = {
  sessionState?: {
    sessionToken: string;
    user_id: string;
    exp: Date;
  }
}

export type TokenData = {
  aud: string;
  exp: Date;
  iat: Date;
  iss: string;
  role: string;
  tenant_id: null | string;
  user_id: string;
}

export const SESSION_STATE_STORAGE_KEY = "session"
