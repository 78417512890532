import store from "./store"

const clientFetch: WindowOrWorkerGlobalScope["fetch"] = async (uri, options?: RequestInit) => {
  const { session } = store.getState()

  const headers = {...(options ? options.headers : {}) as Record<string, string>};
  if (session && session.sessionState && session.sessionState.sessionToken) {
    headers['Authorization'] = `Bearer ${session.sessionState.sessionToken}`;
  }

  return await fetch(uri, {
    ...options,
    headers,
  })
}

export default clientFetch;
