import { makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"

const box3dGridDimension = "90px"

export const useBox3dGridStyles = makeStyles((theme: Theme) => createStyles({
  box: {
    float: "left",
  },
  face: {
    border: `1px solid ${theme.palette.common.black}`,
    width: box3dGridDimension,
    height: box3dGridDimension,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  empty: {
    width: box3dGridDimension,
    height: box3dGridDimension,
    border: `1px solid ${theme.palette.common.black}`,
  }
}))
