import { Box } from "@material-ui/core"
import React, { FC, useState } from "react"
import background from "../../../assets/images/background/game3.jpg"
import { useBackgroundImage } from "../../../hooks/useBackgroundImage";
import { GameComponentProps } from "../../../pages/GamePage";
import GameCard from "../gameCard/GameCard.component"
import clsx from "clsx"
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useGameCardStyles } from "../gameCard/GameCard.styles"
import DragAndDrop from "./dragAndDrop/DragAndDrop.component"
import { DicesTemplate, DraggableBoardType } from "./DicesGame.types"

export const CUBE_WIDTH = 50

const DicesGame: FC<GameComponentProps<DicesTemplate>> = (
  {
    state,
    gameType,
    definition,
    startedAt,
    closedAt,
    attempts,
    onStart,
    onMove,
    onFinish,
  }
) => {
  useBackgroundImage(background);
  const classes = useGameCardStyles()

  const [template, setTemplate] = useState<DicesTemplate>(state)
  const [timeLimitReached, setTimeLimitReached] = useState<boolean>(false)

  const [correctDicesNumber, setCorrentDicesNumber] = useState<number>(0)
  const [dicesNumberInResulCol, setDicesNumberInResulCol] = useState<number>(0)
  const [attemptsMade, setAttemptsMade] = useState<number>(attempts)

  const ended = timeLimitReached || null != closedAt;
  const won = correctDicesNumber >= definition.target;

  const start = !startedAt ? () => onStart() : undefined;
  const finish = (!!startedAt && !ended) ? () => onFinish(correctDicesNumber, template) : undefined;

  const onChange = (resultsBoard: DraggableBoardType) => {
    let newDicesNumberInResulCol = 0
    const answer = resultsBoard.map(col => col.map(diceItem => {
      if (diceItem) newDicesNumberInResulCol++
      return diceItem ? diceItem.id : null
    }))

    const newTemplate = {...template, answer}
    const newAttemptsMadeNumber = attemptsMade + 1
    setTemplate(newTemplate)
    setAttemptsMade(newAttemptsMadeNumber)
    setDicesNumberInResulCol(newDicesNumberInResulCol)

    checkCorrectAnswers(resultsBoard)
    onMove(newAttemptsMadeNumber, newTemplate)
  }

  const checkCorrectAnswers = (resultsBoard: DraggableBoardType) => {
    let correct = 0
    const { border } = template

    resultsBoard.forEach((boardCol, colIdx) => boardCol.forEach((cube, rowIdx) => {
      if (cube) {
        const { top, right, bottom, left } = cube

        if (
          ( // top
            (!rowIdx && top === border)
            || (rowIdx && (!resultsBoard[colIdx][rowIdx - 1] || top === resultsBoard[colIdx][rowIdx - 1]?.bottom))
          ) && ( // left
            (!colIdx && left === border)
            || (colIdx && (!resultsBoard[colIdx - 1][rowIdx] || left === resultsBoard[colIdx - 1][rowIdx]?.right))
          ) && ( // bottom
            (rowIdx === boardCol.length - 1 && bottom === border)
            || (rowIdx !== boardCol.length - 1 && (!resultsBoard[colIdx][rowIdx + 1] || bottom === resultsBoard[colIdx][rowIdx + 1]?.top))
          ) && ( // right
            (colIdx === resultsBoard.length - 1 && right === border)
            || (colIdx !== resultsBoard.length - 1 && (!resultsBoard[colIdx + 1][rowIdx] || right === resultsBoard[colIdx + 1][rowIdx]?.left))
          )
        ) {
          correct++
        }
      }
    }))

    setCorrentDicesNumber(correct)
  }

  return (
    <GameCard
      gameType={gameType}
      definition={definition}
      startedAt={startedAt}
      closedAt={closedAt}
      won={won}
      ended={ended}
      onStart={start}
      onEnd={finish}
      onTimerEnd={() => setTimeLimitReached(true)}
      stats={[
        {
          label: 'Liczba ułożonych kości',
          value: dicesNumberInResulCol + " z " + definition.target
        },
      ]}
      resultDescription={`Ułożono poprawnie ${correctDicesNumber} z ${definition.target} wymaganych kości.`}
    >
      <Box className={clsx(classes.game, classes.main, !startedAt && classes.wrapperPlaceholder)}>
        <DndProvider backend={HTML5Backend}>
          <DragAndDrop
            template={template}
            onChange={onChange}
          />
        </DndProvider>
      </Box>
    </GameCard>
  )
}

export default DicesGame
