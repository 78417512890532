import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
  from
} from "@apollo/client"
import { onError } from "@apollo/client/link/error";
import clientFetch from "./fetch";
import { RoutePath } from "./routes/Routes.types"
import store from "./store"
import { clearSession } from "./store/session/session.slice"

const httpLink = new HttpLink({
  uri: '/graphql',
  fetch: clientFetch,
});

const errorLink = onError((error) => {
  // @ts-ignore
  if (error.graphQLErrors?.length && error.graphQLErrors[0].statusCode) {
    // @ts-ignore
    const {statusCode} = error.graphQLErrors[0]

    if (statusCode === 401) {
      store.dispatch(clearSession())
      window.location.href = window.location.origin + "/login"
    }
    if (statusCode === 403) {
      window.location.href = window.location.origin + RoutePath.ERROR_403
    }
    if (statusCode >= 500) {
      window.location.href = `${window.location.origin}/server-error/${statusCode}`
    }
  }

  if (error.networkError || !error.graphQLErrors) {
    throw error;
  }

  if (1 === error.graphQLErrors.length && !error.graphQLErrors[0].path) {
    throw error;
  }
})

const client = new ApolloClient<NormalizedCacheObject>({
  link: from([
    errorLink,
    httpLink,
  ]),
  connectToDevTools: process.env.NODE_ENV !== "production",
  cache: new InMemoryCache({
    dataIdFromObject: (object: any) => object.nodeId || false,
  }),
});

export default client;
