import {makeStyles, createStyles, Theme} from "@material-ui/core";

export const useUserCompetencesCardStyles = makeStyles((theme: Theme) => createStyles({
  header: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
  defaultTheme: {
    color: theme.palette.common.white,
    '& span': {
      color: theme.palette.common.white
    }
  },
  highContrastTheme: {
    color: theme.palette.common.black,
    '& span': {
      color: theme.palette.common.black
    }
  }
}))