import { Box, Checkbox, InputLabel } from "@material-ui/core"
import React, { FC, useState, ChangeEvent } from "react"
import background from "../../../assets/images/background/game4.svg"
import { useBackgroundImage } from "../../../hooks/useBackgroundImage";
import { GameComponentProps } from "../../../pages/GamePage";
import GameCard from "../gameCard/GameCard.component"
import clsx from "clsx"
import { useGameCardStyles } from "../gameCard/GameCard.styles"
import Box3dComponent from "./box3d/Box3d.component"
import { useBox3dGameStyles } from "./Box3dGame.styles"
import Box3dGrid from "./grid/Box3dGrid.component"
import { Box3dTemplate } from "./Box3dGame.types"
import { useContainerMaxWidth } from "../../../hooks/useContainerMaxWidth"

const Box3dGame: FC<GameComponentProps<Box3dTemplate>> = (
  {
    state,
    gameType,
    definition,
    startedAt,
    closedAt,
    hacks,
    attempts,
    onStart,
    onMove,
    onFinish,
  }
) => {
  useBackgroundImage(background)
  useContainerMaxWidth("xl")

  const classes = useBox3dGameStyles()
  const gameCardClasses = useGameCardStyles()

  const [template, setTemplate] = useState<Box3dTemplate>(state)
  const [timeLimitReached, setTimeLimitReached] = useState<boolean>(false);
  const [movesLeft, setMovesLeft] = useState<number>(definition.maxAttempts as number - attempts);

  const checkedFiguresNumber = template.options.filter(template => template.checked).length
  const ended = timeLimitReached || null != closedAt || 0 === movesLeft;
  const correctCheckedFiguresNumber = template.options.filter(option => option.valid && option.checked).length
  const won = correctCheckedFiguresNumber === definition.target

  const start = !startedAt ? () => onStart() : undefined;
  const finish = (!!startedAt && !ended) ? () => onFinish(correctCheckedFiguresNumber, template) : undefined;

  const onChange = (index: number) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!movesLeft) return

    const newTemplate = {
      ...template,
      options: template.options.map((option, idx) => index === idx
        ? { ...option, checked }
        : option
      )
    }

    const newMovesLeftValue = checked ? movesLeft - 1 : movesLeft
    setMovesLeft(newMovesLeftValue)
    setTemplate(newTemplate)
    onMove(definition.maxAttempts as number - newMovesLeftValue, newTemplate)

    if (!newMovesLeftValue) {
      onFinish(newTemplate.options.filter(option => option.valid && option.checked).length, newTemplate)
    }
  }

  return (
    <GameCard
      gameType={gameType}
      definition={definition}
      startedAt={startedAt}
      closedAt={closedAt}
      won={won}
      ended={ended}
      onStart={start}
      onEnd={finish}
      onTimerEnd={() => setTimeLimitReached(true)}
      stats={[
        {
          label: 'Liczba kostek do znalezienia',
          value: `${Math.max(0, definition.target - checkedFiguresNumber)} z ${definition.target}`
        },
        {
          label: 'Liczba pozostałych ruchów',
          value: movesLeft
        },
      ]}
      resultDescription={`Poprawnie zaznaczono ${correctCheckedFiguresNumber} z ${definition.target} wymaganych figur.
`}
    >
      <Box className={clsx(gameCardClasses.game, gameCardClasses.main, classes.wrapper, !startedAt && gameCardClasses.wrapperPlaceholder)}>
        <Box className={classes.wrapperItem}>
          <Box3dGrid grid={state.projection}/>
        </Box>

        <Box className={clsx(classes.wrapperItem, classes.bottomWrapperItem)}>
          { template.options.map((option, index) => (
            <div key={index}>
              <Box3dComponent option={option}/>

              <div className={classes.checkboxWrapper}>
                <Checkbox
                  id={`cube${index}`}
                  checked={option.checked}
                  disabled={!option.checked && checkedFiguresNumber === definition.target}
                  onChange={onChange(index)}
                />

                <InputLabel
                  htmlFor={`cube${index}`}
                  className={clsx(classes.label, !option.checked && checkedFiguresNumber === definition.target && classes.labelDisabled)}
                >
                  Kostka { index + 1 } {hacks ? `(${option.valid ? 'Poprawna' : "Niepoprawna"})` : ''}
                </InputLabel>
              </div>
            </div>
          ))}
        </Box>
      </Box>
    </GameCard>
  )
}

export default Box3dGame
