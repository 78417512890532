import React, { FC } from "react"
import { Box, Typography } from "@material-ui/core"
import {useSessionManagerPopupStyles} from "./SessionManagerPopup.styles"
import Popup from "../../popup/Popup.component"

interface SessionManagerPopupProps {
  open: boolean;
  onClose(): void;
  timeLeft: string;
  refresh(): void;
}

const SessionManagerPopup: FC<SessionManagerPopupProps> = ({open, onClose, timeLeft, refresh}) => {
  const classes = useSessionManagerPopupStyles()

  const handleClosePopup = () => {
    onClose()
    refresh()
  }

  return (
    <>
      <Popup
        open={open}
        onClose={handleClosePopup}
        title={"Czas sesji dobiega końca"}
        actionButtonText={"Odśwież sesję"}
        actionButtonProps={{
          onClick: handleClosePopup
        }}
      >
        <Box mb={2}>
          <Typography component="span">
            Czas Twojej sesji dobiega końca.
          </Typography>
          <Typography className={classes.mb}>
            Po jego upłynięciu zostaniesz <b>wylogowany</b>.
          </Typography>
          <Typography>
            Zostało <Typography color="error" className={classes.timeLeft}>{ timeLeft }</Typography>
          </Typography>
        </Box>
      </Popup>
    </>
  )
}

export default SessionManagerPopup
