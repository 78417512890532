const MuiTypography = {
  variantMapping: {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    subtitle1: "p", //body-mobile
    subtitle2: "p", //supporting-mobile
    body1: "p", //body
    body2: "p", //supporting
  },
}

export const typography = {
  MuiTypography
}