import {call, put, takeEvery, throttle} from "redux-saga/effects"
import { initSession, setSession, clearSession, refreshSession } from "./session.slice"
import {SagaIterator} from "redux-saga"
import { clearUserData, getUserData } from "../user/user.slice"
import { SESSION_STATE_STORAGE_KEY, TokenData } from "./session.types"
import client from "../../client"
import { REFRESH_SESSION } from "./session.mutation"
import jwtDecode from "jwt-decode"

function* initSessionSaga({payload}: ReturnType<typeof initSession>): SagaIterator {
  if (payload.sessionState && payload.sessionState.user_id) {
    yield put(getUserData())
    yield call(() => localStorage.setItem(SESSION_STATE_STORAGE_KEY, JSON.stringify(payload.sessionState)))
  }
}

function* setSessionSaga({payload}: ReturnType<typeof setSession>) {
  yield call(() => localStorage.setItem(SESSION_STATE_STORAGE_KEY, JSON.stringify(payload.sessionState)))
}

function* refreshSessionSaga() {
  try {
    const {data: {session: {sessionToken}}} = yield call(client.mutate, {
      mutation: REFRESH_SESSION,
      variables: {
        input: {}
      }
    })

    const decodedToken: TokenData = jwtDecode(sessionToken)
    yield put(setSession({
      sessionState: {
        sessionToken,
        exp: decodedToken.exp,
        user_id: decodedToken.user_id,
      }
    }))
  } catch (e) {
    yield put(clearSession());
  }
}

function* clearSessionSaga() {
  yield call(() => localStorage.removeItem(SESSION_STATE_STORAGE_KEY))
  yield put(clearUserData())

  yield call(() => client.stop())
  yield call(() => client.clearStore())
  yield call(() => client.cache.reset())
}

export default function* (): SagaIterator {
  const sessionJSON: string = yield call(() => localStorage.getItem(SESSION_STATE_STORAGE_KEY))
  const sessionState = JSON.parse(sessionJSON)

  if (!!sessionState?.sessionToken) {
    yield put(initSession({ sessionState }))
  }

  yield takeEvery(initSession, initSessionSaga)
  yield takeEvery(setSession, setSessionSaga)
  yield takeEvery(clearSession, clearSessionSaga)
  yield throttle(1000, refreshSession, refreshSessionSaga)
}
