import { gql } from "@apollo/client"

export const TENANTS_LIST_QUERY = gql`
    query GetTenantsList(
        $first: Int,
        $offset: Int,
        $filter: TenantFilter,
        $orderBy: [TenantsOrderBy!]
    ) {
        connection: tenants(
          first: $first,
          offset: $offset,
          filter: $filter,
          orderBy: $orderBy
        ) {
            totalCount
            edges {
                node {
                    tenantId
                    name
                    archived
                    createdAt
                    membersCount
                }
            }
        }
    }
`;