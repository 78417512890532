import { gql } from "@apollo/client"

export const GAMES_QUERY = gql`
  query GetGames {
    games: gameLevels {
      totalCount
      edges {
        node {
          game
          level
          size
          target
          maxAttempts
          timeLimit
          gameType: gameDefinitionByGame {
            name
            description
          }
        }
      }
    }
  }
`;
