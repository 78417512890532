import {makeStyles} from "@material-ui/core"
import {createStyles, Theme} from "@material-ui/core/styles"
import { CUBE_WIDTH } from "../../DicesGame.component"
import { Theme as DefaultTheme } from "@material-ui/core/styles/createTheme"

interface DropWrapperStylesProps {
  resultColBorderColor?: string;
}

export const useDropWrapperStyles = makeStyles<DefaultTheme, DropWrapperStylesProps>((theme: Theme) => createStyles({
  wrapper: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    flex: 1,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  wrapperBorder: {
    borderTop: "12px solid black",
    borderBottom: "12px solid black",
    "&:first-child": {
      borderLeft: "12px solid black",
      borderColor: ({resultColBorderColor}) => resultColBorderColor ? resultColBorderColor : "black",
    },
    "&:last-child": {
      borderRight: "12px solid black",
      borderColor: ({resultColBorderColor}) => resultColBorderColor ? resultColBorderColor : "black",
    },
    borderColor: ({resultColBorderColor}) => resultColBorderColor ? resultColBorderColor : "black",
  },
  cubeWrapper: {
    width: `${CUBE_WIDTH * 2}px`,
    height: `${CUBE_WIDTH * 2}px`,
  },
  initCubeItem: {
    margin: theme.spacing(2, 1),
  }
}))
