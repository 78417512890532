import React, { FC } from "react"
import { MenuItem } from "@material-ui/core"
import TextFieldController, {
  TextFieldControllerProps
} from "../../formItems/textFieldController/TextFieldController.component"

interface GameLevelSelectProps
  extends TextFieldControllerProps
{}

const GameLevelSelect: FC<GameLevelSelectProps> = (props) => {
  return (
    <TextFieldController
      select
      {...props}
    >
      { [1, 2, 3, 4, 5, 6].map(value => (
        <MenuItem
          key={value}
          value={value}
        >
          Poziom { value }
        </MenuItem>
      ))}
    </TextFieldController>
  )
}

export default GameLevelSelect
