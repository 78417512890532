import React, { FC } from "react"
import clsx from "clsx"
import useLayoutStyles from "./Layout.styles"

interface BaseLayoutProps {
  className?: string;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children, className }) => {
  const classes = useLayoutStyles({})

  return (
    <div className={clsx(classes.root, className)}>
      {children}
    </div>
  )
}

export default BaseLayout
