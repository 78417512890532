import { makeStyles, createStyles, Theme, alpha } from "@material-ui/core";

export const useUserGameCardStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1,0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: alpha(theme.palette.common.white, 0.8),
    boxShadow: theme.shadows[4],
    width: '100%',
    minHeight: "120px",
  },
  level: {
    marginBottom: theme.spacing(2)
  },
  contentWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 0.5),
  },
  completedBox: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    margin: theme.spacing(0, 1),
    ...theme.typography.button,
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  completedIcon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.success.light
  }
}))