import React, {FC} from "react"
import AdminLayout from "../layout/AdminLayout"

interface WithAdminLayoutProps {}

const withAdminLayout = <T extends WithAdminLayoutProps>(WrappedComponent: FC<T & WithAdminLayoutProps>): FC<T> => (props: T) => {
  return (
    <AdminLayout>
      <WrappedComponent {...props} />
    </AdminLayout>
  )
}

export default withAdminLayout
