import theme from "../../../themes/theme"

const getColor: { [key: number]: string } = {
  0: theme.palette.common.black,
  1: theme.palette.primary.main,
  2: theme.palette.error.main,
  3: theme.palette.warning.main,
  4: theme.palette.success.main,
  5: theme.palette.grey.A200,
  6: theme.palette.secondary.main,
  7: "pink",
  8: "yellow",
  9: "purple",
  10: "tomato",
  11: theme.palette.common.white,
}

export default getColor