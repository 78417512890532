import React, { FC } from 'react'
import {Box, Accordion, AccordionSummary, AccordionDetails, Typography} from '@material-ui/core'
import {ExpandMore} from "@material-ui/icons";
import UserCompetencesChart from "../userCompetencesChart/UserCompetencesChart.component";
import {useUserCompetencesCardStyles} from "./UserCompetencesCard.styles";
import clsx from 'clsx';
import { useIsHighContrast } from '../../../../hooks/useIsHightContrast';

const UserCompetencesCard: FC = () => {
  const classes = useUserCompetencesCardStyles();
  const isHighContrast = useIsHighContrast()
  return (
    <Box>
      <Accordion>
        <AccordionSummary
          className={clsx(classes.header, isHighContrast ? classes.highContrastTheme : classes.defaultTheme) }
          expandIcon={ <ExpandMore/> }
        >
          <Typography
            component='p'
            variant='h3'
          >
            Moje kompetencje
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserCompetencesChart/>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default UserCompetencesCard