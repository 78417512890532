import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useTableCellWithFilter = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
    popover: {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[4],
      padding: theme.spacing(2),
      flex: 1,
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      width: "500px",
      maxWidth: "80vw",
    },
    buttonsWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    }
  }),
)