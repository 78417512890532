import React, { FC, useEffect, useState } from "react"
import { Box, MenuItem, Typography } from "@material-ui/core"
import TextFieldController, { TextFieldControllerProps } from "../../formItems/textFieldController/TextFieldController.component"
import { useQuery } from "@apollo/client"
import { TENANTS_LIST_QUERY } from "./TenantsSelect.query"
import { TenantSelectItem } from "./TenantsSelect.types"

interface TenantsSelectProps
  extends TextFieldControllerProps
{}

const TenantsSelect: FC<TenantsSelectProps> = ({ disabled,  ...props}) => {
  const { data, loading } = useQuery<{ connection: { edges: Array<{ node: TenantSelectItem}>}}>(TENANTS_LIST_QUERY);
  const [ options, setOptions ] = useState<TenantSelectItem[]>([])

  useEffect(() => {
    if (data) {
      setOptions(data.connection.edges.map(edge => edge.node))
    }
  }, [loading])

  return (
    <TextFieldController
      select
      {...props}
      disabled={disabled || loading}
    >
      { options.length
        ? (
          options.map((option: TenantSelectItem) => (
            <MenuItem
              key={option.tenantId}
              value={option.tenantId}
            >
              {option.name}
            </MenuItem>
          ))
        )
        : (
          <Box m={2}>
            <Typography variant={"body2"}>
              <i>Brak podmiotów</i>
            </Typography>
          </Box>
        )
      }
    </TextFieldController>
  )
}

export default TenantsSelect
