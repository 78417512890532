import React, { FC } from "react"
import { useDropWrapperStyles } from "./DropWrapper.styles"
import clsx from "clsx"
import { DraggableBoardType, DroppableId } from "../../DicesGame.types"
import CubeItem from "../cubeItem/CubeItem.component"
import EmptyCell from "../emptyCell/EmptyCell.component"
import getColor from "../../DicesGame.utils"

export interface DropWrapperProps {
  droppableId: DroppableId;
  items: DraggableBoardType;
  border?: number;
  updateBoardCell(
    cubeId: number,
    sourceId: DroppableId,
    destinationId: DroppableId,
    srcX: number,
    srcY: number,
    destX: number,
    destY: number,
  ): void;
}

const DropWrapper: FC<DropWrapperProps> = (
  {
    droppableId,
    border = 0,
    updateBoardCell,
    items
  }
) => {
  const classes = useDropWrapperStyles({resultColBorderColor: getColor[border]})

  return (
    <div className={classes.wrapper}>
      { items.map((itemRow, columnIndex: number) => (
        <div
          key={columnIndex}
          className={clsx(droppableId === DroppableId.RESULT_COL && classes.wrapperBorder)}
        >
          { itemRow.map((item, rowIndex: number) => (
            <div key={`${columnIndex}-${rowIndex}`}>
              <div
                className={clsx(
                  classes.cubeWrapper,
                  droppableId === DroppableId.INIT_COL && classes.initCubeItem
                )}
              >
                { item
                  ? <CubeItem
                    {...item}
                    droppableId={droppableId}
                    x={columnIndex}
                    y={rowIndex}
                  />
                  : <EmptyCell updateBoardCell={(cubeId, sourceId, x, y) => updateBoardCell(
                    cubeId,
                    sourceId, droppableId,
                    x, y,
                    columnIndex, rowIndex,
                  )}
                  />
                }
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default DropWrapper