import * as yup from "yup"
import {
  maxNumberErrorMessage,
  numberErrorMessage,
  requiredErrorMessage,
} from "../../formItems/errors/yupErrorMessages"
import { questionsData } from "../questionsCard/QuestionsCardData.utils"

export const entryExitFormResultCardValidationSchema = yup.object().shape({
  answers: yup.array().of(
    yup.number()
    .nullable(true)
    .required(requiredErrorMessage)
    .typeError(numberErrorMessage)
    .moreThan(-1, numberErrorMessage)
    .test({
      name: 'max-number-validate',
      test: function(value) {
        const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
        const maxValue = questionsData[index].maxPoints

        return value && value > maxValue
          ? this.createError({
            message: maxNumberErrorMessage(maxValue),
            path: this.path,
          })
          : true;
      },
    }),
  )
})