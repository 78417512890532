import React, { FC } from 'react';
import {Container, Typography} from "@material-ui/core";
import {useEntryExitFormResultCardStyles} from "./EntryExitFormResultCard.styles";

interface EntryExitFormResultCardProps {
  title: string;
}

const EntryExitFormResultCard: FC<EntryExitFormResultCardProps> = ({ title, children }) => {
  const classes = useEntryExitFormResultCardStyles();

  return(
    <Container className={classes.root}>
      <Typography
        component='h1'
        variant='h2'
        className={classes.header}
      >
        { title }
      </Typography>
      {children}
    </Container>
  )
}

export default EntryExitFormResultCard;