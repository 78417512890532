import React, { FC } from 'react'
import {Box, Button, Chip, Tooltip, Typography} from "@material-ui/core";
import {useUserGameCardStyles} from "./UserGameCard.styles";
import { GAME_TYPE, GameNode } from "../../Game.types";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {useMutation} from "@apollo/client";
import {CREATE_GAME_MUTATION} from "./UserGameCard.mutation";
import {refreshSession} from "../../../../store/session/session.slice";
import UserGameCardStats from "../userGameCardStats/UserGameCardStats.component"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface UserGameCardProps extends GameNode {
  disabled: boolean;
}

const UserGameCard: FC<UserGameCardProps> = (
  {
    games,
    level,
    unlocked,
    lastGame,
    game,
    completed,
    disabled,
  }
) => {
  const classes = useUserGameCardStyles()
  const dispatch = useDispatch()
  const {push} = useHistory()

  const [createGame] = useMutation(CREATE_GAME_MUTATION)

  const submitCreateGame = async (gameType: GAME_TYPE, level: number) => {
    dispatch(refreshSession())

    const result = await createGame({
      variables: {
        input: {gameType, level}
      }
    });

    const notCompletedRoute = level === 6 && !completed
    push(`/game/${result.data.mutation.game.gameId}${notCompletedRoute ? "/notCompleted" : ""}`)
  }

  const getGameLevelCardContent = () => {
    if (!unlocked && !completed) {
      return (
        <Typography component='p' variant='body2'>
          { disabled
            ? "Poziom zablokowany" // for leader preview
            : level === 1  // for tested-user
              ? "Zagraj najpierw w inną grę, by odblokować tą grę"
              : "Odblokuj poprzedni poziom, żeby zagrać lub zagraj w inną grę"
          }
        </Typography>
      )
    } else if (completed && level !==6) {
      return (
        <Chip
          label="Zaliczone!"
          className={classes.completedBox}
        />
      )
    } else if (!disabled) { // not for leader preview
      return (
        <Button
          onClick={() => submitCreateGame(game, level)}
          variant='contained'
          disabled={disabled}
          className={classes.button}
          color='primary'
        >
          Zagraj
        </Button>
      )
    }
  }

  return (
    <Box className={classes.root}>
      <Box display={"flex"}>
        <Typography
          variant='button'
          component='span'
          className={classes.level}
        >
          Poziom {level}
        </Typography>

        {level === 6 && completed && (
          <Tooltip title={"Poziom został już zaliczony."}>
            <CheckCircleIcon className={classes.completedIcon}/>
          </Tooltip>
        )}

      </Box>

      <Box className={classes.contentWrapper}>
        <UserGameCardStats
          gamesAmount={games}
          lastGame={lastGame}
          unlocked={unlocked}
        />

        { getGameLevelCardContent() }
      </Box>
    </Box>
  )
}

export default UserGameCard