import React, { FC, useState } from "react"
import { Box, Button, Typography } from "@material-ui/core"
import TextFieldController from "../../../components/formItems/textFieldController/TextFieldController.component"
import { useForm, FormProvider } from "react-hook-form"
import { useForgetPasswordStyles } from "./ForgetPasswordPage.styles"
import AuthPageCard from "../../../components/authPageCard/AuthPageCard.component"
import { yupResolver } from "@hookform/resolvers/yup"
import { forgetPasswordValidationSchema } from "./ForgetPasswordPage.validation"
import { useMutation } from "@apollo/client"
import { FORGET_PASSWORD } from "./ForgetPasswortPage.mutation"
import GlobalSuccessSnackbar from "../../../components/formItems/globalSuccessSnackbar/GlobalSuccessSnackbar"

interface ForgetPasswordPageProps {}

const ForgetPasswordPage: FC<ForgetPasswordPageProps> = () => {
  const classes = useForgetPasswordStyles()
  const [forgetPassword, {loading}] = useMutation(FORGET_PASSWORD)
  const [isSuccessRequest, setIsSuccessRequest] = useState<boolean>(false)

  const form = useForm({
    mode: "all",
    resolver: yupResolver(forgetPasswordValidationSchema),
    defaultValues: {
      email: ""
    }
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    setIsSuccessRequest(false)

    try {
      await forgetPassword({
        variables: {
          input: values
        }
      })

      form.reset()
      setIsSuccessRequest(true)
    } catch (exception) {
      // processResponseException(form, exception);
    }
  })

  return (
    <AuthPageCard
      title="Nie pamiętasz hasła?"
      showGoBackButton
    >
      <>
        <Box mb={2} display="inline-block">
          <Typography color="textSecondary">
            Wpisz swój adres e-mail. Dostaniesz na niego link do resetu hasła.
          </Typography>
        </Box>

        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
          >
            <TextFieldController
              name="email"
              label={'E-mail'}
              isRequired
              disabled={loading}
              className={classes.input}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              className={classes.submitButton}
            >
              Wyślij
            </Button>
          </form>
        </FormProvider>

        <GlobalSuccessSnackbar
          isSuccess={isSuccessRequest}
          successMessage={"Link do resetu hasła został wysłany na podany adres e-mail."}
        />
      </>
    </AuthPageCard>
  )
}

export default ForgetPasswordPage
