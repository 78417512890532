import { makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"

export const useEnterGameStyles = makeStyles((theme: Theme) => createStyles({
  dialogBox: {
    height: `calc(100vh - ${theme.spacing(20)}px)`,
    margin: theme.spacing(10),
    maxWidth: "unset",
    width: "66%",
  },
  startGameButtno: {
    margin: "auto",
  },
  section: {
    display: "flex",
  },
  img: {
    display: "block",
    margin: theme.spacing(2.5, "auto"),
    width: "50%"
  }
}))
