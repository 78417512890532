import { makeStyles } from "@material-ui/core"
import { createStyles, Theme } from "@material-ui/core/styles"

export const useBox3dGameStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  },
  wrapperItem: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    minWidth: "392px",
  },
  bottomWrapperItem: {
    flex: 1,
  },
  checkboxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    cursor: "pointer",
  },
  labelDisabled: {
    pointerEvents: "none",
  }
}))
