import {gql} from '@apollo/client'

export const CREATE_EXAM_MUTATION = gql`
  mutation createExam($input: ExamInput!){
    mutation: createExam(input: {exam: $input}) {
      exam {examinationId}
    }
  }
`

export const UPDATE_EXAM_MUTATION = gql`
  mutation updateExam($input: UpdateExamInput!){
    mutation: updateExam(input: $input){
      clientMutationId
    }
  }
`