import React, { FC, useEffect } from "react"
import { Box, TableBody, TableHead, Typography } from "@material-ui/core"
import { TENANTS_LIST_QUERY } from "./TenantsList.query"
import useRelayConnection from "../../../hooks/useRelayConnection"
import { TenantItemNode } from "./TenantsList.types"
import { usePaginationParams } from "../../../hooks/useQueryParams"
import TableWithPagination from "../../common/tableWithPagination/TableWithPagination.component"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TenantsListActions from "../tenantsListActions/TenantsListActions.component"
import getDateStringFromIsoFormat from "../../../utils/getDateStringFromIsoFormat"
import { useTenantsListStyles } from "./TenantsList.styles"
import AddTenantPopup from "../addTenantPopup/AddTenant.component"
import { useQueryParam, StringParam } from "use-query-params";
import TableCellWithFilter from "../../common/tableCellWithFilter/TableCellWithFilter.component"

interface TenantsListProps {
  isOpenAddTenantPopup: boolean;
  onCloseAddTenantPopup(): void;
}

const TenantsList: FC<TenantsListProps> = ({isOpenAddTenantPopup, onCloseAddTenantPopup}) => {
  const classes = useTenantsListStyles()
  const [nameFilter, setNameFilter] = useQueryParam("name", StringParam)

  const filters = nameFilter
    ? {
      name: nameFilter ? { likeInsensitive: nameFilter } : undefined,
    }
    : undefined

  const {tableProps, refetch, loading} = useRelayConnection<TenantItemNode>(TENANTS_LIST_QUERY, {
    variables: {
      filter: filters,
      orderBy: ['CREATED_AT_DESC']
    },
    pagination: usePaginationParams()
  })

  useEffect(() => {
    refetch()
  }, [nameFilter])

  return (
    <Box>
      <AddTenantPopup
        open={isOpenAddTenantPopup}
        refetch={refetch}
        onClose={onCloseAddTenantPopup}
      />

      <TableWithPagination {...tableProps}>
        <TableHead>
          <TableRow>
            <TableCellWithFilter
              defaultValue={nameFilter}
              label="Nazwa podmiotu"
              disabled={loading}
              onFilterSubmit={(val) => setNameFilter(val)}
            >
              <Typography className={classes.fieldName}>Nazwa</Typography>
            </TableCellWithFilter>
            <TableCell>
              <Typography className={classes.fieldName}>Data utworzenia</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.fieldName}>Liczba użytkowników</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography className={classes.fieldName}>Akcje</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <>
            { tableProps.dataSource.map((dataItem: TenantItemNode) => (
              <TableRow key={dataItem.name}>
                <TableCell>
                  {dataItem.name}
                </TableCell>

                <TableCell>
                  {getDateStringFromIsoFormat(dataItem.createdAt)}
                </TableCell>

                <TableCell>
                  {dataItem.membersCount}
                </TableCell>

                <TableCell align="right">
                  <TenantsListActions
                    tenant={dataItem}
                    refetch={refetch}
                  />
                </TableCell>
              </TableRow>
            ))}
          </>
        </TableBody>
      </TableWithPagination>
    </Box>
  )
}

export default TenantsList
