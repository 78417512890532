import React, {FC} from "react"
import { Box, Typography, useTheme } from "@material-ui/core"

interface GlobalFormErrorMessageProps {
  message: string;
}

const GlobalFormErrorMessage: FC<GlobalFormErrorMessageProps> = ({message}) => {
  const  theme = useTheme()
  return (
    <Box
      mb={3}
      p={2}
      border={`1px solid ${theme.palette.error.main}`}
      borderRadius={theme.spacing(1)}
    >
      <Typography color="error" className="break-spaces">
        {message}
      </Typography>
    </Box>

  )
}

export default GlobalFormErrorMessage
