import React, { FC } from "react"
import ErrorPageCard from "../../components/errorPageCard/ErrorPageCard.component"

const Error403Page: FC = () => {
  return (
    <ErrorPageCard
      title="403 - Brak uprawnień"
      description="Nie masz uprawnień, by wejść na tą stronę."
    />
  )
}

export default Error403Page