import {makeStyles} from "@material-ui/core"
import {createStyles} from "@material-ui/core/styles"

export const useDifferenceGameImageStyles = makeStyles(() => createStyles({
  svg: {
    display: "block",
    cursor: "pointer",
    "& *": {
      pointerEvents: "none"
    },
  }
}))
