import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useReportsStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[8],
      padding: theme.spacing(4),
    },
    title: {
      ...theme.typography.body1,
      fontWeight: "bold",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
    }
  }),
)