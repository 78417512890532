import React, { FC, useRef, useEffect, useMemo } from "react"
import { useDifferenceGameImageStyles } from "./DifferanceGameImage.styles"
import { useTheme } from "@material-ui/core"

import { ReactComponent as Level1Asset1 } from "../assets/level1/v1.svg"
import { ReactComponent as Level1Asset2 } from "../assets/level1/v2.svg"
import { ReactComponent as Level1Asset3 } from "../assets/level1/v3.svg"

import { ReactComponent as Level2Asset1 } from "../assets/level2/v1.svg"
import { ReactComponent as Level2Asset2 } from "../assets/level2/v2.svg"
import { ReactComponent as Level2Asset3 } from "../assets/level2/v3.svg"

import { ReactComponent as Level3Asset1 } from "../assets/level3/v1.svg"
import { ReactComponent as Level3Asset2 } from "../assets/level3/v2.svg"
import { ReactComponent as Level3Asset3 } from "../assets/level3/v3.svg"

import { ReactComponent as Level4Asset1 } from "../assets/level4/v1.svg"
import { ReactComponent as Level4Asset2 } from "../assets/level4/v2.svg"
import { ReactComponent as Level4Asset3 } from "../assets/level4/v3.svg"

import { ReactComponent as Level5Asset1 } from "../assets/level5/v1.svg"
import { ReactComponent as Level5Asset2 } from "../assets/level5/v2.svg"
import { ReactComponent as Level5Asset3 } from "../assets/level5/v3.svg"

import { ReactComponent as Level6Asset1 } from "../assets/level6/v1.svg"
import { ReactComponent as Level6Asset2 } from "../assets/level6/v2.svg"
import { ReactComponent as Level6Asset3 } from "../assets/level6/v3.svg"

interface DifferenceGameImageProps {
  level: number;
  disabled: boolean;
  onImageClick(onDiffClick: boolean): void;
}

const DIFF_CIRCLE_DATA_NAME = "diffCircle"

const DifferenceGameImage: FC<DifferenceGameImageProps> = (
  {
    level,
    disabled,
    onImageClick,
  }
) => {
  const svgImage = useRef<SVGSVGElement | null>(null)
  const classes = useDifferenceGameImageStyles()
  const theme = useTheme()

  const random = useMemo(() => Math.ceil(Math.random() * 3), [])

  const handleOnClick = (e: React.MouseEvent<SVGSVGElement | SVGCircleElement>) => {
    if (!(e.target instanceof SVGSVGElement || e.target instanceof SVGCircleElement)) {
      return
    } else {
      const el = e.target

      if (el && el.dataset && el.dataset.name === DIFF_CIRCLE_DATA_NAME && !el.dataset.clicked) {
        el.dataset.clicked = "true"

        el.style.strokeWidth = "3px"
        el.style.stroke = theme.palette.primary.main
        onImageClick(true)
      } else {
        onImageClick(false)
      }
    }
  }

  useEffect(() => {
    if (svgImage && svgImage.current) {
      const diffCircles: NodeListOf<SVGCircleElement> = svgImage.current.querySelectorAll(`[data-name*=${DIFF_CIRCLE_DATA_NAME}]`)

      diffCircles.forEach((diffCircle: SVGCircleElement) => {
        diffCircle.style.pointerEvents = "auto"
        diffCircle.style.fill = "transparent"
        diffCircle.style.stroke = "transparent"
      })
    }
  }, [svgImage])

  const svgImageProps = {
    ref: svgImage,
    className: classes.svg,
    onClick: disabled ? undefined : handleOnClick
  }

  switch(level) {
    case 1: switch (random) {
      case 1: return <Level1Asset1 {...svgImageProps}/>
      case 2: return <Level1Asset2 {...svgImageProps}/>
      case 3: return <Level1Asset3 {...svgImageProps}/>
      default: return null
    }
    case 2: switch (random) {
      case 1: return <Level2Asset1 {...svgImageProps}/>
      case 2: return <Level2Asset2 {...svgImageProps}/>
      case 3: return <Level2Asset3 {...svgImageProps}/>
      default: return null
    }
    case 3: switch (random) {
      case 1: return <Level3Asset1 {...svgImageProps}/>
      case 2: return <Level3Asset2 {...svgImageProps}/>
      case 3: return <Level3Asset3 {...svgImageProps}/>
      default: return null
    }
    case 4: switch (random) {
      case 1: return <Level4Asset1 {...svgImageProps}/>
      case 2: return <Level4Asset2 {...svgImageProps}/>
      case 3: return <Level4Asset3 {...svgImageProps}/>
      default: return null
    }
    case 5: switch (random) {
      case 1: return <Level5Asset1 {...svgImageProps}/>
      case 2: return <Level5Asset2 {...svgImageProps}/>
      case 3: return <Level5Asset3 {...svgImageProps}/>
      default: return null
    }
    case 6: switch (random) {
      case 1: return <Level6Asset1 {...svgImageProps}/>
      case 2: return <Level6Asset2 {...svgImageProps}/>
      case 3: return <Level6Asset3 {...svgImageProps}/>
      default: return null
    }
    default: return null
  }
}

export default DifferenceGameImage;