import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useTenantsListActionsStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1)
    },
    icon: {
      ...theme.typography.h5,
      marginRight: theme.spacing(.5)
    }
  }),
)