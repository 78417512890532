import React, {FC} from "react"
import { Box, Button, ButtonProps, Typography } from "@material-ui/core"
import { useAdminPageStyles } from "./AdminPageCard.styles"
import AddIcon from '@material-ui/icons/Add'

interface AdminPageCardProps {
  title: string;
  actionButtonText?: string;
  actionButtonProps?: ButtonProps;
}

const AdminPageCard: FC<AdminPageCardProps> = (
  {
    title,
    actionButtonText,
    actionButtonProps,
    children
  }
) => {
  const classes = useAdminPageStyles()

  return (
    <Box mb={2}>
      <Box>
        <Box className={classes.titleBox}>
          <Typography variant={"h2"}>
            { title }
          </Typography>

          { (actionButtonText && actionButtonProps) &&
            <Button
              variant="contained"
              color={"primary"}
              size={"large"}
              {...actionButtonProps}
            >
              <AddIcon className={classes.icon}/>
              { actionButtonText }
            </Button>
          }
        </Box>

        <Box mt={4}>
          { children }
        </Box>
      </Box>
    </Box>
  )
}

export default AdminPageCard
