import { makeStyles } from "@material-ui/core"
import { createStyles } from "@material-ui/core/styles"
import { Theme as DefaultTheme } from "@material-ui/core/styles/createTheme";

export const useMemoryGameStyles = makeStyles<DefaultTheme>(() => createStyles({
  cardsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
}))
