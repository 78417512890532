import {QuestionsType} from "./QuestionsCard.component";

export const getQuestionCardLabel = (questionCardType: QuestionsType) => {
  switch (questionCardType) {
    case QuestionsType.ATTENTION: return 'Uwaga'
    case QuestionsType.MEMORY: return "Pamięć"
    case QuestionsType.LANGUAGE: return "Język"
    case QuestionsType.FLUENCY: return "Fluencja"
    case QuestionsType.VISUOSPATIAL_ABILITIES: return "Funkcje wzrokowo-przestrzenne"
  }
}

export const sumArray = (arr: number[]) => {
  const filteredArray: number[] = arr.filter(number => !isNaN(number))
  return filteredArray.reduce((a,b) => a + b, 0)
}