import React, {FC, useEffect, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import EntryExitFormResultCard
  from "../components/entryExitFormResult/entryExitFormResultCard/EntryExitFormResultCard.component";
import SummaryScore from "../components/entryExitFormResult/summaryScore/SummaryScore.component";
import QuestionsCard from "../components/entryExitFormResult/questionsCard/QuestionsCard.component";
import {questionsData} from "../components/entryExitFormResult/questionsCard/QuestionsCardData.utils";
import {RouteComponentProps, useHistory} from "react-router"
import {useParams} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/client'
import {
  CREATE_EXAM_MUTATION,
  UPDATE_EXAM_MUTATION
} from "../components/entryExitFormResult/createFormButtons/CreateForm.mutation";
import SubmitFormButtons from "../components/entryExitFormResult/submitFormButtons/SubmitFormButtons.component";
import {RoutePath} from "../routes/Routes.types";
import { isIdValidated } from "../utils/validateId"
import LoaderBox from "../components/common/loaderBox/LoaderBox.component"
import { FORM_URL_TYPE} from "../components/entryExitFormResult/createFormButtons/CreateForm.types";
import {EXAM_QUERY} from "../components/entryExitFormResult/createFormButtons/CreateForm.query";
import Popup from "../components/common/popup/Popup.component";
import {Box, Typography} from "@material-ui/core";
import GlobalSuccessSnackbar from "../components/formItems/globalSuccessSnackbar/GlobalSuccessSnackbar";
import { yupResolver } from "@hookform/resolvers/yup"
import {
  entryExitFormResultCardValidationSchema
} from "../components/entryExitFormResult/entryExitFormResultCard/EntryExitFormResultCard.validation"
import {scrollToElementId} from "../utils/scrollTo";

type UserExamQuery = {
  user:
    {
      userId: string,
      firstName: string,
      lastName: string,
      examsList: Array<{answers: number[], examinationId: string, type: string}>
    }
}

export interface EntryExitFormResultPageProps {
  userId: string;
  formType: FORM_URL_TYPE
}

const EntryExitFormResultPage: FC<RouteComponentProps<EntryExitFormResultPageProps>> = () => {
  const {userId, formType} = useParams<EntryExitFormResultPageProps>()
  const {push} = useHistory()

  const form = useForm({
    resolver: yupResolver(entryExitFormResultCardValidationSchema),
    shouldUseNativeValidation: false,
    mode: "all",
  });

  const {errors} = form.formState

  useEffect(() => {
    if(errors?.answers){
      const filteredErrors = errors.answers.filter((answer: {name: string, ref: string}) => answer.ref)
      scrollToElementId(filteredErrors[0].ref.name, -150)
    }
  }, [errors])

  const [createForm, {loading: createFormLoading}] = useMutation(CREATE_EXAM_MUTATION)
  const [updateForm, {loading: updateFormLoading}] = useMutation(UPDATE_EXAM_MUTATION)

  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState<boolean>(false)
  const [isCreateFormSucceed, setIsCreateFormSucceed] = useState<boolean>(false)

  const saveFormLoading = createFormLoading || updateFormLoading

  const {data, refetch, loading} = useQuery<UserExamQuery>(EXAM_QUERY,
    {
      variables: {userId}
    }
  )

  if (!userId
    || !formType
    || !isIdValidated(userId)
    || (data && !data.user)
    || !Object.values(FORM_URL_TYPE).includes(formType)
  ) {
    push(RoutePath.ERROR_404)
  }

  const formTypeFromUrl = Object.keys(FORM_URL_TYPE).find(url => FORM_URL_TYPE[url as keyof typeof FORM_URL_TYPE] === formType)
  const defaultExam = data?.user.examsList.find(exam => FORM_URL_TYPE[exam.type as keyof typeof FORM_URL_TYPE] === formType)
  const isExamUpdateForm = !!defaultExam
  const examId = defaultExam?.examinationId
  const userFullName = data?.user ? `${ data?.user.firstName } ${ data?.user.lastName }` : '';

  const handleSubmit = form.handleSubmit(async (values) => {
    setIsSuccessPopupOpen(false)
    setIsCreateFormSucceed(false)

    try {
      if(isExamUpdateForm){
        await updateForm({
          variables: {
            input: {
              patch: {
                answers: values.answers,
              },
              examinationId: examId
            }
          }
        })
        setIsCreateFormSucceed(true)
        await refetch()
      } else {
          await createForm({
            variables: {
              input: {
                type: formTypeFromUrl,
                answers: values.answers,
                userId
              }
            }
          })
          setIsSuccessPopupOpen(true)
        await refetch()
      }
    } catch (err) {
      console.error(err)
    }
  })

  if (loading) {
    return <LoaderBox/>
  }

  return (
    <EntryExitFormResultCard title={`Wyniki formularza użytkownika ${ userFullName }`}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          {questionsData.map((question, index) => (
            <QuestionsCard
              key={index}
              image={question.image}
              questionType={question.questionType}
              name={`answers[${index}]`}
              loading={saveFormLoading}
              maxPoints={question.maxPoints}
              defaultValue={isExamUpdateForm ? defaultExam.answers[index] : ''}
            />
          ))}

          <SummaryScore />

          <SubmitFormButtons
            loading={saveFormLoading}
            isEditMode={isExamUpdateForm}
          />
        </form>
      </FormProvider>

      <Popup
        open={isSuccessPopupOpen}
        title={"Poprawnie zapisano dane"}
        actionButtonText={"Wróć do listy użytkowników"}
        actionButtonProps={{
          onClick: () => push(RoutePath.USERS),
        }}
        onClose={() => setIsSuccessPopupOpen(false)}
      >
        <Box>
          <Typography className={"break-spaces"}>
            Do osoby badanej został wysłany e-mail z linkiem do ustawienia hasła
          </Typography>
        </Box>
      </Popup>

      <GlobalSuccessSnackbar
        isSuccess={isCreateFormSucceed}
        successMessage={"Poprawnie zapisano dane"}
      />
    </EntryExitFormResultCard>
  )
}

export default EntryExitFormResultPage;