import { createTheme, CssBaseline, MuiThemeProvider, ThemeOptions } from "@material-ui/core";
import { createContext, Dispatch, FC, useReducer } from "react";
import theme from "../themes/theme";
import { defaultPalette, highContrastPalette } from "../themes/palette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import {
  ActionType,
  CustomTheme,
  CustomThemeActionTypes,
  PaletteEnum,
  themeKey,
  TypographyEnum,
} from "./customTheme.types";
import {
  bigTypography,
  mediumTypography,
  smallTypography,
} from "../themes/typography";
import { highContrastComponents } from "../themes/overrides";

const getTypography = (typography?: TypographyEnum | null): TypographyOptions => {
  switch(typography) {
    case TypographyEnum.SMALL:
      return smallTypography;
    case TypographyEnum.MEDIUM:
      return mediumTypography;
    case TypographyEnum.BIG:
      return bigTypography;
    default: 
      return smallTypography
  }
}

const initialSettings: CustomTheme | null = localStorage.getItem(themeKey)
  ? JSON.parse(localStorage.getItem(themeKey)!)
  : null;

export const CustomThemeContext = createContext<{
  settings: CustomTheme | null;
  dispatch: Dispatch<CustomThemeActionTypes>;
}>({
  settings: initialSettings,
  dispatch: () => {},
});

export const CustomThemeProvider: FC = ({ children }) => {
  const reducer = (
    state: CustomTheme | null,
    action: CustomThemeActionTypes
  ): CustomTheme | null => {
    const { type, payload } = action;

    switch (type) {
      case ActionType.SWITCH_TYPOGRAPHY:
        const typographyItem = { ...state, typography: payload as TypographyEnum }
        localStorage.setItem(
          themeKey,
          JSON.stringify(typographyItem)
        );
        return typographyItem

      case ActionType.SWITCH_PALETTE:
        const paletteItem = { ...state, palette: payload as PaletteEnum }
        localStorage.setItem(
          themeKey,
          JSON.stringify(paletteItem)
        )
        return paletteItem

      default: 
        return state
    }
  };

  const [settings, dispatch] = useReducer(reducer, initialSettings);

  const buildTheme = (): ThemeOptions => {
    const typography = getTypography(settings?.typography)
    const palette = settings?.palette === PaletteEnum.HIGH_CONTRAST
      ? highContrastPalette
      : defaultPalette

    return {
      ...theme,
      overrides: settings?.palette === PaletteEnum.HIGH_CONTRAST
        ? highContrastComponents
        : {},
      typography,
      palette
    }
  };

  const customTheme = createTheme(buildTheme());

  return (
    <CustomThemeContext.Provider value={{ settings, dispatch }}>
      <MuiThemeProvider theme={customTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export const ThemeConsumer = CustomThemeContext.Consumer;
