import React, {FC, useState} from "react"
import { Avatar, Box, ButtonBase, Menu, MenuItem, Typography } from "@material-ui/core"
import {useUserMenuStyles} from "./UserMenu.styles"
import UserIcon from '@material-ui/icons/Person';
import {useSelector} from "react-redux"
import { selectUser } from "../../../store/user/user.selectors"
import { useHistory } from "react-router"
import EditUserPopup from "../../user/editUserPopup/EditUser.component"
import { useAppDispatch } from "../../../hooks/storeHooks"
import { clearSession } from "../../../store/session/session.slice"
import { selectAuthenticated } from "../../../store/session/session.selectors"

interface UserMenuProps {}

const UserMenu: FC<UserMenuProps> = () => {
  const dispatch = useAppDispatch()
  const classes = useUserMenuStyles()
  const {push} = useHistory()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openUserMenu = Boolean(anchorEl)
  const [openUserDataPopup, setOpenUserDataPopup] = useState<boolean>(false)

  const authenticated = useSelector(selectAuthenticated)
  const userData = useSelector(selectUser)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logOut = () => {
    handleClose()
    dispatch(clearSession())
    push('/login')
  }

  const openMyDataModal = () => {
    handleClose()
    if (userData) {
      setOpenUserDataPopup(true)
    }
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.userText}>
        { (authenticated && userData) ? `${userData.firstName} ${userData.lastName}` : '' }
      </Typography>

      <ButtonBase
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
      >
        <Avatar className={classes.avatar}>
          <UserIcon/>
        </Avatar>
      </ButtonBase>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoFocus={false}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={openUserMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          list: classes.listRoot,
        }}
      >
        <MenuItem onClick={openMyDataModal}>
          <Typography>
            Moje dane
          </Typography>
        </MenuItem>

        <MenuItem onClick={logOut}>
          <Typography>
            Wyloguj
          </Typography>
        </MenuItem>
      </Menu>

      <EditUserPopup
        user={userData!}
        open={openUserDataPopup}
        onClose={() => setOpenUserDataPopup(false)}
      />
    </Box>
  )
}

export default UserMenu
