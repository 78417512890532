import React, {FC} from "react"
import {Box} from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { useForm, FormProvider } from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import { TenantItemNode } from "../tenantsList/TenantsList.types"

interface PreviewTenantPopupProps {
  tenant: TenantItemNode;
  open: boolean;
  onClose(): void;
}

const PreviewTenantPopup: FC<PreviewTenantPopupProps> = ({tenant, open, onClose}) => {
  const form = useForm({
    mode: "all",
    defaultValues: {
      name: tenant.name,
    }
  })

  return (
    <Popup
      open={open}
      onClose={onClose}
      title="Podgląd podmiotu"
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <TextFieldController
              name="name"
              label={'Nazwa'}
              isRequired
              disabled={true}
            />
          </form>
        </FormProvider>
      </Box>
    </Popup>
  )
}

export default PreviewTenantPopup
