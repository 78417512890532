import React, { FC } from "react"
import { Box, Typography } from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { useEnterGameStyles } from "./EnterGameModal.styles"
import { GameQueryResponse } from "../../../pages/GamePage"
import {getTutorialGifForGame} from "./EnterGameModal.utils";
import {GAME_TYPE} from "../Game.types";

interface EnterGamePopupProps {
  gameEnum: GAME_TYPE,
  open: boolean;
  gameType: GameQueryResponse["game"]["definition"]["gameType"];
  onStart?(): void;
}

const EnterGamePopup: FC<EnterGamePopupProps> = (
  {
    gameEnum,
    open,
    gameType,
    onStart,
  }
) => {
  const classes = useEnterGameStyles()

  return (
    <Popup
      open={open}
      title={`Zasady gry ${gameType.name}`}
      actionButtonText={"Rozpocznij grę"}
      actionButtonProps={{
        onClick: onStart ? () => onStart() : undefined,
        className: classes.startGameButtno,
        size: "large",
      }}
      classes={{paper: classes.dialogBox}}
    >
      <Box>
        <Typography className={`${classes.section} break-spaces}`}>
          { gameType.description }
        </Typography>
        <img
          src={getTutorialGifForGame(gameEnum)}
          className={classes.img}
          alt={`Zasady gry ${gameType.name}`}
        />
      </Box>
    </Popup>
  )
}

export default EnterGamePopup
