import { RoutePath } from "../Routes.types"
import { RouteListItem } from "./RouteList.types"
import { UserRole } from "../../components/user/userRole/UserRole.types"

import ReportsPage from "../../pages/ReportsPage"
import TenantsPage from "../../pages/TenantsPage"
import UsersPage from "../../pages/UsersPage"
import UserGameStatusPage from "../../pages/UserGamesStatusPage"
import EntryExitFormResultPage from "../../pages/EntryExitFormResultPage";
import SettingsPage from "../../pages/SettingsPage"

import BarChartIcon from "@material-ui/icons/BarChart"
import PeopleIcon from "@material-ui/icons/People"
import Person from "@material-ui/icons/Person"
import Settings from "@material-ui/icons/Settings"

const adminRoutes: RouteListItem[] = [
  {
    path: RoutePath.TENANTS,
    component: TenantsPage,
    title: "Podmioty",
    permission: [UserRole.ADMIN],
    icon: PeopleIcon,
  },
  {
    path: RoutePath.USERS,
    component: UsersPage,
    title: "Użytkownicy",
    permission: [UserRole.ADMIN, UserRole.LEADER],
    icon: Person,
  },
  {
    path: RoutePath.REPORTS,
    component: ReportsPage,
    title: "Raporty",
    permission: [UserRole.ADMIN, UserRole.LEADER],
    icon: BarChartIcon,
  },
  {
    path: RoutePath.USER_GAMES_STATUS,
    component: UserGameStatusPage,
    title: "Karta użytkownika",
    permission: [UserRole.LEADER],
  },
  {
    path: RoutePath.ENTRY_EXIT_FORM_RESULT,
    component: EntryExitFormResultPage,
    title: "Wyniki formularza wejścia",
    permission: [UserRole.LEADER],
  },
  {
    path: RoutePath.SETTINGS,
    component: SettingsPage,
    title: "Ustawienia",
    permission: [UserRole.ADMIN],
    icon: Settings,
  }

]

export default adminRoutes