import { SagaIterator } from "redux-saga"
import { fork, take } from "redux-saga/effects"
import {LOCATION_CHANGE} from "connected-react-router"
import userSaga from "./user/user.saga"
import sessionSaga from "./session/session.saga"

export default function* rootSaga(): SagaIterator {
  yield take(LOCATION_CHANGE)
  yield fork(userSaga)
  yield fork(sessionSaga)
}
