import React, { FC } from "react"
import clsx from "clsx"
import { useBox3dStyles } from "./Box3d.styles"
import { Box3dOption } from "../Box3dGame.types"
import GameItem from "../../gameItem/GameItem"
import getImageNumber from "../Box3dGame.utils"

interface Box3dProps {
  option: Box3dOption;
}

const Box3d: FC<Box3dProps> = ({option}) => {
  const classes = useBox3dStyles()

  const gameItemProps = {
    size: 92,
    assetsNumber: 1,
    backgroundSize: "cover"
  }

  return (
    <div className={classes.scene}>
      <div className={classes.box}>
        <div className={clsx(classes.face, classes.front)}>
          <div style={{ transform: `rotate(${option.front.rotation}deg)` }}>
            <GameItem
              type={getImageNumber[option.front.type]}
              {...gameItemProps}
            />
          </div>
        </div>

        <div className={clsx(classes.face, classes.ceil)}>
          <div style={{ transform: `rotate(${option.ceil.rotation}deg)` }}>
            <GameItem
              type={getImageNumber[option.ceil.type]}
              {...gameItemProps}
            />
          </div>
        </div>

        <div className={clsx(classes.face, classes.side)}>
          <div style={{ transform: `rotate(${option.side.rotation}deg)` }}>
            <GameItem
              type={getImageNumber[option.side.type]}
              {...gameItemProps}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Box3d
