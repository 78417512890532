import {RootState} from "../store.types"
import { SessionState } from "./session.types"

export const selectSessionState: ((state: RootState) => SessionState["sessionState"]) = ({session}) => session.sessionState

export const selectToken: ((state: RootState) => string | null) = ({session}) => {
  return session.sessionState ? session.sessionState.sessionToken : null
}

export const selectAuthenticated: ((state: RootState) => boolean) = ({session}) => {
  return !!session.sessionState
}

export const selectSessionExpirationDate: ((state: RootState) => Date|null) = ({session}) => {
  return session.sessionState ? session.sessionState.exp : null
}

export const selectSessionUserId: ((state: RootState) => string|null) = ({session}) => {
  return session.sessionState ? session.sessionState.user_id : null
}