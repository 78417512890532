import { Overrides } from "@material-ui/core/styles/overrides";

const black = "#000";
const yellow = "#FFC000";

export const highContrastComponents: Overrides = {
  MuiOutlinedInput: {
    root: {
      "& $notchedOutline, &:hover $notchedOutline, &$focused $notchedOutline, &.disabled":
        {
          borderColor: black,
        },
    },
  },
  MuiInputLabel: {
    root: {
      "&$focused": {
        color: black,
      },
      color: black,
    },
  },
  MuiTableCell: {
    root: {
      borderBottomColor: black,
    },
  },
  MuiTypography: {
    h2: {
      color: yellow,
    },
  },
  MuiButton: {
    root: {
      backgroundColor: black,
    },
    outlinedSecondary: {
      "&:hover": {
        backgroundColor: black,
      },
    },
    containedPrimary: {
      "&:hover": {
        backgroundColor: yellow,
      },
    },
    outlinedPrimary: {
      "&:hover": {
        backgroundColor: black
      }
    },
    text: {
      backgroundColor: yellow
    }
  },
};
