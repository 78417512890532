import React, {FC, useEffect} from "react"
import { Box, Typography } from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { GameQueryResponse } from "../../../pages/GamePage"
import { useEndGameStyles } from "./EndGameModal.styles"
import {useParams} from "react-router";
import {fireConfetti} from './EndGameModal.utils'

interface EndGamePopupProps {
  open: boolean;
  won: boolean;
  level: number;
  gameType: GameQueryResponse["game"]["definition"]["gameType"];
  resultDescription: string;
  goToHomepage?(): void;
  onClose(): void;
}

type UrlParamsType = {
  notCompleted?: string
}

const EndGamePopup: FC<EndGamePopupProps> = (
  {
    open,
    gameType,
    won,
    level,
    resultDescription,
    goToHomepage,
    onClose,
  }
) => {
  const classes = useEndGameStyles()
  const { notCompleted } = useParams<UrlParamsType>()

  useEffect(() => {
    if(level === 6
      && won
      && notCompleted
    ) {
      fireConfetti(400)
      fireConfetti(700)
      fireConfetti(1000)
    }
  }, [open])

  return (
    <Popup
      open={open}
      title={`Koniec gry ${gameType.name}`}
      actionButtonText={"Wróć do listy gier"}
      actionButtonProps={{
        onClick: goToHomepage ? () => goToHomepage() : undefined,
      }}
      onClose={onClose}
    >
      <Box>
        <Typography
          variant={"h4"}
          className={classes.resultTitle}
        >
          {won ? "GRATULACJE!" : "Mało zabrakło"}
        </Typography>

        <Typography className={classes.resultDescription}>
          { resultDescription }
        </Typography>
      </Box>
    </Popup>
  )
}

export default EndGamePopup
