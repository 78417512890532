import React, {FC, useEffect, Fragment} from "react"
import {useHistory, useLocation} from "react-router"
import { getHomeRouteForRole } from "../components/user/userRole/UserRole.utils"
import { useSelector } from "react-redux"
import { selectUserRole } from "../store/user/user.selectors"

interface RoutesControllerProps {}

const RoutesController: FC<RoutesControllerProps> = ({children}) => {
  const { pathname } = useLocation()
  const { replace, push } = useHistory()
  const userRole = useSelector(selectUserRole)

  useEffect(() => {
    // redirect to homepage from base url
    if (["/", ""].includes(pathname)) {
      push(userRole ? getHomeRouteForRole(userRole) : "/login")
    }
    // e/o redirect to homepage from base url

    // remove trailing slash from url - it causes bugs with push url method
    if (!["/", ""].includes(pathname) && pathname.substring(pathname.length - 1) === "/") {
      replace(pathname.slice(0, -1))
    }
    // e/o remove trailing slash from url

    // scroll to top
    window.scrollTo(0, 0)
    // e/o scroll to top
  }, [pathname])

  return <Fragment>{children}</Fragment>
}

export default RoutesController
