import React from "react";
import { FC, useEffect, useState } from "react";

interface GameItemProps {
  size: number;
  type: number | null;
  assetsNumber?: number;
  backgroundSize?: string;
  onClick?: () => void;
}

const GameItem: FC<GameItemProps> = (
  {
    size,
    type,
    assetsNumber = 2,
    backgroundSize = "contain",
    onClick
  }
) => {
  const [background, setBackground] = useState<string | null>(null);

  useEffect(() => {
    if (null == type) {
      setBackground(null);
      return;
    }
    import(`./assets${assetsNumber}/${type}.png`)
      .then((module) => setBackground(module.default));
  }, [type, assetsNumber, setBackground]);

  return (
    <div
      onClick={onClick}
      style={{
        width: size,
        height: size,
        float: "left",
        backgroundImage: background ? `url(${background})` : undefined,
        backgroundSize: backgroundSize,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        cursor: onClick ? "pointer" : undefined,
      }}
    />
  );
}

export default GameItem;
