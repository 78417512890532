import React, { FC } from "react"
import { MenuItem } from "@material-ui/core"
import TextFieldController, {
  TextFieldControllerProps
} from "../../formItems/textFieldController/TextFieldController.component"
import { GAME_TYPE } from "../Game.types"
import { getGameLabel } from "../Game.utils"

interface GameSelectProps
  extends TextFieldControllerProps
{}

const GameSelect: FC<GameSelectProps> = (props) => {
  return (
    <TextFieldController
      select
      {...props}
    >
      { Object.values(GAME_TYPE).map(value => (
        <MenuItem
          key={value}
          value={value}
        >
          { getGameLabel(value) }
        </MenuItem>
      ))}
    </TextFieldController>
  )
}

export default GameSelect
