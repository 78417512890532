import { useCallback, useState } from "react"

interface useIntervalReturnType {
  start() : void;
  stop() : void;
}

const useInterval = (callback: () => void, timeout: number): useIntervalReturnType => {
  const [timer, setTimer] = useState<number>(0)

  const start = useCallback(() => {
    if (!timer) {
      setTimer(window.setInterval(() => {
        callback()
      }, timeout))
    }
  }, [timer, timeout, callback])

  const stop = useCallback(() => {
    if (timer > 0) {
      window.clearInterval(timer)
      setTimer(0)
    }
  }, [timer])

  return {start, stop}
}

export default useInterval
