import React, { FC } from 'react'
import { Box, Typography } from "@material-ui/core";
import { useUserGameCardStatsStyles } from "./UserGameCardStats.styles"

interface UserGameCardProps {
  gamesAmount: number;
  lastGame: string;
  unlocked: boolean;
}

const UserGameCard: FC<UserGameCardProps> = (
  {
    gamesAmount,
    lastGame,
    unlocked,
  }
) => {
  const classes = useUserGameCardStatsStyles()

  const convertDate = (date: string): string => {
    const slicedDate = date.slice(0,10)
    return slicedDate.split('-').reverse().join('.');
  }

  return ((gamesAmount && lastGame) || unlocked)
    ? (
      <Box className={classes.root}>
        <Typography
          component='p'
          variant='body2'
        >
          <Box className={classes.propertyWrapper}>
            <Typography
              component='dl'
              variant='body2'
              className={classes.propertyTitle}
            >
              Ostatnia gra: 
            </Typography>

            <Typography
              component='dd'
              variant='body2'
            >
              {lastGame ? convertDate(lastGame) : '-'}
            </Typography>
          </Box>
        </Typography>

        <Box className={classes.propertyWrapper}>
          <Typography
            component='dl'
            variant='body2'
            className={classes.propertyTitle}
          >
            Prób: 
          </Typography>
          <Typography
            component='dd'
            variant='body2'
          >
            {gamesAmount}
          </Typography>
        </Box>
      </Box>
    )
    : null
}

export default UserGameCard