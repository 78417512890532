import { Box } from "@material-ui/core"
import React, { FC, useEffect, useState } from "react"
import background from "../../../assets/images/background/game5.svg"
import { useBackgroundImage } from "../../../hooks/useBackgroundImage";
import { GameComponentProps } from "../../../pages/GamePage";
import { useGameCardStyles } from "../gameCard/GameCard.styles";
import GameItem from "../gameItem/GameItem";
import GameCard from "../gameCard/GameCard.component"
import clsx from "clsx"

const BubblesGame: FC<GameComponentProps<GameState>> = (
  {
    state,
    gameType,
    definition,
    startedAt,
    closedAt,
    attempts,
    onStart,
    onMove,
    onFinish,
  }
) => {
  useBackgroundImage(background);
  const classes = useGameCardStyles()

  const [bubbles, setBubbles] = useState<GameState>(state);
  const [timeLimitReached, setTimeLimitReached] = useState<boolean>(false);
  const [movesLeft, setMovesLeft] = useState<number>(definition.maxAttempts as number - attempts);

  const bubblesRemains = bubbles.reduce(
    (sum, row) => sum + row.filter((c) => c != null).length,
    0
  );

  const bubblesEliminated = definition.size - bubblesRemains;
  const ended = 0 === movesLeft || timeLimitReached || bubblesRemains === 0 || null != closedAt;
  const moveNumber = definition.maxAttempts as number - movesLeft;
  const won = bubblesEliminated >= definition.target;

  useEffect(() => {
    if (ended) {
      onFinish(bubblesEliminated, bubbles);
    }

    onMove(moveNumber, bubbles);
  }, [onMove, onFinish, bubbles, moveNumber, ended])

  const rowStyle = {
    width: 870,
    height: Math.floor(870 / Math.sqrt(definition.size || 1)),
    margin: "0 auto",
  }

  const shoot = (x: number, y: number) => () => {
    setMovesLeft((n) => n - 1);
    setBubbles(clearField(x, y));
  }

  const start = !startedAt ? () => onStart() : undefined;
  const finish = (!!startedAt && !ended) ? () => onFinish(bubblesEliminated, bubbles) : undefined;

  return (
    <GameCard
      gameType={gameType}
      definition={definition}
      startedAt={startedAt}
      closedAt={closedAt}
      won={won}
      ended={ended}
      onStart={start}
      onEnd={finish}
      onTimerEnd={() => setTimeLimitReached(true)}
      stats={[
        {
          label: 'Liczba kulek do zestrzelenia',
          value: `${Math.max(0, definition.target - bubblesEliminated)} z ${definition.target}`
        },
        {
          label: 'Liczba pozostałych ruchów',
          value: movesLeft
        },
      ]}
      resultDescription={`Zbiłeś ${bubblesEliminated} z ${definition.target} wymaganych kulek.`}
    >
      <Box
        className={clsx(classes.game, classes.main, !startedAt && classes.wrapperPlaceholder)}
        style={{transform: `rotate(${rotate(moveNumber, definition.level)}deg)`}}
      >
        {bubbles.map((row, rowIndex) => (
          <div key={rowIndex} style={rowStyle}>
            {row.map((cell, columnIndex) => (
              <GameItem
                key={columnIndex}
                size={rowStyle.height}
                type={cell}
                onClick={ended || !startedAt || null == cell ? undefined : shoot(rowIndex, columnIndex)}
              />
            ))}
          </div>
        ))}
      </Box>
    </GameCard>
  )
}

type GameState = Array<Array<number | null>>;

const clearField = (x: number, y: number) => (s: GameState) => {
  return [[x, y], [x, y - 1], [x + 1, y], [x, y + 1], [x - 1, y]].reduce((n, [i, j]): GameState => {
    if (i < 0 || j < 0 || i >= s.length || j >= s.length || n[i][j] !== s[x][y]) return n;
    n[x] = [...n[x]];
    n[x][y] = null;

    return i === x && j === y ? n : clearField(i, j)(n);
  }, [...s])
};

const rotate = (move: number, level: number): number => {
  const steps = [0, 90, 180, 90];
  const n = level % 2 ? -1 : 1;
  const m = Math.floor(move / steps.length) % 2 ? -1 : 1;

  return steps[move % steps.length] * n * m;
}

export default BubblesGame
