import { createStyles, makeStyles } from "@material-ui/core"
import { Theme } from "@material-ui/core/styles"

export const useAdminPageStyles = makeStyles((theme: Theme) => createStyles({
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}))
