import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const useErrorPageStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
  },
  contentWrapper: {
    width: "560px",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 6),
    boxShadow: theme.shadows[8],
    height: "fit-content",
    marginBottom: theme.spacing(30),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  description: {
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.grey["500"]}`,
    whiteSpace: "break-spaces",
  },
  button: {
    marginBottom: theme.spacing(2),
  }
}))
