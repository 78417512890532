import { ErrorResponse } from "@apollo/client/link/error"

export const errorMessagesMap: Record<string, string> = {
  "Tenant still contains active users": "Ten podmiot posiada aktywnych użytkowników. W celu usunięcia podmiotu, usuń najpierw wszytkich jego użytkowników."
}

export const getMessageErrorTranslation: (message: string) => string = (message: string) => {
  return errorMessagesMap[message] || ""
}

export const getExceptionMessageErrorTranslation = (error: ErrorResponse): string => {
  if (error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].message) {
    return getMessageErrorTranslation(error.graphQLErrors[0].message)
  }
  return ""
}