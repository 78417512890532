import React, { FC, useEffect } from "react"
import {Box} from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { useForm, FormProvider } from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import { useMutation } from "@apollo/client";
import { EDIT_USER } from "./EditUser.mutation"
import { UserItemNode } from "../../../store/user/user.types"
import { useDispatch } from "react-redux"
import { setUserData } from "../../../store/user/user.slice"
import UserRoleSelect from "../userRole/UserRoleSelect.component"
import TenantsSelect from "../../tenants/tenantsSelect/TenantsSelect.component"
import { UserRole } from "../userRole/UserRole.types"

interface EditUserPopupProps {
  user: UserItemNode | null;
  open: boolean;
  onClose(): void;
  refetch?(): void;
}

const EditUserPopup: FC<EditUserPopupProps> = ({user, open, refetch, onClose}) => {
  const [editUser, {loading}] = useMutation(EDIT_USER)
  const dispatch = useDispatch()

  const isTenantFieldRequired = user?.role && user.role !== UserRole.ADMIN

  const form = useForm({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      role: user?.role,
      tenantId: user?.tenant?.tenantId,
    }
  })

  useEffect(() => {
    if (open) {
      form.setValue("firstName", user?.firstName)
      form.setValue("lastName", user?.lastName)
    }
  }, [open])

  const handleClose = () => {
    onClose()
    form.reset()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    try {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
      }

      await editUser({
        variables: {
          input: {
            userId: user?.userId,
            patch: data
          }
        }
      })

      if (refetch) {
        await refetch()
      } else {
        dispatch(setUserData(data))
      }
      handleClose()
    } catch (e) {
      console.error(e)
    }
  })

  if (!user) return null

  return (
    <Popup
      open={open}
      onClose={handleClose}
      title={refetch ? "Edytuj użytkownika" : "Moje dane"}
      actionButtonText={"Edytuj"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <TextFieldController
              name="firstName"
              label={'Imię'}
              isRequired
              mb
              disabled={loading}
            />

            <TextFieldController
              name="lastName"
              label={'Nazwisko'}
              isRequired
              mb
              disabled={loading}
            />

            <TextFieldController
              name="email"
              label={'E-mail'}
              isRequired
              mb
              disabled={true}
            />

            <UserRoleSelect
              name="role"
              label={'Rola'}
              isRequired
              mb
              disabled={true}
            />

            { isTenantFieldRequired &&
              <TenantsSelect
                name="tenantId"
                label={'Podmiot'}
                isRequired={isTenantFieldRequired}
                mb
                disabled={true}
              />
            }
          </form>
        </FormProvider>
      </Box>
    </Popup>
  )
}

export default EditUserPopup
