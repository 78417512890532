import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useContext, useState } from "react";
import { ActionType, TypographyEnum } from "../../../context/customTheme.types";
import { CustomThemeContext } from "../../../context/customThemeContext";

const TypographyController = () => {
  const { settings, dispatch } = useContext(CustomThemeContext)
  const [typographySize, setTypographySize] = useState<TypographyEnum>(settings?.typography ?? TypographyEnum.SMALL);

  const handleSize = (
    event: React.MouseEvent<HTMLElement>,
    newSize: TypographyEnum
  ) => {
    if(newSize){
      dispatch({ type: ActionType.SWITCH_TYPOGRAPHY, payload: newSize })
      setTypographySize(newSize);
    }
  };
  return (
    <ToggleButtonGroup value={typographySize} onChange={handleSize} exclusive>
      <ToggleButton value={TypographyEnum.SMALL}>
        A
      </ToggleButton>
      <ToggleButton value={TypographyEnum.MEDIUM}>
        A+
      </ToggleButton>
      <ToggleButton value={TypographyEnum.BIG}>
        A++
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TypographyController;
